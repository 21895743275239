import {Pipe, PipeTransform} from '@angular/core';

/**
 * Change pixels to root em unit
 * @param pixelValue
 */
@Pipe({
	name: 'pxToRem',
	standalone: true
})
export class PxToRemPipe implements PipeTransform {
	transform(pixelValue: number): string {
		return (pixelValue / 16) + 'rem';
	}
}
