<dialog #dialog [attr.aria-label]="title" [style.min-width]="minWidth | pxToRem" (click)="click($event)" (keydown)="keydown($event)">
	<div class="wrapper">
		@if (showTitle) {
			<div class="title-bar" [ngClass]="hasVerticalScrollbar ? 'border-bottom' : ''">
				<h4>{{ title }}</h4>
				@if (showCloseButton) {
					<button class="close" (click)="close()">
						<svg-icon key="close"></svg-icon>
					</button>
				}
			</div>
		}

		<overlay-scrollbars class="content" [options]="overlayScrollDarkVertical" (waResizeObserver)="onResize($event)">
			<div #content>
				<ng-content></ng-content>
			</div>
		</overlay-scrollbars>

		<div class="action-bar" [ngClass]="hasVerticalScrollbar ? ' border-top' : ''">
			<ng-content select=".actions"></ng-content>
		</div>
	</div>
</dialog>
