import {common} from './common';

export const environment = {
	production: false,
	development: false,
	version: common.version,
	googleMapsKey: 'AIzaSyDJWQ72Q9inJvS6yFkS7jRw2U15dQeQue4',
	googleMeasurementId: 'G-0ZJDGM8VNS',
	// Used in dev environments to automatically login a given SSO user given an email address
	// and GUID from SubscriptionServices table. Format: `${email}&GUID=${GUID}`
	// DO NOT SET THIS TO ANYTHING OTHER THAN NULL IN NON DEV ENVIRONMENTS
	loginUser: null,
	base: 'https://test.fit.thinkfellow.com',
	apis: {
		error: 'https://test.fit.thinkfellow.com/Error/LogJavaScriptError',
		app: 'https://test.fit.thinkfellow.com/api',
		externalLGData: 'https://common.fit.thinkfellow.com/ExternalLGData/api/v2',
		mapbox: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
		externalCommonService: 'https://portal.sao.wa.gov/ExternalCommonservice/api',
		auditReports: 'https://www.sao.wa.gov/reports-data/audit-reports/'
	},
	reportAnIssueEmail: 'michelle.julien@thinkfellow.com'
};
