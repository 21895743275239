import {Injectable} from '@angular/core';
import {
	Event,
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router
} from '@angular/router';
import {filter, Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

export enum Layout {
	spacious = 'spacious',
	compact = 'compact'
}

/**
 * This service has been built on the assumption that there are no nested routes (firstChild). If we decide to support
 *  nested routes in the future, we will need to refactor to provide Observables based on some condition (outlet?).
 */
@Injectable({
	providedIn: 'root'
})
export class RouteService {

	private _navigationEnd = this.router.events.pipe(
		filter(event => event instanceof NavigationEnd)
	);

	// The currently-activated route
	//  https://angular.io/api/router/ActivatedRouteSnapshot
	activatedRoute = this._navigationEnd.pipe(
		map(() => this.router.routerState.snapshot.root.firstChild),
		shareReplay(1)
	);

	// The resolved data for the ActivatedRoute
	data = this.activatedRoute.pipe(
		map(route => route?.data),
		shareReplay(1)
	);

	// Determines the layout to use for the given route
	layout = this.data.pipe(
		map(data => data?.['layout'] ?? Layout.compact)
	);

	// Is the route in the process of loading?
	isLoading: Observable<boolean> = this.router.events.pipe(
		filter((event: Event) =>
			event instanceof NavigationStart
			|| event instanceof NavigationEnd
			|| event instanceof NavigationCancel
			|| event instanceof NavigationError
		),
		map((event: Event) => event instanceof NavigationStart),
		shareReplay(1)
	);

	hasOmniSearch: Observable<boolean> = this.data.pipe(
		map(data => data?.['hasOmniSearch'] ?? false),
		shareReplay(1)
	);

	constructor(
		private router: Router
	) {
	}
}
