import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {MCAG} from '../api/fit-api/models/mcag';
import {GovernmentId} from '../api/fit-api/models/government-id';
import {UserService} from '../user.service';
import {RouteParam} from '../../models/report';

/**
 * Determine if Report is valid and if the user has access to it.
 *  Check report route parameter against report definitions. If the report is in the route definitions, pass.
 *  If report is 'debt and liabilities' check if user is authorized to see report. Navigate user to 'not authorized' page if not.
 *  If report route parameter isn't found in report definitions, navigate user to 'not found' page.
 *
 * @param route
 * @param state
 */
export const financialReportGuard: CanActivateFn = (route, state) => {
	const router = inject(Router);
	const userService = inject(UserService);
    const mcag= route.paramMap.get('mcag') as MCAG;
	const reportRouteParameter = route.paramMap.get('report') as RouteParam;

    // if report exists, check auth. Else, navigate to not found page
    if (validReports.includes(reportRouteParameter)) {
        if (reportRouteParameter === RouteParam.debtLiabilities) {
            // check auth
           userService.user.subscribe(user => {
                if (mcag && user.hasAccessToMcag(new GovernmentId(mcag))) {
                    return true;
                } else if (user.hasGlobalAccess()) {
                    return true;
                } else {
                    router.navigate(['/not-authorized']); // redirect
                    return false;
                }
            });
        }

        return true;
    } else {
        router.navigate(['/not-found']); // redirect
        return false;
    }
};
export const validReports = [RouteParam.summary, RouteParam.revenues, RouteParam.revenuesOtherIncreases, RouteParam.expenditures, RouteParam.expendituresOtherDecreases, RouteParam.debtLiabilities];
