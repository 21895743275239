import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {LoggerService} from './logger.service';

declare const window: Window & { dataLayer: Record<string, unknown>[]; };

@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {

	private readonly dataLayer = window.dataLayer = window.dataLayer || [];

	constructor(
		private logger: LoggerService
	) { }

	// This is just absurd. Google doesn't explain themselves, so it's impossible to type this stuff properly.
	// The dataLayer object is a mixed array.
	// https://developers.google.com/tag-platform/tag-manager/datalayer
	init() {
		// @ts-ignore
		this.gtag('js', new Date());
		// @ts-ignore
		this.gtag('config', environment.googleMeasurementId);

		this.logger.debug('gtag id', environment.googleMeasurementId);
	}

	private gtag(): void {
		// @ts-ignore
		this.dataLayer.push(arguments);
	}
}
