import {Benchmark} from '../services/api/fit-api/models/indicators/benchmark';
import {SavedUnitInfo} from '../services/api/fit-api/models/indicators/saved-unit-info';
import {TrendAmount} from './trend-amount';
import {OutlookIndicator} from './outlook-indicator';
import {MCAG} from '../services/api/fit-api/models/mcag';

export class Indicator {
	constructor(public mcag: string,
				public code: string,
				public instanceCode: string,
				public title: string,
				public measureBenchmark: Benchmark,
				public measureUnitInfo: SavedUnitInfo,
				public trendAmounts: Array<TrendAmount>,
				public displayYear: number,
				public infoMeaning: string,
				public outlookIndicatorForDisplayYear: OutlookIndicator,
				public measureForDisplayYear?: number,
	) {
	}
}
