import { ResolveFn } from '@angular/router';
import {inject} from '@angular/core';
import {DatasetService} from '../api/fit-api/dataset.service';
import {DatasetType} from '../api/fit-api/models/datasets/dataset-type';
import {Dataset} from '../api/fit-api/models/datasets/dataset';
import {ExtractTypeId} from '../../pages/data-extracts/extract-type';
import {DataExtractsService} from '../data-extracts.service';
import {Observable, of, switchMap} from 'rxjs';
import {DatasetSource} from '../api/fit-api/models/datasets/dataset-source';

export const extractDatasetResolver: ResolveFn<Dataset | null> = (route, state) => {
	const datasetService = inject(DatasetService);
	// 1. If datasetType (+ datasetId) provided, resolve that Dataset
	const datasetType = route.paramMap.get('datasetType') as DatasetType;
	if (datasetType) {
		const datasetId = route.paramMap.get('datasetId') ? Number(route.paramMap.get('datasetId')) : undefined;
		return datasetService.getDataset(datasetType, datasetId);
	}

	// 2. Otherwise, get the default Dataset for the applicable scenario...
	//    ...Data Extract's DatasetSource is based on the Extract Type (when it is required)
	const extractTypeId = route.paramMap.get('extractTypeId') as ExtractTypeId;

	// 2a. Get the DatasetSource for the context (GovTypeCode, MCAG, or ExtractTypeId)
	const dataExtracts = inject(DataExtractsService);
	let datasetSource: Observable<DatasetSource>;

    if (extractTypeId) {
		datasetSource = dataExtracts.getDatasetSource(extractTypeId);
	} else {
		datasetSource = of(DatasetSource.None);
	}

	// 2b. Default Dataset for the given datasetSource
	return datasetSource.pipe(
		switchMap(datasetSource => datasetService.getDataset(datasetSource))
	);
};
