import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterOutlet} from '@angular/router';
import {BreadcrumbComponent} from '../breadcrumb/breadcrumb.component';
import {LoadingIndicatorComponent} from '../loading-indicator/loading-indicator.component';
import {ContentActionsComponent} from '../content-actions/content-actions.component';

@Component({
	selector: 'app-content',
	standalone: true,
	imports: [CommonModule, RouterOutlet, BreadcrumbComponent, LoadingIndicatorComponent, ContentActionsComponent],
	templateUrl: './content.component.html',
	styleUrls: ['./content.component.scss']
})
export class ContentComponent {

}
