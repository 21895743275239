import {County} from '../snapshots/county';
import {AccountDescriptor} from '../snapshots/account-descriptor';
import {FilingBasis} from '../snapshots/filing-basis';
import {ReferenceLookup} from '../snapshots/reference-lookup';
import {DebtGroup} from '../snapshots/debt-group';
import {BonanzaSchedule9Type} from '../snapshots/bonanza-schedule-9-type';
import {DebtCategoryItem} from '../snapshots/debt-category-item';
import {GovernmentType} from '../government-type';
import {IndicatorName} from '../indicators/indicator-name';
import {OperatingResultsSection} from '../snapshots/operating-results-section';
import {OperatingResultsSubSection} from '../snapshots/operating-results-sub-section';
import {Dataset, DatasetDetail} from './dataset';
import {AccountingBasis} from '../accounting-basis';
import {LocalGovernment} from '../local-government';
import {FinancialSummarySection} from '../snapshots/financial-summary-section';

export class AnnualFilingDataset extends Dataset {
	constructor(data: AnnualFilingDataset) {
		super(data);
	}
}

/**
 * SAO Annual Filing-based Detail model (i.e., Snapshots, Live)
 */
export abstract class AnnualFilingDatasetDetail extends DatasetDetail {
	barsYear!: number;
	includedYears!: Array<number>;
	counties!: Array<County>;
	accountDescriptors!: Array<AccountDescriptor>;
	accountingBases!: Array<AccountingBasis>;
	filingBases!: Array<FilingBasis>;
	financialSummarySections!: Array<FinancialSummarySection>;
	expenditureObjects!: Array<ReferenceLookup<number>>;
	debtGroups!: Array<DebtGroup>;
	bonanzaSchedule9Categories!: Array<ReferenceLookup<number>>;
	bonanzaSchedule9Types!: Array<BonanzaSchedule9Type>;
	debtCategoryItems!: Array<DebtCategoryItem>;

	governmentTypes!: Array<GovernmentType>;
	governments!: Array<LocalGovernment>;
	indicatorNames!: Array<IndicatorName>;
	operatingResultsTopLevels!: Array<ReferenceLookup<string>>;
	operatingResultsSections!: Array<OperatingResultsSection>;
	operatingResultsSubSections!: Array<OperatingResultsSubSection>;

	protected constructor(data: AnnualFilingDatasetDetail) {
		super(data);
	}
}
