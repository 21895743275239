import {ActivatedRouteSnapshot, ResolveFn, Router} from '@angular/router';
import {FitApiService} from '../api/fit-api/fit-api.service';
import {inject} from '@angular/core';
import {MCAG} from '../api/fit-api/models/mcag';
import {LocalGovernment} from '../api/fit-api/models/local-government';
import {catchError} from 'rxjs/operators';
import {EMPTY} from 'rxjs';

export const localGovernmentResolver: ResolveFn<LocalGovernment> = (route, state) => {
	const router = inject(Router);

	return resolveGovernment(route).pipe(
		catchError(err => {
			// console.debug(`caught error`, err); // this actually throws an error lol
			// todo need to think about this more to figure out how we want to handle not found scenarios
			router.navigateByUrl('/not-found');
			return EMPTY;
		})
	);
};

export const resolveGovernment = (route: ActivatedRouteSnapshot) => {
	const mcag =
		route.paramMap.get('mcag') as MCAG;

	if (mcag == null || mcag.length !== 4) {
		throw new Error(`A valid mcag must be provided to use this resolver. Offered: ${mcag}`);
	}

	return inject(FitApiService).getLocalGovernment(mcag);
};
