import { ResolveFn } from '@angular/router';
import {DataExtractsService} from '../data-extracts.service';
import {ExtractTypeId} from '../../pages/data-extracts/extract-type';
import {inject} from '@angular/core';
import {LoggerService} from '../logger.service';

export const extractTitleResolver: ResolveFn<string> = (route, state) => {
	// todo - same code as in extractTypeResolver; title resolvers can now access parent's data resolver?
	// https://github.com/angular/angular/issues/47781#issuecomment-1283536077
	const extractTypeId = route.paramMap.get('extractTypeId') as ExtractTypeId;
	if (extractTypeId == null) {
		throw new Error(`A valid extract type must be provided to use this resolver. Offered: ${extractTypeId}`);
	}
	let extractTitle = inject(DataExtractsService).getExtractType(extractTypeId)?.title;
	if (!extractTitle){
		inject(LoggerService).warn(`Could not locate a title for extractTypeId: ${extractTypeId}`);
		extractTitle = '';
	}
	return extractTitle;
};
