import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {distinctUntilChanged, fromEvent} from 'rxjs';
import {map} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SvgIconComponent} from '@ngneat/svg-icon';
import {v4 as uuid} from 'uuid';

@UntilDestroy()
@Component({
	selector: 'app-search',
	standalone: true,
	imports: [CommonModule, SvgIconComponent],
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent implements AfterViewInit {

	@ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;

	@Input() label: string | null = null;
	@Input() placeholder = 'Search';

	@Input() value = '';
	@Output() valueChange = new EventEmitter<string>();

	elementId = uuid();

	ngAfterViewInit(): void {
		if (!this.searchInput?.nativeElement) {
			throw new Error(`#searchInput could not be found. Values will not be emitted.`);
		}
		// emit initial if parent does not set a value
		this.valueChange.emit(this.value);
		fromEvent<InputEvent>(this.searchInput.nativeElement, 'input').pipe(
			untilDestroyed(this),
			map(event => (event.target as HTMLInputElement).value)
		).subscribe(result => {
			if (result !== this.value) {
				this.valueChange.next(result);
			}
		});
	}

	focus() {
		if (this.searchInput) {
			this.searchInput.nativeElement.focus();
		}
	}
}
