import { Pipe } from '@angular/core';
import type { PipeTransform } from '@angular/core';

type AbstractType<T> = abstract new (...args: never[]) => T;

@Pipe({
	name: 'instanceof',
	pure: true,
	standalone: true
})
// https://vasily-ivanov.medium.com/instanceof-in-angular-html-templates-63f23d497242
export class InstanceofPipe implements PipeTransform {
	public transform<V, R>(value: V, type: AbstractType<R>): R | undefined {
		return value instanceof type ? value : undefined;
	}
}
