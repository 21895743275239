import {Injectable} from '@angular/core';
import {Observable, of, switchMap} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {DatasetConfig, DatasetDetail, DatasetType} from './models/datasets/dataset-type';
import {ODataCollectionResult} from '../odata-collection-result';
import {HttpClient} from '@angular/common/http';
import {Dataset} from './models/datasets/dataset';
import {DatasetSource} from './models/datasets/dataset-source';
import {DatasetResponse} from './models/datasets/dataset-response';
import {Data} from '@angular/router';
import {LoggerService} from '../../logger.service';
import {Share, ShareMode} from '../share';
import {GovernmentId} from './models/government-id';
import {GovernmentSpecificity} from './models/government-specificity';
import {FitApiService} from './fit-api.service';
import {MCAG} from './models/mcag';
import {GovTypeCode} from './models/gov-type-code';

@Injectable({
	providedIn: 'root'
})
export class DatasetService {

	/**
	 * Get all Datasets and cache. This will return anything you have access to as the currently-logged-in user.
	 */
	readonly datasets: Observable<Array<Dataset>> = this.getDatasets().pipe(
			shareReplay(1) // Cache for life of app
		);

	getDatasets():Observable<Array<Dataset>> {
		return this.httpClient
			.get<ODataCollectionResult<DatasetResponse>>(`Datasets`)
			.pipe(map(result =>
				result.value.map(dataset => new Dataset(dataset))
			));
	}

	constructor(
		private httpClient: HttpClient,
		private logger: LoggerService,
		private api: FitApiService
	) {
		// tests todo remove once work on these functions is complete
		// const fromSource = this.getDataset(DatasetSource.AnnualFiling);
		// const fromType = this.getDataset(DatasetType.Live);
		// const fromTypeAndId = this.getDataset(DatasetType.Snapshot, 17);
	}

	/**
	 * Get the default Dataset metadata for the given DatasetSource.<br />
	 *  For versioned DatasetTypes, this will return the Dataset containing the max of `Dataset.id` within the
	 *  DatasetType, otherwise the singleton Dataset for the DatasetType.
	 * @param source
	 * @param id
	 */
	getDataset(source: DatasetSource, id?: never): Observable<Dataset | null>;
	/**
	 * Get the Dataset metadata for the given DatasetType.
	 * @param type
	 * @param id For versioned DatasetTypes, id will be used to retrieve the specific Dataset. If not provided,
	 *  the Dataset containing the max of id within the DatasetType will be returned.
	 */
	getDataset(type: DatasetType, id?: number): Observable<Dataset>;
	getDataset(sourceOrType: DatasetSource | DatasetType, id?: number): Observable<Dataset | null> {
		let datasetType: DatasetType | null = null;
		if (sourceOrType in DatasetType) {
			// DatasetType was already provided
			datasetType = sourceOrType as DatasetType;
		} else if (sourceOrType in DatasetSource) {
			// todo break into own resolution function
			// DatasetSource provided; resolve the default DatasetType
			// Natural order determines default, i.e. first in config matching on DatasetSource is the default
			if (sourceOrType !== DatasetSource.None) {
				for (const [_, config] of Object.entries(DatasetConfig)) {
					if (config.datasetSource === sourceOrType) {
						datasetType = config.datasetType;
						break;
					}
				}
			}
		} else  {
			throw new Error(`value ${sourceOrType} is neither a DatasetSource nor DatasetType`);
		}

		if (datasetType) {
			// Search the cached Datasets for a match
			return this.datasets.pipe(
				map(datasets => {
					const datasetsOfType = datasets.filter(x => x.datasetType === datasetType);
					if (!datasetsOfType) {
						throw new Error(`Could not locate any Datasets of type ${datasetType}`);
					}

					const datasetTypeConfig = DatasetConfig[datasetType ?? DatasetType.Snapshot];
					let dataset: Dataset | null;
					if (datasetTypeConfig.versioned) {
						if (id !== undefined) {
							const datasetWithId = datasetsOfType.find(x => x.id === id);
							if (!datasetWithId) {
								throw new Error(`Could not locate Dataset for ${datasetType} ${id}.`);
							}
							dataset = datasetWithId;
						} else {
							// find the Dataset with max id
							dataset = datasetsOfType.reduce((prev, current) =>
								// ids cannot be null for versioned Dataset types
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								current.id! > prev.id! ? current : prev
							);
						}
					} else {
						// Otherwise pick the first one (which should be the only one for a singleton type).
						dataset = datasetsOfType[0];
						// Note that this may return undefined if you are attempting to access a Dataset that you do not
						//  have access to (i.e. Live, Schools) since they are removed from the API response.
						if (dataset === undefined) {
							this.logger.info(`Could not locate Dataset for ${datasetType} or user doesn't have access to the specified dataset.`);
							return dataset = null;
						}
					}
					return dataset;
				})
			);
		}
		return of(null);
	}

	/**
	 * Get a specific DatasetWithDetail.
	 * @param datasetType
	 * @param id
	 */
	@Share(ShareMode.Replay)
	getDatasetDetail(datasetType: DatasetType, id?: number, withDetail = true): Observable<DatasetDetail> {
		return this.getDataset(datasetType, id).pipe(
			switchMap(dataset => {
				// Need to know the type to know the response type to use (any)
				const expand = withDetail ? `?$expand=Detail` : '';
				return this.httpClient.get<any>(`${dataset.route}${expand}`).pipe(
					map(result => new dataset.detailModel(result))
				);
			})
		);
	}

	/**
	 * Uses the Dataset object populated in ActivatedRoute.data to fetch the full DatasetDetail.
	 * @param routeData
	 */
	getDatasetDetailFromRoute(routeData: Observable<Data>): Observable<DatasetDetail | null> {
		return routeData.pipe(
			map(data => {
				const dataset = data['dataset'] as Dataset;
				if (dataset === undefined) {
					throw new Error(`No Dataset present in route data.`);
				}
				return dataset;
			}),
			// This returns null because there are conditions where you are not allowed access to a Dataset, however
			// the page still needs to be displayed. (E.g. Schools, but you aren't logged in.)
			switchMap(dataset => dataset ?
				this.getDatasetDetail(dataset.datasetType, dataset.id) :
				of(null)
			)
		);
	}

	/**
	 * Retrieves the default Dataset based on GovernmentId, or null if GovernmentId cannot be resolved.
	 * @param {GovernmentId} governmentId
	 * @returns {Observable<Dataset | null>}
	 */
	getDefaultDatasetForGovernmentId(governmentId: GovernmentId): Observable<Dataset | null> {
		let obs = of(DatasetSource.None);
		if (governmentId.specificity === GovernmentSpecificity.Government) {
			obs = this.api.getLocalGovernment(governmentId.id as MCAG).pipe(
				map(x => x.financialsDatasetSource)
			);
		} else if (governmentId.specificity === GovernmentSpecificity.GovernmentType) {
			obs = this.api.getGovernmentType(governmentId.id as GovTypeCode).pipe(
				map(x => x.financialsDatasetSource)
			);
		}
		return obs.pipe(
			switchMap(datasetSource => this.getDataset(datasetSource))
		);
	}

}
