import {ReferenceLookup} from '../snapshots/reference-lookup';

export enum FundCategoryId {
	governmental = 1,
	proprietary = 2
}

export interface FundCategory extends ReferenceLookup<number> {
	id: FundCategoryId;
	name: string;
	sortOrder: number;
}

/**
 * Hardcoded values avoid expensive lookups.
 */
export const FUND_CATEGORIES: Readonly<Array<FundCategory>> = [
	{ id: 1, name: 'Governmental', sortOrder: 1 },
	{ id: 2, name: 'Proprietary', sortOrder: 2 }
] as const;

export interface FundCategoryWithMcagAndYear {
	fundTypeId: number;
	fundCategoryId: FundCategoryId;
	year: number;
	mcag: string;
}
