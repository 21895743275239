import {FundTypeId} from './fund-type';
import {FundCategoryId} from './fund-category';

export class Fund {

	fundNumber!: string;
	fundName!: string;
	fundTypeId!: FundTypeId;
	fundCategoryId!: FundCategoryId;

	fundFullName: string;

	constructor(data?: Partial<Fund>) {
		Object.assign(this, data);

		const name = this.fundName ? ` - ${this.fundName}` : ``;
		this.fundFullName = `${this.fundNumber}${name}`;
	}
}
