import {GovernmentId} from '../services/api/fit-api/models/government-id';
import {GovernmentSpecificity} from '../services/api/fit-api/models/government-specificity';

export class User {

	// List of MCAGs that the user is restricted to if they do not have global access
	private readonly allowedMcags: Array<string> | null = null;

	// Government user roles
	private governmentUserGroups = [
		UserGroup.annualReportFiler,
		UserGroup.governmentUser,
		UserGroup.governmentPortalAdmin
	];

	// FIT global access roles
	private globalAccessUserGroups = [
		UserGroup.lgcsAdministrator,
		UserGroup.lgcsReportEditor,
		UserGroup.lgcsReportViewer
	];

	constructor(public claim?: Claim) {
		// If the user does not have global access, set their MCAG restriction list
		if (!this.hasGlobalAccess()) {
			this.allowedMcags = this.determineAllowedMcags();
		}
	}

	get hasAdminRole(): boolean {
		return this.hasAccessRoleForScope('admin');
	}

	get hasDeveloperRole(): boolean {
		return this.hasAccessRoleForScope('developer');
	}

	get hasAnyFitAccessRole(): boolean {
		return this.hasAccessRoleForScope('fit');
	}

	get hasAnyAccessRole(): boolean {
		return (this.claim?.userGroups?.length ?? 0) > 0;
	}

	/**
	 * Global access is defined by the server api/Live endpoint returning isRestrictedByMCAG = false.  Alternatively, we can
	 * test the roles that the user has to see if any of them grant global access to all MCAGS
	 */
	public hasGlobalAccess(): boolean {
		return this.hasAccessRoleForScope('global');
	}

	/**
	 * Check if the user has access to an mcag
	 * Test for global access role or access to the specified MCAG
	 *
	 * @param governmentId
	 */
	public hasAccessToMcag(governmentId: GovernmentId): boolean {
		const hasAccessViaAllowedMcags = governmentId.specificity === GovernmentSpecificity.Government
			&& this.allowedMcags && this.allowedMcags.includes(governmentId.toString());

		if (this.hasGlobalAccess() || hasAccessViaAllowedMcags) {
			return true;
		}

		// defaults to false
		return false;
	}

	private determineAllowedMcags = (): Array<string> | null => {
		// We only want the MCAGs that are associated with local government user roles
		return this.claim?.portalSystemGroups?.filter(g => this.governmentUserGroups.includes(g.saoportalSystemGroupName))
			.map(g => g.mcag) ?? null;
	};

	private hasAccessRoleForScope(role: 'fit' | 'admin' | 'developer' | 'global'): boolean {
		if (!this.hasAnyAccessRole) {
			return false;
		}
		let accessRoles: Array<UserGroup> = [];
		if (role === 'admin') {
			accessRoles = [UserGroup.fitAdministrator];
		}
		else if (role === 'fit') {
			accessRoles = this.governmentUserGroups.concat(this.globalAccessUserGroups);
		}
		else if (role === 'global') {
			accessRoles = this.globalAccessUserGroups;
		}
		else if (role === 'developer') {
			accessRoles = [UserGroup.saoDevelopers];
		}
		return accessRoles
			.filter(r => (this.claim?.userGroups?.indexOf(r) ?? 0) > -1)
			.length > 0;
	}

}

// Based off SAO's user implementation - WASAO.SSO.DALModels.ViewModels.ContactClaim
export interface Claim {
	contactID: number;
	mcag: string; // this is self-reported by the user, do not rely on for logic
	registered: boolean;
	fullName: string;
	email: string;
	userGroups: Array<UserGroup>;
	portalSystemGroups: Array<PortalSystemGroup>;
}

export enum UserGroup {
	lgcsAdministrator     = 'LGCS Administrator',
	lgcsReportViewer      = 'LGCS Report Viewer',
	lgcsReportEditor      = 'LGCS Report Editor',
	annualReportFiler     = 'Annual Report Filer',
	governmentUser        = 'Government User',
	governmentPortalAdmin = 'Government Portal Admin',
	fitAdministrator      = 'FIT Admin',
	saoDevelopers 		  = 'SAO Developers'
}

export interface PortalSystemGroup {
	contactID: number;
	saoportalSystemGroupName: UserGroup;
	saoportalSystemGroupId: number;
	mcag: string;
	govTypeCode: string;
	entityName: string;
	lookupName: string;
	acctBasisTypeID: number;
	adfsInternalOnly: boolean;
	parentMCAG: string;
	isActive: boolean;
}
