import {MCAG} from "../mcag";
import {GovTypeCode} from "../gov-type-code";
import {AmountFormat, Ranking, Section} from "./ranking";
import {RankingSortOrder} from "./ranking-sort-order";

export class EnrollmentRanking {
	constructor(
		public mcag: MCAG,
		public coDist: string,
		public govTypeCode: GovTypeCode,
		public fy: number,
		public schoolYear: string,
		public enrollmentType: string,
		public averagesForAllGrades: number,
		public rank: number
	) {}

	toRanking = () => new Ranking(
		this.mcag,
		this.fy,
		Section.nonFinancial,
		null,
		'Enrollment',
		RankingSortOrder.schoolsEnrollment,
		this.rank,
		this.averagesForAllGrades,
		AmountFormat.decimal
	)

}
