import {Injectable} from '@angular/core';
import Excel from '@nbelyh/exceljs';
import {PivotRow, PivotRowType} from '../../models/pivot-table/pivot-row';
import {PivotCellType} from '../../models/pivot-table/pivot-cell';
import {PivotTable} from '../../models/pivot-table/pivot-table';
import {PivotAnnotationType} from '../../models/pivot-table/pivot-annotation';
import {LoggerService} from '../../services/logger.service';
import {FitApiService} from '../../services/api/fit-api/fit-api.service';
import {AnnotatedValue, HierarchyFieldConfig} from '../../models/data-cube/hierarchy-field-config';
import {map} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import {HierarchyConfig} from '../../models/data-cube/hierarchy-config';
import {AllFundsDataRow} from '../../services/api/fit-api/models/all-funds';
import {SchoolsWithDetail} from '../../services/api/fit-api/models/datasets/schools';
import {SchoolFund} from '../../services/api/fit-api/models/funds/school-fund';
import {AccountDescriptor} from '../../services/api/fit-api/models/snapshots/account-descriptor';
import {ReferenceLookup} from '../../services/api/fit-api/models/snapshots/reference-lookup';
import {FundField, SelectedFundNode} from '../../components/funds-selector/fund-node-model';
import {DatasetDetail} from '../../services/api/fit-api/models/datasets/dataset-type';
import {Report, ReportId} from '../../models/report';
import {SnapshotWithDetail} from '../../services/api/fit-api/models/datasets/snapshot';
import {Schedule1AggregationByGovType} from '../../services/api/fit-api/models/schedule1-aggregation-by-gov-type';
import {Schedule1AggregationByGovt} from '../../services/api/fit-api/models/schedule1-aggregation-by-govt';
import {GovernmentSpecificity} from '../../services/api/fit-api/models/government-specificity';
import {Fund} from '../../services/api/fit-api/models/funds/fund';
import {Schedule9} from '../../services/api/fit-api/models/schedule9';
import {LiveWithDetail} from '../../services/api/fit-api/models/datasets/live';
import {OSPILongTermLiabilityRow} from '../../services/api/fit-api/models/schools/long-term-liability-row';
import {ReportItem} from '../../services/api/fit-api/models/schools/report-item';
import {AnnualFilingDataset} from '../../services/api/fit-api/models/datasets/annual-filing-dataset-detail';
import {BarsAggregation} from '../../services/api/fit-api/models/bars-aggregation';
import {
	FinancialReportAggregationByGovt
} from '../../services/api/fit-api/models/schools/financial-report-aggregation-by-govt';
import {
	FinancialReportAggregationByGovType
} from '../../services/api/fit-api/models/schools/financial-report-aggregation-by-gov-type';

@Injectable({
	providedIn: 'root'
})
export class FinancialReportService {
	NUM_OF_EXCEL_COLUMNS_USED_BY_PIVOT_ROW_HEADERS = 5;

	constructor(
		private fitApiService: FitApiService,
		private logger: LoggerService,
	) {
	}

	//region Data Cube
	fsSectionBarsHierarchy<T extends AllFundsDataRow<BarsAggregation>>
	(
		datasetDetail: DatasetDetail,
		report: Report<T>,
		expenditureObject?: boolean)
	{
		const fields = [];

		// if summary report add 'fs section' report row
		if (report.id === ReportId.summary || report.id === ReportId.schoolsSummary) {
			fields.push(
				new HierarchyFieldConfig<T>(
					'fsSectionId', true, false,
					this.referenceSortOrder(datasetDetail?.detail?.financialSummarySections),
					this.referenceDisplayValue(datasetDetail?.detail?.financialSummarySections),
				),
			)
		} else if (expenditureObject && datasetDetail instanceof AnnualFilingDataset &&
			(report.id === ReportId.expenditures || report.id === ReportId.expendituresAndOtherDecreases)) {
			fields.push(
				new HierarchyFieldConfig<AllFundsDataRow<Schedule1AggregationByGovt> | AllFundsDataRow<Schedule1AggregationByGovType>>(
					'expenditureObjectId', true, false,
					this.referenceSortOrder(datasetDetail?.detail?.expenditureObjects),
					this.referenceDisplayValue(datasetDetail?.detail?.expenditureObjects),
					this.referenceExpenditureObject()
				),
			)
		}

		return of({
			fields: [
				...fields,
				new HierarchyFieldConfig<T>(
					'basicAccountId', true, false,
					this.accountSortOrder(datasetDetail?.detail?.accountDescriptors),
					this.accountDisplayValue(datasetDetail?.detail?.accountDescriptors),
					this.accountCode(datasetDetail?.detail?.accountDescriptors)
				),
				new HierarchyFieldConfig<T>(
					'subAccountId', true, false,
					this.accountSortOrder(datasetDetail?.detail?.accountDescriptors),
					this.accountDisplayValue(datasetDetail?.detail?.accountDescriptors),
					this.accountCode(datasetDetail?.detail?.accountDescriptors)
				),
				new HierarchyFieldConfig<T>(
					'elementId', true, false,
					this.accountSortOrder(datasetDetail?.detail?.accountDescriptors),
					this.accountDisplayValue(datasetDetail?.detail?.accountDescriptors),
					this.accountCode(datasetDetail?.detail?.accountDescriptors)
				),
				new HierarchyFieldConfig<T>(
					'subElementId', false, false,
					this.accountSortOrder(datasetDetail?.detail?.accountDescriptors),
					this.accountDisplayValue(datasetDetail?.detail?.accountDescriptors),
					this.accountCode(datasetDetail?.detail?.accountDescriptors)
				),
			]
		} as HierarchyConfig<T>);

	}

	annualFilingDebtCategoriesHierarchy<T extends AllFundsDataRow<Schedule9>>
	(datasetDetail: DatasetDetail)
	{
		if ((datasetDetail instanceof LiveWithDetail || datasetDetail instanceof SnapshotWithDetail)) {
			return of({
				fields: [
					new HierarchyFieldConfig<T>(
						'bonanzaCategoryId', true, true,
						this.referenceSortOrder(datasetDetail?.detail.bonanzaSchedule9Categories),
						this.referenceDisplayValue(datasetDetail?.detail.bonanzaSchedule9Categories)
					),
					new HierarchyFieldConfig<T>(
						'bonanzaTypeId', true, false,
						this.referenceSortOrder(datasetDetail?.detail.bonanzaSchedule9Types),
						this.referenceDisplayValue(datasetDetail?.detail.bonanzaSchedule9Types)
					),
					new HierarchyFieldConfig<T>(
						'debtCategoryItemId', false, false,
						this.referenceSortOrder(datasetDetail?.detail.debtCategoryItems),
						this.referenceDisplayValue(datasetDetail?.detail.debtCategoryItems)
					)
				]
			} as HierarchyConfig<T>);
		} else {
			throw new Error(`No logic defined for this dataset ${datasetDetail} or report not found`);
		}
	}

	schoolsDebtCategoriesHierarchy<T extends AllFundsDataRow<OSPILongTermLiabilityRow>>
	(datasetDetail: DatasetDetail)
	{
		if (datasetDetail instanceof SchoolsWithDetail) {
			return of({
				fields: [
					new HierarchyFieldConfig<T>(
						'multiYearSortOrder', false, false,
						HierarchyFieldConfig.defaultSortOrder,  // multi-year sort order is the sort order :-)
						this.schoolDebtDisplayValue(datasetDetail?.detail.reportItems)
					),
				]
			} as HierarchyConfig<T>);
		} else {
			throw new Error(`No logic defined for this dataset ${datasetDetail} or report not found`);
		}
	}

	schoolsFundsHierarchy<T extends
		AllFundsDataRow<FinancialReportAggregationByGovt> |
		AllFundsDataRow<FinancialReportAggregationByGovType>>
	(
		specificity: GovernmentSpecificity,
		dataset: SchoolsWithDetail,
		fundNode?: SelectedFundNode,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fundsLookup: Array<Fund> = [], // n/a for schools
		mustIncludeYears: Array<AnnotatedValue> = []
	)
	{
		return forkJoin([this.fitApiService.getFundCategories(), this.fitApiService.getFundTypes()])
			.pipe(map(([fundCategories, fundTypes]) => {
				// Prepare years array to force display of years w/enrollment annotations
				const yearField = new HierarchyFieldConfig<T>('year', false);
				yearField.mustIncludeValues = mustIncludeYears;

				const fieldConfigs = [
					yearField,
					new HierarchyFieldConfig<T>('allFunds'),
					new HierarchyFieldConfig<T>(
						'fundCategoryId',	true, false,
						this.referenceSortOrder(fundCategories),
						this.referenceDisplayValue(fundCategories)
					),
					new HierarchyFieldConfig<T>(
						'fundTypeId', specificity === GovernmentSpecificity.Government, false,
						this.referenceSortOrder(fundTypes),
						this.referenceDisplayValue(fundTypes)
					)
				] as HierarchyFieldConfig<T>[];

				// Add fund level field to the hierarchy if we are viewing an individual government
				if (specificity === GovernmentSpecificity.Government) {
					fieldConfigs.push(
						new HierarchyFieldConfig<T>(
							'fundCode' as keyof T, false, false,
							this.schoolFundCodeSortOrder(dataset?.detail?.funds),
							this.schoolFundCodeDisplayValue(dataset?.detail?.funds)
					));
				}

				// Convert to correct fund field name since this is different between SAO and OSPI
				const fundFieldName = fundNode?.field === FundField.fund ? 'fundCode' : fundNode?.field.toString();
				this.consolidateFundLevels<T>(fieldConfigs, fundFieldName);

				return { fields: fieldConfigs } as HierarchyConfig<T>;
			}));
	}

	// todo FIT-1736 (https://youtrack.thinkfellow.com/youtrack/issue/FIT-1736/Schools-GetLongTermLiabilities-Endpoint-and-schoolsDebtAndLiabilitiesFundsHierarchy-refactor)
	// fix endpoint to include fundCategoryId and fundTypeId so they can be added to this columnHierarchy config function
	schoolsDebtAndLiabilitiesFundsHierarchy<T extends AllFundsDataRow<OSPILongTermLiabilityRow>>
	(
		specificity: GovernmentSpecificity,
		dataset: SnapshotWithDetail,
		fundNode?: SelectedFundNode,
		fundsLookup?: Array<Fund>,
		mustIncludeYears: Array<AnnotatedValue> = [])
	{
		const yearField = new HierarchyFieldConfig<T>('fy', false);
		yearField.mustIncludeValues = mustIncludeYears;

		const fieldConfigs = [
			yearField,
			new HierarchyFieldConfig<T>('allFunds', false),
		] as HierarchyFieldConfig<T>[];

		return of({ fields: fieldConfigs } as HierarchyConfig<T>);
	}

	annualFilingSch1ColumnHierarchy<T extends
		AllFundsDataRow<Schedule1AggregationByGovt> |
		AllFundsDataRow<Schedule1AggregationByGovType>>
	(
		specificity: GovernmentSpecificity,
		dataset: SnapshotWithDetail,
		fundNode?: SelectedFundNode,
		fundsLookup: Array<Fund> = [],
		mustIncludeYears: Array<AnnotatedValue> = [])
	{
		return forkJoin([this.fitApiService.getFundCategories(), this.fitApiService.getFundTypes()])
			.pipe(map(([fundCategories, fundTypes]) => {
				// Prepare years array to force display of years even if missing a filing
				const yearField = new HierarchyFieldConfig<T>('year', false);
				yearField.mustIncludeValues = mustIncludeYears;

				const fieldConfigs = [
					yearField,
					new HierarchyFieldConfig<T>('allFunds'),
					new HierarchyFieldConfig<T>(
						'fundCategoryId',	true, false,
						this.referenceSortOrder(fundCategories),
						this.referenceDisplayValue(fundCategories)
					),
					new HierarchyFieldConfig<T>(
						'fundTypeId', specificity === GovernmentSpecificity.Government, false,
						this.referenceSortOrder(fundTypes),
						this.referenceDisplayValue(fundTypes)
					)
				] as HierarchyFieldConfig<T>[];

				// Add fund level field to the hierarchy if we are viewing an individual government
				if (specificity === GovernmentSpecificity.Government) {
					fieldConfigs.push(new HierarchyFieldConfig<T>(
						'fund' as keyof T, false, false,
						HierarchyFieldConfig.defaultSortOrder,
						this.annualFilingFundDisplayValue(fundsLookup)
					));
				}

				// Remove fund levels based on selected fund node; start after first element which is FY
				this.consolidateFundLevels<T>(fieldConfigs, fundNode?.field);

				return { fields: fieldConfigs } as HierarchyConfig<T>;
			}));
	}

	annualFilingSch9ColumnHierarchy<T extends AllFundsDataRow<Schedule9>>
	(
		specificity: GovernmentSpecificity,
		dataset: SnapshotWithDetail,
		fundNode?: SelectedFundNode,
		fundsLookup?: Array<Fund>,
		mustIncludeYears: Array<AnnotatedValue> = [])
	{
		const yearField = new HierarchyFieldConfig<T>('year', false);
		yearField.mustIncludeValues = mustIncludeYears;

		const fieldConfigs = [
			yearField,
			new HierarchyFieldConfig<T>('allFunds', false),
		] as HierarchyFieldConfig<T>[];

		return of({ fields: fieldConfigs } as HierarchyConfig<T>);
	}

	/**
	 * Remove fund levels based on selected fund node; start after first element which is FY or year
	 *
	 * @param fieldConfigs - full column hierarchy with fund levels
	 * @param fundFieldName - filtered fund level (no need to keep parent fund levels above this one for display)
	 * @private
	 */
	private consolidateFundLevels<T extends object>(fieldConfigs: HierarchyFieldConfig<T>[], fundFieldName: string | undefined) {
		for (let i = 1; i < fieldConfigs.length; i++) {
			const config = fieldConfigs[i];
			if (config.name === fundFieldName) {
				break;
			}
			// If we have not encountered the selected fund node level (working top-down), then remove this level from the configs
			fieldConfigs.splice(i, 1);
			i--;
		}
	}

	private schoolFundCodeDisplayValue(fundCodes: SchoolFund[]) {
		return function (code: number | string | null): string {
			if (code === null) {
				return '';
			}
			const fundCode = fundCodes.find(fc => fc.code === code);
			return fundCode?.description ?? code.toString();
		}
	}

	private schoolDebtDisplayValue(reportItems: ReportItem[]) {
		return function (code: number | string | null): string {
			if (code === null) {
				return '';
			}
			const item = reportItems.find(i => i.multiYearSortOrder === code && i.reportNo === '013');
			return item?.title ?? code.toString();
		}
	}

	private annualFilingFundDisplayValue(funds: Fund[]) {
		return function (code: number | string | null): string {
			if (code === null) {
				return '';
			}
			const fund = funds.find(f => f.fundNumber === code);
			return fund?.fundFullName ?? code.toString();
		}
	}

	private schoolFundCodeSortOrder(fundCodes: SchoolFund[]) {
		return function (code: number | string | null): number | string {
			if (code === null) {
				return -1;
			}
			const fundCode = fundCodes.find(fc => fc.code === code);
			return fundCode?.sortOrder ?? code;
		}
	}

	private accountDisplayValue(accounts: AccountDescriptor[]) {
		return function (id: number | string | null): string {
			if (id === null) {
				return '';
			}
			const account = accounts.find(cat => cat.id === id);
			return account?.name ?? id.toString();
		}
	}

	private accountSortOrder(accounts: AccountDescriptor[]) {
		return function (id: number | string | null): number | string {
			if (id === null) {
				return '';
			}
			const account = accounts.find(acc => acc.id === id);
			if (account?.categoryDisplay === '') {
				return 'XXX' + id.toString(); // If no category display (BARS account number), then sort at the bottom (schools)
			}
			return account?.categoryDisplay ?? id.toString();
		}
	}

	private accountCode(accounts: AccountDescriptor[]) {
		return function (id: number | string | null): string | null {
			if (id === null) {
				return '';
			}
			const account = accounts.find(acc => acc.id === id);
			if (account?.categoryDisplay === '') {
				return null;
			}
			return account?.categoryDisplay ?? id.toString();
		}
	}

	private referenceDisplayValue(refData: ReferenceLookup<number | string>[]) {
		return function (id: number | string | null): string {
			if (id === null) {
				return '';
			}
			const row = refData.find(ref => ref.id === id);
			return row?.name ?? id.toString();
		}
	}

	private referenceSortOrder(refData: ReferenceLookup<number | string>[]) {
		return function (id: number | string | null): number | string {
			if (id === null) {
				return '';
			}
			const row = refData.find(ref => ref.id === id);
			return row?.sortOrder ?? id.toString();
		}
	}

	private referenceExpenditureObject() {
		return function (id: number | string | null): string | null {
			return id ? id.toString() : null;
		}
	}
	//endregion

	//region Download Extract
	/**
	 * Write Pivot Table Data Rows to Excel Worksheet
	 * See mockup: https://docs.google.com/spreadsheets/d/1DQlc4_yoe6Z6Pq1SuV759PMkyVfDPc2MNspB_rvAB9o/edit#gid=0
	 *
	 * Steps to write table:
	 *  1 - Find place marker cell to start writing table to
	 *  2 - Write header rows (if normalization row exists in first row add another row)
	 *	3 - Write data rows (if child rows exist, call write-to function recursively)
	 *	4 - Write total rows
	 *
	 * @param worksheet
	 * @param pivotTable
	 */
	writePivotTableRowsToWorksheet<T extends object>(
		worksheet: Excel.Worksheet,
		pivotTable: PivotTable<T>
	) {
		// find placeholder, set first cell in pivot table
		const placeholderCell = this.findPlaceHolderCell(worksheet);
		// track the current row in Excel that we are writing to; set initially to the row of the placeholder cell
		let currentExcelRowNumber = placeholderCell.row;
		// column number of the placeholder cell; represents the left most column for our grid
		const firstColumnNumber = placeholderCell.col;

		// iterate over pivot table data
		pivotTable.rows.forEach((pivotTableRow, pivotTableRowIndex) => { // iterate each row of data
			switch (pivotTableRow.type) {
				case PivotRowType.header: {
					// first row of pivot table data
					const isFirstPivotTableRow = pivotTableRowIndex === 0;
					currentExcelRowNumber = this.writeHeaderRow(pivotTableRow, worksheet, firstColumnNumber, currentExcelRowNumber, isFirstPivotTableRow);
					break;
				}
				case PivotRowType.data:
					currentExcelRowNumber = this.writeDataRow(pivotTableRow, worksheet, firstColumnNumber, currentExcelRowNumber);
					break;
				case PivotRowType.total:
					currentExcelRowNumber = this.writeTotalRow(pivotTableRow, worksheet, firstColumnNumber, currentExcelRowNumber);
					break;
				default:
					break;
			}

			// iterate to next Excel row to write to
			currentExcelRowNumber++;
		});

	}

	/**
	 * Write Header Row Cells to Excel Worksheet
	 *
	 * @param pivotTableRow
	 * @param worksheet
	 * @param firstColumnNumber
	 * @param currentExcelRowNumber
	 * @param isFirstPivotTableRow
	 * @return currentExcelRowNumber
	 */
	writeHeaderRow<T extends object>(
		pivotTableRow: PivotRow<T>,
		worksheet: Excel.Worksheet,
		firstColumnNumber: number,
		currentExcelRowNumber: number,
		isFirstPivotTableRow: boolean
	): number {
		// test if header row, log an error and return row number (skips row)
		if (pivotTableRow.type !== PivotRowType.header) {
			this.logger.warn('Pivot table row type not header');
			return currentExcelRowNumber;
		}

		// is this row the first pivot table row? and find if normalization annotation exists on any cells in row
		const normalizationRowExists = isFirstPivotTableRow
			&& pivotTableRow.cells.some(cell => (cell.type === PivotCellType.columnHeader) && cell.firstAnnotationOfType(PivotAnnotationType.normalization));

		// track the current column in Excel that we are writing to or have just finished writing to
		let currentExcelColumnNumber = firstColumnNumber;

		// iterate on cells
		for (const [pivotTableCellIndex, pivotTableCell] of pivotTableRow.cells.entries()) {
			if (!pivotTableCell.isHeader) {
				this.logger.warn('Pivot table cell type not header cell');
				break;
			} else {

				// is Excel cell writeable?
				if (!this.isExcelCellWriteable(worksheet, currentExcelRowNumber, currentExcelColumnNumber)) {
					currentExcelColumnNumber = this.nextExcelColumnNumberToWriteTo(worksheet, currentExcelRowNumber, currentExcelColumnNumber);
				}

				// set Excel cell to write to
				let currentExcelCellToWriteTo = worksheet.getRow(currentExcelRowNumber).getCell(currentExcelColumnNumber);

				// if first row
				if (isFirstPivotTableRow) {
					// if normalization row add 1 to rowspan; will be added another excel row for normalization annotation values
					let effectiveRowSpan = pivotTableCell.rowSpan + (normalizationRowExists ? 1 : 0);

					// if cell type is empty (area description)
					if (pivotTableCell.type === PivotCellType.descriptionArea) {
						// establish what needs to be in category header text
						const displayUnit = pivotTableCell.firstAnnotationOfType(PivotAnnotationType.displayUnit);
						const categoryHeaderText = displayUnit && effectiveRowSpan === 1
							? pivotTableCell.displayValue + '/' + displayUnit.text
							: pivotTableCell.displayValue;

						// set actual current Excel row to keep track of, if there is a rowspan greater than 1 and display unit annotation is present
						const actualCurrentExcelRowNumber = currentExcelRowNumber;

						// if row span more than 1 and there is a display unit
						if (effectiveRowSpan > 1 && displayUnit) {
							// set value display code
							currentExcelCellToWriteTo.value = displayUnit.text;
							// merge cell
							worksheet.mergeCells(currentExcelRowNumber, currentExcelColumnNumber, currentExcelRowNumber + (effectiveRowSpan - 2), currentExcelColumnNumber + (this.NUM_OF_EXCEL_COLUMNS_USED_BY_PIVOT_ROW_HEADERS - 1));
							// set styling
							this.styleCellForHeaderAndGrandTotal(currentExcelCellToWriteTo);
							this.setAlignment(currentExcelCellToWriteTo, 'center', 'middle');

							// set current excel row to write to
							currentExcelRowNumber += (effectiveRowSpan - 1);

							// set effective row span
							effectiveRowSpan = 1;
						}
						// set cell to write to
						currentExcelCellToWriteTo = worksheet.getRow(currentExcelRowNumber).getCell(currentExcelColumnNumber);

						// set value + display code
						currentExcelCellToWriteTo.value = categoryHeaderText;
						// merge cell
						worksheet.mergeCells(currentExcelRowNumber, currentExcelColumnNumber, currentExcelRowNumber + (effectiveRowSpan - 1), currentExcelColumnNumber + (this.NUM_OF_EXCEL_COLUMNS_USED_BY_PIVOT_ROW_HEADERS - 1));
						// set styling
						this.styleCellForHeaderAndGrandTotal(currentExcelCellToWriteTo);
						// note: no need to align - automatically aligns to the left

						// set current excel row number back to actual row
						// note: will be the same number if above conditional doesn't trigger
						currentExcelRowNumber = actualCurrentExcelRowNumber;

						// increment current column number
						currentExcelColumnNumber += (this.NUM_OF_EXCEL_COLUMNS_USED_BY_PIVOT_ROW_HEADERS - 1);

						// set last column in area description width
						worksheet.getColumn(currentExcelColumnNumber).width = 36;

						// else if cell type is column header
					} else if (pivotTableCell.type === PivotCellType.columnHeader) {
						// set column header widths
						worksheet.getColumn(currentExcelColumnNumber).width = 18;

						// set value
						currentExcelCellToWriteTo.value = pivotTableCell.displayValue;

						// if colspan - merge cells
						worksheet.mergeCells(currentExcelRowNumber, currentExcelColumnNumber, currentExcelRowNumber, currentExcelColumnNumber + (pivotTableCell.columnSpan - 1));

						// if normalization row exists, write to it
						if (normalizationRowExists) {
							const cellBelow = this.getCellBelow(worksheet, currentExcelRowNumber, currentExcelColumnNumber);
							cellBelow.value = pivotTableCell.firstAnnotationOfType(PivotAnnotationType.normalization)?.text;
							worksheet.mergeCells(parseInt(cellBelow.row), currentExcelColumnNumber, parseInt(cellBelow.row), currentExcelColumnNumber + (pivotTableCell.columnSpan - 1));

							// set style on cell below
							this.styleCellForHeaderAndGrandTotal(cellBelow);
							this.setAlignment(cellBelow, 'center', 'middle');
						}

						// if missing filing annotation exists, write it below normalization annotation (if it exists)
						if (pivotTableCell.firstAnnotationOfType(PivotAnnotationType.missingFilingReason)) {
							const howManyRowsBelow = normalizationRowExists ? 2 : 1;
							const cellBelow = this.getCellBelow(worksheet, currentExcelRowNumber, currentExcelColumnNumber, howManyRowsBelow);
							cellBelow.value = pivotTableCell.firstAnnotationOfType(PivotAnnotationType.missingFilingReason)?.text;
							worksheet.mergeCells(parseInt(cellBelow.row), currentExcelColumnNumber, parseInt(cellBelow.row) + (pivotTableCell.rowSpan - 2), currentExcelColumnNumber);

							// set style on cell below
							this.styleCellForHeaderAndGrandTotal(cellBelow);
							this.setAlignment(cellBelow, 'center', 'middle');
						}

						// set styling on cell
						this.styleCellForHeaderAndGrandTotal(currentExcelCellToWriteTo);
						this.setAlignment(currentExcelCellToWriteTo, 'center', 'middle');

						// set current column
						currentExcelColumnNumber += (pivotTableCell.columnSpan - 1);

						// if last cell in first row set new current row to write to based on if there is an annotation row
						if (pivotTableRow.cells.length - 1 === pivotTableCellIndex) {
							currentExcelRowNumber += normalizationRowExists ? 1 : 0;
						}

						// else error - skip cell
					} else {
						this.logger.warn('Pivot table cell type not found');
						break;
					}

					// else any other row but first row
				} else {
					// if column header
					if (pivotTableCell.type === PivotCellType.columnHeader) {
						// set column header widths
						worksheet.getColumn(currentExcelColumnNumber).width = 18

						// set value
						currentExcelCellToWriteTo.value = pivotTableCell.displayValue;
						worksheet.mergeCells(currentExcelRowNumber, currentExcelColumnNumber, currentExcelRowNumber + (pivotTableCell.rowSpan - 1), currentExcelColumnNumber + (pivotTableCell.columnSpan - 1));

						// set style
						this.styleCellForHeaderAndGrandTotal(currentExcelCellToWriteTo);
						this.setAlignment(currentExcelCellToWriteTo, 'center', 'middle');

						// set current column
						currentExcelColumnNumber += (pivotTableCell.columnSpan - 1);

						// else error
					} else {
						this.logger.warn('Pivot table cell type not found');
						break;
					}
				}
			}

			// iterate to next excel column to write to
			currentExcelColumnNumber = this.nextExcelColumnNumberToWriteTo(worksheet, currentExcelRowNumber, currentExcelColumnNumber);
		}

		return currentExcelRowNumber;
	}

	/**
	 * Write Data Row Cells to Excel Worksheet
	 *
	 * @param pivotTableRow
	 * @param worksheet
	 * @param firstColumnNumber
	 * @param currentExcelRowNumber
	 * @return currentExcelRowNumber
	 */
	writeDataRow<T extends object>(
		pivotTableRow: PivotRow<T>,
		worksheet: Excel.Worksheet,
		firstColumnNumber: number,
		currentExcelRowNumber: number
	): number {
		// track the current column in Excel that we are writing to
		let currentExcelColumnNumber = firstColumnNumber;

		for (const [pivotTableCellIndex, pivotTableCell] of pivotTableRow.cells.entries()) {
			// is Excel cell writeable?
			if (!this.isExcelCellWriteable(worksheet, currentExcelRowNumber, currentExcelColumnNumber)) {
				currentExcelColumnNumber = this.nextExcelColumnNumberToWriteTo(worksheet, currentExcelRowNumber, currentExcelColumnNumber);
			}

			// set Excel cell to write to
			let currentExcelCellToWriteTo = worksheet.getRow(currentExcelRowNumber).getCell(currentExcelColumnNumber);

			// if first cell in row
			if (pivotTableCellIndex === 0) {
				if (pivotTableCell.isHeader) {
					// set cell to write to based on level
					currentExcelCellToWriteTo = worksheet.getRow(currentExcelRowNumber).getCell(currentExcelColumnNumber + ((pivotTableRow.level ?? 1) - 1));
					// write value or value with account code annotation
					currentExcelCellToWriteTo.value = pivotTableCell.firstAnnotationOfType(PivotAnnotationType.accountCode)
						? pivotTableCell.displayValue + ` (${pivotTableCell.firstAnnotationOfType(PivotAnnotationType.accountCode)!.text})`
						: pivotTableCell.displayValue;
					// set current to column 5
					currentExcelColumnNumber = this.NUM_OF_EXCEL_COLUMNS_USED_BY_PIVOT_ROW_HEADERS;
					// style every cell in row
					this.styleBackgroundColorForRowHeader(worksheet, firstColumnNumber, currentExcelRowNumber, 'data');
				} else {
					this.logger.warn('Pivot table cell type not found');
					break;
				}
			} else {
				if (pivotTableCell.type === PivotCellType.data) {
					// set value
					currentExcelCellToWriteTo.value = pivotTableCell.value;
					currentExcelCellToWriteTo.numFmt = pivotTableCell.excelNumberFormat;
					// set alignment
					this.setAlignment(currentExcelCellToWriteTo, 'right');
				} else {
					this.logger.warn('Pivot table cell type not found');
					break;
				}
			}

			// set style
			this.styleCellForData(currentExcelCellToWriteTo);

			// iterate to next excel column to write to
			currentExcelColumnNumber = this.nextExcelColumnNumberToWriteTo(worksheet, currentExcelRowNumber, currentExcelColumnNumber);
		}

		// if child rows trigger recursion
		if (pivotTableRow.childRows) {
			for (let i = 0; i < pivotTableRow.childRows.length; i++) {
				currentExcelRowNumber++;
				currentExcelRowNumber = this.writeDataRow(pivotTableRow.childRows[i], worksheet, firstColumnNumber, currentExcelRowNumber);
			}
		}

		return currentExcelRowNumber;
	}

	/**
	 * Write Total Row Cells to Excel Worksheet
	 *
	 * @param pivotTableRow
	 * @param worksheet
	 * @param firstColumnNumber
	 * @param currentExcelRowNumber
	 * @return currentExcelRowNumber
	 */
	writeTotalRow<T extends object>(
		pivotTableRow: PivotRow<T>,
		worksheet: Excel.Worksheet,
		firstColumnNumber: number,
		currentExcelRowNumber: number
	): number {
		// track the current column in Excel that we are writing to
		let currentExcelColumnNumber = firstColumnNumber;

		for (const [pivotTableCellIndex, pivotTableCell] of pivotTableRow.cells.entries()) {
			// set Excel cell to write to
			const currentExcelCellToWriteTo = worksheet.getRow(currentExcelRowNumber).getCell(currentExcelColumnNumber);

			// if first cell in row
			if (pivotTableCellIndex === 0) {
				if (pivotTableCell.isHeader) {
					// write value or value with account code annotation
					currentExcelCellToWriteTo.value = pivotTableCell.displayValue;
					// set current to column 5
					currentExcelColumnNumber = this.NUM_OF_EXCEL_COLUMNS_USED_BY_PIVOT_ROW_HEADERS;
					// style every cell in row
					this.styleBackgroundColorForRowHeader(worksheet, firstColumnNumber, currentExcelRowNumber, 'header');
				} else {
					this.logger.warn('Pivot table cell type not found');
					break;
				}
			} else {
				if (pivotTableCell.type === PivotCellType.data) {
					// set value
					currentExcelCellToWriteTo.value = pivotTableCell.value;
					currentExcelCellToWriteTo.numFmt = pivotTableCell.excelNumberFormat;
					// set alignment
					this.setAlignment(currentExcelCellToWriteTo, 'right');
				} else {
					this.logger.warn('Pivot table cell type not found');
					break;
				}
			}

			// set style
			this.styleCellForHeaderAndGrandTotal(currentExcelCellToWriteTo);

			// iterate to next excel column to write to
			currentExcelColumnNumber = this.nextExcelColumnNumberToWriteTo(worksheet, currentExcelRowNumber, currentExcelColumnNumber);
		}

		return currentExcelRowNumber;
	}

	/**
	 * Iterate to next cell to write to, test if its writeable
	 * Return excel column number to write to
	 *
	 * @param worksheet
	 * @param currentExcelRowNumber
	 * @param currentExcelColumnNumber
	 * @return currentExcelColumnNumber
	 */
	nextExcelColumnNumberToWriteTo(
		worksheet: Excel.Worksheet,
		currentExcelRowNumber: number,
		currentExcelColumnNumber: number
	): number {
		// iterate to next column
		currentExcelColumnNumber += 1;

		// is Excel writeable?
		const isExcelCellWriteable = this.isExcelCellWriteable(worksheet, currentExcelRowNumber, currentExcelColumnNumber);

		if (!isExcelCellWriteable) {
			// check over cells in row, write to the next cell that is not merged and doesn't have a value
			let i = 1, stopLoop = false;
			while (!stopLoop) {
				const c = worksheet.getRow(currentExcelRowNumber).getCell(currentExcelColumnNumber + i);
				if (!c.isMerged && !c.value) {
					currentExcelColumnNumber = parseInt(c.col);
					stopLoop = true;
				}
				i++;
			}
		}

		return currentExcelColumnNumber;
	}

	/**
	 * Test if cell is writeable
	 *
	 * @param worksheet
	 * @param currentExcelRowNumber
	 * @param currentExcelColumnNumber
	 * @return boolean
	 */
	isExcelCellWriteable(
		worksheet: Excel.Worksheet,
		currentExcelRowNumber: number,
		currentExcelColumnNumber: number
	): boolean {
		const cellToWriteTo = worksheet.getRow(currentExcelRowNumber).getCell(currentExcelColumnNumber);
		return !(cellToWriteTo.isMerged || cellToWriteTo.value);
	}

	/**
	 * Style background color for every cell in pivot row headers
	 *
	 * @param worksheet
	 * @param firstColumnNumber
	 * @param currentCellRowNumber
	 * @param type
	 */
	styleBackgroundColorForRowHeader(
		worksheet: Excel.Worksheet,
		firstColumnNumber: number,
		currentCellRowNumber: number,
		type: 'header' | 'data'
	) {
		for (let i = 0; i < this.NUM_OF_EXCEL_COLUMNS_USED_BY_PIVOT_ROW_HEADERS; i++) {
			const cellToWriteTo = worksheet.getRow(currentCellRowNumber).getCell(firstColumnNumber + i);
			if (type === 'header') {
				this.styleCellForHeaderAndGrandTotal(cellToWriteTo);
			}

			if (type === 'data') {
				this.styleCellForData(cellToWriteTo);
			}
		}
	}

	// light gray
	styleCellForData(cell: Excel.Cell) {
		cell.fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: {argb: 'f3f3f3'},
		};
	}

	// blue
	styleCellForHeaderAndGrandTotal(cell: Excel.Cell) {
		cell.fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: {argb: '2459a9'},
		};

		cell.font = {
			color: {argb: 'FFFFFF'},
			bold: true
		};
	}

	// set alignment
	setAlignment(
		cellToWriteTo: Excel.Cell,
		horizontal: 'right' | 'center',
		vertical?: 'middle'
	) {
		if (vertical) {
			cellToWriteTo.alignment = {vertical: vertical, horizontal: horizontal}
		} else {
			cellToWriteTo.alignment = {horizontal: horizontal};
		}
	}

	/**
	 * Find placeholder cell
	 *
	 * @param worksheet
	 * @return placeholder
	 */
	findPlaceHolderCell(worksheet: Excel.Worksheet): { row: number, col: number } {
		let placeholder = {
			row: 1,
			col: 1
		};
		worksheet.eachRow(row =>
			row.eachCell(cell => {
				if (cell.value === '<grid goes here>') {
					cell.value = null; // allows for top left column (column 1) to be written correctly
					placeholder = {
						row: parseInt(cell.row),
						col: parseInt(cell.col)
					}
				}
			})
		)

		return placeholder;
	}

	getCellBelow = (
		worksheet: Excel.Worksheet,
		currentExcelRowNumber: number,
		currentExcelColumnNumber: number,
		howManyRowsBelow = 1
	) => worksheet.getRow(currentExcelRowNumber + howManyRowsBelow).getCell(currentExcelColumnNumber);

	//endregion
}
