import {ResolveFn, UrlSegment} from '@angular/router';
import {Breadcrumb} from '../../layout/breadcrumb/breadcrumb';
import {BreadcrumbService} from '../breadcrumb.service';
import {inject} from '@angular/core';
import {resolveLogicalAccount} from './dollars-logical-account.resolver';
import {map} from 'rxjs/operators';
import {AccountDescriptor} from '../api/fit-api/models/snapshots/account-descriptor';
import {DatasetService} from '../api/fit-api/dataset.service';
import {switchMap, forkJoin} from 'rxjs';
import {DollarsCategoryService} from '../dollars-category.service';
import {resolveByDollarsDatasets} from './by-dollars-datasets.resolver';

export const dollarsBreadcrumbsResolver: ResolveFn<Array<Breadcrumb>> = (route) => {
	// Generate base breadcrumbs (skip bars segment in URL)
	const breadcrumbService = inject(BreadcrumbService);
	const baseCrumbs = breadcrumbService.generateBreadcrumbs(route.url ?? new Array<UrlSegment>())
		.filter(crumb => crumb.label.toLowerCase() !== 'bars');

	// We need to get the resolved dataset to get the dataset detail
	const datasetService = inject(DatasetService);
	const datasets = resolveByDollarsDatasets();
	const datasetsWithDetail = datasets.pipe(
		switchMap(datasets =>
			forkJoin(
				datasets.map(dataset => datasetService.getDatasetDetail(dataset.datasetType, dataset.id))
			)
		)
	);

	// Resolve Logical Account & iterate through parentage;
	// Generate any needed additional breadcrumbs for parents
	return resolveLogicalAccount(route).pipe(switchMap(logicalAccount => {
		return datasetsWithDetail.pipe(map(datasetsWithDetail => {
			if (datasetsWithDetail.length) {
				// Prepare array of crumbs to build
				const additionalCrumbs= [] as Array<Breadcrumb>;

				datasetsWithDetail.forEach(datasetDetail => {
					const accounts = datasetDetail.detail.accountDescriptors;
					const fsSections = datasetDetail.detail.financialSummarySections;

					const foundLogicalAccount = accounts.find(account => account.logicalAccount === logicalAccount.logicalAccount);
					if (foundLogicalAccount && additionalCrumbs.length === 0) {
						// The resolved logicalAccount to start with
						let currentAccount: AccountDescriptor | undefined = logicalAccount as AccountDescriptor;

						// We will add a crumb at the end for FSSectionId if we started with a BARS Account (ie, logicalAccount is AccountDescriptor)
						const fsSection = fsSections.find(fs => fs.id === currentAccount?.fsSectionId);

						// Follow parents recursively and create crumbs
						while (accounts && currentAccount?.parentId) {
							// Find parent
							const parent = accounts.find(a => a.id === currentAccount?.parentId);
							const isParentSegmentSupported = DollarsCategoryService.defaultBARSHierarchy
								.some(item => parent && item.toLowerCase().startsWith(parent.acctSgmt.toLowerCase()));
							if (parent && isParentSegmentSupported) {
								// Create crumb
								additionalCrumbs.push(new Breadcrumb(parent.name, '../' + parent.logicalAccount));
							}
							// Make parent current account & repeat
							currentAccount = parent;
						}

						if (fsSection) {
							additionalCrumbs.push(new Breadcrumb(fsSection.name, '../' + fsSection.logicalAccount));
						}
					}
				});
				return baseCrumbs.concat(additionalCrumbs.reverse());
			} else {
				throw new Error(`A dataset must be provided that supports BARS accounts to use this resolver.`);
			}
		}));
	}));

};
