import {Component, HostBinding, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SvgIconComponent} from '@ngneat/svg-icon';
import {OutlookIndicatorComponent} from '../outlook-indicator/outlook-indicator.component';
import {OutlookIndicator} from '../../../../models/outlook-indicator';
import FinancialHealthService, {OutlookConfig} from '../../../../services/financial-health.service';
import {Outlook, OutlookInfo} from '../../../../services/api/fit-api/models/indicators/outlook-info';

@Component({
  selector: 'app-outlook-icon-counts',
  standalone: true,
	imports: [CommonModule, SvgIconComponent, OutlookIndicatorComponent],
  templateUrl: './outlook-icon-counts.component.html',
  styleUrls: ['./outlook-icon-counts.component.scss']
})
export class OutlookIconCountsComponent {
	@HostBinding('class')
	@Input() size: 'small' | 'medium' | 'large' = 'large';
	@Input() displayAllOutlooks?: boolean;
	// This is an array of OutlookInfo or OutlookIndicator objects (one per indicator)
	@Input()
	set outlookIndicators(value: Array<OutlookIndicator | OutlookInfo> | undefined) {
		this.outlookIndicatorsSummed = this.financialHealthService.getOutlookCounts(value);

		if (this.displayAllOutlooks) {
			this.outlookIndicatorsSummed = FinancialHealthService.OUTLOOKS.map(outlook => {
				const match = this.outlookIndicatorsSummed?.find((outlookIndicator: OutlookIndicator) =>
					outlookIndicator.outlook === outlook.outlook)
				if (!match) {
					return new OutlookIndicator(outlook.outlook, null, 0);
				} else {
					return match;
				}
			})

		}
	}

	outlookIndicatorsSummed?: Array<OutlookIndicator>;

	constructor(
		private financialHealthService: FinancialHealthService
	) {	}
}
