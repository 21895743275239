import {Directive, HostBinding} from '@angular/core';
import {Layout, RouteService} from '../route.service';

/**
 * Applies the Layout string specified by the ActivatedRoute to the host's class attribute.
 */
@Directive({
	selector: '[appLayoutClass]',
	standalone: true
})
export class LayoutClassDirective {

	@HostBinding('class') layout?: Layout;

	constructor(
		private route: RouteService
	) {
		this.route.layout.subscribe(layout => this.layout = layout);
	}

}
