/**
 * https://www.30secondsofcode.org/js/s/count-by/
 * Groups the elements of an array based on the given function and returns the count of elements in each group.
 * @param array Array to be grouped and counted
 * @param func Function to define the groups
 *
 * Examples,
 * countBy([6.1, 4.2, 6.3], Math.floor); // {4: 1, 6: 2}
 * countBy(['one', 'two', 'three'], 'length'); // {3: 2, 5: 1}
 * countBy([{ count: 5 }, { count: 10 }, { count: 5 }], x => x.count); // {5: 2, 10: 1}
 *
 */
export const countBy = <T,V extends keyof T>(array: Array<T>, func: (e:T) => any | any ) =>
	array.map(typeof func === 'function' ? func : val => val[func]).reduce((acc, val) => {
		acc[val] = (acc[val] || 0) + 1;
		return acc;
	}, {});
