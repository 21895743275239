/**
 * Provides the intersection of the provided array values. That is, the values that exist in all arrays.
 * @param {Array<Array<T>>} data
 * @returns {Array<T>}
 */
export const intersection = <T>(data: Array<Array<T>>): Array<T> =>
	data.reduce(
		(accumulator, currentValue) => accumulator.filter(
			value => currentValue.includes(value)
		)
	)
