<img class="logo" ngSrc="assets/fit-logo-color.svg" height="36" width="120" alt="FIT Logo" priority />
<app-omni-search *ngIf="!(contentHasOmniSearch | async)"></app-omni-search>
<ul class="header-actions">
	<li *ngIf="!user?.claim?.contactID">
		<a [routerLink]="[]" (click)="login()">
			<svg-icon key="login"></svg-icon>
			Login
		</a>
	</li>
	<li *ngIf="user?.claim?.contactID">
		<a [routerLink]="[]" (click)="logoutDialog?.showModal()">
			<svg-icon key="logout"></svg-icon>
			Logout
		</a>
	</li>
</ul>
<app-dialog #logoutDialog [showCloseButton]="true" title="Logout Confirmation" class="generic" aria-describedby="description">
	<p id="description">Please confirm you wish to logout as an authenticated user.  After logout, you will be taken to the Explore page.</p>
	<ng-container class="actions">
		<button class="dialog-button large outline" (click)="logoutDialog.close()">
			Cancel
		</button>
		<button class="dialog-button large solid" autofocus=autofocus (click)="logout()">
			<svg-icon key="check"></svg-icon>
			Logout
		</button>
	</ng-container>
</app-dialog>




