import {PartialOptions} from 'overlayscrollbars';

// https://kingsora.github.io/OverlayScrollbars/
export const overlayScrollLightVertical: PartialOptions = {
	overflow: {
		x: 'hidden',
	},
	scrollbars: {
		theme: 'os-theme-light',
		visibility: 'auto',
		autoHide: 'move',
		autoHideDelay: 400,
	}
};

export const overlayScrollDarkVertical: PartialOptions = {
	overflow: {
		x: 'hidden',
	},
	scrollbars: {
		theme: 'os-theme-dark',
		visibility: 'auto',
		autoHide: 'move',
		autoHideDelay: 400,
	}
};

export const overlayScrollDarkHorizontal: PartialOptions = {
	overflow: {
		y: 'hidden',
	},
	scrollbars: {
		theme: 'os-theme-dark',
		visibility: 'auto',
		autoHide: 'move',
		autoHideDelay: 400,
	}
};

export const overlayScrollDeactivate: PartialOptions = {
	overflow: {
		y: 'hidden',
		x: 'hidden'
	},
};
