import {Injectable} from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	UrlSegment,
	UrlSegmentGroup
} from '@angular/router';
import {Breadcrumb} from '../layout/breadcrumb/breadcrumb';
import {TitleCasePipe} from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class BreadcrumbService {

	constructor(
		private router: Router,
		private titleCasePipe: TitleCasePipe
	) { }

	generateBreadcrumbs(segments: Array<UrlSegment>, govNameOrGovType?: string, routeSnapshot?: ActivatedRouteSnapshot) {
		// Reset breadcrumb array
		const breadcrumbs = new Array<Breadcrumb>();

		// Iterate through url segments to build breadcrumb items
		segments.forEach((s, i) => {
			// We only want the segments other than the last segment for the breadcrumbs
			if (i < segments.length - 1) {
				const route = this.routeLink(segments.slice(0, i + 1));
				// Lookup title from route config
				const title = this.router.config.find(r => r.path === route)?.title;
				// Handle case where title is not a static string; otherwise use title cased segment path
				let titleText = typeof title === 'string' ? title : this.titleCasePipe.transform(s.path);
				// If route is financial health indicator, change 'Health' to 'Financial health' in breadcrumbs (per spec)
				if (titleText === 'Health') {
					titleText = 'Financial Health'
				}
				// If the url array segment matches the mcag or gov type code on the activate route snapshot, replace segment with gov or gov type name
				const mcagOrGovTypeCode = routeSnapshot?.params['mcag'] || routeSnapshot?.params['govTypeCode'];
				if (titleText === mcagOrGovTypeCode && govNameOrGovType) {
					titleText = govNameOrGovType;
				}
				// Create new crumb and add to the list
				breadcrumbs.push(new Breadcrumb(titleText, '/' + route));
			}
		});

		return breadcrumbs;
	}

	// Builds a route link with url segments
	routeLink(urlSegments: UrlSegment[]) {
		const tree = new UrlSegmentGroup(urlSegments, {});
		return tree.toString();
	}
}
