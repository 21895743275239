import {ActivatedRouteSnapshot, ResolveFn} from '@angular/router';
import {map} from 'rxjs';
import {resolveGovernment} from './local-government.resolver';

export const governmentNameResolver: ResolveFn<string> = (route, state) => {
	return resolveGovernmentName(route);
};
export const resolveGovernmentName = (route: ActivatedRouteSnapshot) => {
	return resolveGovernment(route).pipe(
		map(govt => govt.commonName ?? '')
	);
};
