import {Directive} from '@angular/core';
import {FileService} from '../file.service';
import {PercentPipe} from '@angular/common';

@Directive({
	selector: '[appDownload]',
	standalone: true
})
export abstract class DownloadDirective {
	/**
	 * Currently in a state of downloading a file or not.
	 */
	isDownloading = false;
	/**
	 * A HttpResponseHeader event indicated an event.ok === false.
	 * We need to save this condition because more progress events, etc.
	 * can be received after this HttpResponseHeader event.
	 */
	downLoadFailed = false;
	/**
	 * Status message that can be displayed with the download spinner.
	 */
	downloadStatusMessage = '';

	constructor(
		private fileService: FileService,
		private percentPipe: PercentPipe
	) {
	}

	downloadFile(link: string, disabled = false) {
		if (this.isDownloading || disabled) {
			return;
		}

		this.isDownloading = true;
		this.downLoadFailed = false;

		this.fileService.download(link).subscribe(d => {
			if (!this.downLoadFailed) {
				if (d.state === 'inProgress') {
					this.downloadStatusMessage = this.percentPipe.transform(d.progress, '1.0-0') ?? '';
				} else {
					this.downloadStatusMessage = `Download ${d.state}`;
				}
			}
			// Turn off downloading indicator when done or if there is an error
			this.downLoadFailed = this.downLoadFailed || d.state === 'error';

			if (d.state === 'done' || d.state === 'error') {
				// Leave the spinner in place for some time in order for the user to read 'Error' or 'Done'
				setTimeout(() => {
					this.isDownloading = false
				}, 1000);
			}
		});
	}
}
