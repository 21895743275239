import {CanActivateFn, createUrlTreeFromSnapshot} from '@angular/router';
import {DatasetType} from '../api/fit-api/models/datasets/dataset-type';
import {inject} from '@angular/core';
import {DatasetService} from '../api/fit-api/dataset.service';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';

export const adminCanActivateSnapshotDatasetGuard: CanActivateFn  = (route, state) => {
	// 1. Check if datasetType provided. If no, return true
	const datasetType = route.paramMap.get('snapshotDatasetType') as DatasetType;
	if (!datasetType) {
		return true;
	}

	// Otherwise get the Dataset Observable requested and determine if the requested dataset's type is valid for this route
	const datasetId = route.paramMap.get('snapshotDatasetId') ? Number(route.paramMap.get('snapshotDatasetId')) : undefined;
	const datasetService = inject(DatasetService);
	const routeDataset = datasetService.getDataset(datasetType, datasetId)
		// return null for any errors that occur in order to rewrite url below
		.pipe(catchError(() => of(null)));

	// Determine valid dataset types for this route
	const validDatasetTypes = [DatasetType.Snapshot];

	return routeDataset.pipe(map((routeBased) => {
		// If the dataset types do not agree, redirect to the originator without optional parameters for default resolution.
		// Also note the optional chain operator on routeDatasource since Datasets you do not have access to do not get
		//  returned from the API. (i.e., Live, Schools.) This obviates the need to check a UserService.
		const datasetType = validDatasetTypes.find(dt => dt === routeBased?.datasetType);
		if (!datasetType) {
			// todo this should specifically remove datasetType and datasetId instead of just killing all optional params
			return createUrlTreeFromSnapshot(route, ['/', ...route.url.map(x => x.path)]);
		}

		// fallback to true
		return true;
	}));
};
