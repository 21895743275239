import {MCAG} from "../mcag";
import {RankingSortOrder} from "./ranking-sort-order";

export class Ranking {
	constructor(
		public mcag: MCAG,
		public year: number,
		public section: Section,
		public link: any[] | string | null | undefined,
		public name: string,
		public sortOrder: RankingSortOrder,
		public rank: number,
		public amount: number,
		public amountFormat: AmountFormat
	) {}
}

export enum Section {
	financial = 'financial',
	nonFinancial = 'nonFinancial'
}

export enum AmountFormat {
	currency = 'currency',
	decimal = 'decimal'
}
