import {MCAG} from '../mcag';
import {GovTypeCode} from '../gov-type-code';
import {Outlook, OutlookInfo} from './outlook-info';
import {FilingBasisType} from '../snapshots/filing-basis';
import {SummaryGroup} from './indicator-report';
import {MissingFilingReason} from '../missing-filing-reason';


export class IndicatorSummary {
	public mcag: MCAG;
	public year: number;
	public countyCode: number;
	public countyName: string;
	public govTypeCode: GovTypeCode;
	public govTypeDesc: string;

	// @deprecated
	public indicatorOutlooks: Array<OutlookInfo>;

	public filingBasis: FilingBasisType;

	public missingFilingReason?: MissingFilingReason;

	public groups: Array<IndicatorSummaryGroup>

	constructor(
		data: IndicatorSummary
	) {
		this.mcag = data.mcag;
		this.year = data.year;
		this.countyCode = data.countyCode;
		this.countyName = data.countyName;
		this.govTypeCode = data.govTypeCode;
		this.govTypeDesc = data.govTypeDesc;
		this.indicatorOutlooks = data.indicatorOutlooks.map(x =>
			new OutlookInfo(x.outlook, x.annotation)
		);
		this.filingBasis = data.filingBasis;
		this.missingFilingReason = data.missingFilingReason;
		this.groups = data.groups;
	}
}
class IndicatorSummaryGroup {
	constructor(
		public group: SummaryGroup,
		public outlook: Outlook,
		public annotation: string,
		public totalIndicators: number,
		public indicatorOutlookCounts: Array<IndicatorOutlookCounts>
	) {}
}

class IndicatorOutlookCounts {
	constructor(
		public good: number,
		public cautionary: number,
		public concerning: number,
		public indeterminate: number,
	) {}
}
