import { Injectable } from '@angular/core';
import {EMPTY, Observable, of, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ExtractType, ExtractTypeId} from '../pages/data-extracts/extract-type';
import {SnapshotWithDetail} from './api/fit-api/models/datasets/snapshot';
import {ExtractYear} from '../pages/data-extracts/extract-year';
import {ODataCollectionResult} from './api/odata-collection-result';
import {LocalGovernment} from './api/fit-api/models/local-government';
import {DatasetSource} from './api/fit-api/models/datasets/dataset-source';
import {DatasetDetail} from './api/fit-api/models/datasets/dataset-type';
import {Team} from './api/fit-api/models/team';
import {SearchItem} from '../models/search-item';
import {GovernmentType} from './api/fit-api/models/government-type';

@Injectable({
	providedIn: 'root'
})
export class DataExtractsService {
	baseExtractsUrl = '/extracts';

	public extractTypes: Array<ExtractType> = [
		new ExtractType(
			'full',
			'Full Extracts',
			'Download full, single-year extracts of FIT data with reference tables.',
			'Data extracts for each filing year are available for download in Excel 2007 format (.xlsx).',
			true
		),
		new ExtractType(
			'omwbe',
			'OMWBE Extracts',
			'Download two-year extracts of capital projects, supplies and other services expenditure totals used by OMWBE.',
			'Expenditure extracts used by the Office of Minority and Women\'s Business Enterprises (OMWBE) for each two-year period are available for download in Excel format (.xlsx). These extracts contain capital projects, supplies and other services expenditure totals for cities, towns, counties, ports, and transportation authorities from the latest published snapshot.',
			true
		),
		new ExtractType(
			'census-debt-balances',
			'US Census Debt Balances',
			'Download single-year extracts of debt balance totals for use by the US Census.',
			'Debt balance extracts for each filing year are available for download in comma-separated values format (.csv). These extracts contain data from the latest published snapshot for the selected year.',
		),
		new ExtractType(
			'census-revenues-expenditures',
			'US Census Revenues & Expenditures',
			'Download single-year extracts of revenues & expenditures for use by the US Census.',
			'Revenue and expenditure extracts for each filing year are available for download in comma-separated values format (.csv). These extracts contain data from the latest published snapshot for the selected year.',
		),
		new ExtractType(
			'debt-service-coverage',
			'State Partner Debt Service Coverage Ratios',
			'Download single-year extracts of debt service coverage ratios for use by SAO\'s state partners.',
			'Debt service coverage ratio (DSCR) extracts for each filing year are available for download in comma-separated values format (.csv). These extracts contain DSCR calculations as defined by SAO\'s state partners and use the latest published snapshot for the calculations.',
		),
		new ExtractType(
			'operating-results',
			'Global Operating Results (Worksheets)',
			'Download single-year worksheet of live FIT data, with built-in variance comparison formulas.',
			'A global operating results worksheet for each filing year (for GAAP governments) is available for download in Excel format (.xlsx) with built-in variance comparison formulas. These worksheets contain live data.',
			false,
			'Worksheets',
			true,
			'globalAccess'
		),
		new ExtractType(
			'financial-health-indicators',
			'Financial Health Indicators',
			'Download year-over-year extracts of financial health indicators for internal agency use.',
			'Financial health indicator trend reports (by team, government type, individual government, or all governments) are available for download in Excel format (.xlsx). These reports use the latest published snapshot data.',
			true,
			'',
			false,
			'globalAccess',
			true,
			'FinancialHealthIndicators'
		),
		new ExtractType(
			'pension-liability',
			'Pension Liability Variances',
			'Download multi-year extracts comparing pension liabilities as reported by the government and DRS.',
			'Participating Employer Financial Information (PEFI) variance reports (accessible by team, government type, individual government, or all governments) are available for download in Excel format (.xlsx). These reports use the live annual filing data and data from the Department of Retirement Systems (DRS).',
			true,
			'',
			false,
			'globalAccess',
			true,
			'PensionLiabilityVariances'
		)
	];
    constructor(
		private http: HttpClient
	) { }

	getPublicExtractTypes = () => this.extractTypes.filter(e => !e.restrictedToRole);

	getGlobalAccessExtractTypes = () => this.extractTypes.filter(e => e.restrictedToRole === 'globalAccess');

	getExtractType = (id: ExtractTypeId) => this.extractTypes.find(e => e.id === id);

	getExtractYears = (extractType: ExtractType, datasetDetail?: Observable<DatasetDetail | null>): Observable<Array<ExtractYear>> => {
		if (!extractType.isYearsBased) {
			return EMPTY;
		}

		switch (extractType?.id) {
			case 'full':
				return this.getFullExtractYears(datasetDetail);
			case 'omwbe':
				return this.getOMWBEExtractYears(datasetDetail);
				break;
			case 'census-debt-balances':
				return this.getCensusYears('Debt');
			case 'census-revenues-expenditures':
				return this.getCensusYears('RevExp');
			case 'debt-service-coverage':
				return this.getDebtCoverageYears();
			case 'operating-results':
				return this.getGlobalOperatingYears();
		}

		throw new Error('Extract type undefined: ' + extractType);
	}

	private descendingYearSort = (a: ExtractYear, b: ExtractYear): -1 | 0 | 1 => a.year > b.year ? -1 : 1;

	private getYearsWithFullExtracts (dataset: SnapshotWithDetail) {
		return this.http.get<ODataCollectionResult<number>>(`${dataset.route}/GetFullExtractYears`).pipe(map(result => result.value));
	}
    private getFullExtractYears(datasetDetail?: Observable<DatasetDetail | null>): Observable<Array<ExtractYear>> {
		if (datasetDetail) {
			return datasetDetail.pipe(switchMap(dataset => {
				if (dataset instanceof SnapshotWithDetail) {
					return this.getYearsWithFullExtracts(dataset).pipe(map(years => {
						const extractYears = new Array<ExtractYear>();
						years.sort().reverse().forEach(y => {
							const url = `${dataset.route}/FullExtract(year=${y})`;
							const extractYear = new ExtractYear(y, url);
							extractYears.push(extractYear);
						});
						return extractYears;
					}));
				}
				return of(new Array<ExtractYear>());
			}));
		}
		return of(new Array<ExtractYear>());
	}

	private getOMWBEExtractYears(datasetDetail?: Observable<DatasetDetail | null>): Observable<Array<ExtractYear>> {
		if (datasetDetail) {
			return datasetDetail.pipe(map(dataset => {
				if (dataset instanceof SnapshotWithDetail) {
					// creates new array out of snapshot included years using just odd years, where a prior year exist
					const years = dataset.detail.includedYears.filter((x, i, a) => x % 2 !== 0 && typeof a[i - 1] !== 'undefined');
					const extractYears = new Array<ExtractYear>();
					years.sort().reverse().forEach(y => {
						const url = `${this.baseExtractsUrl}/OMWBE/(endYear=${y})`;
						const extractYear = new ExtractYear(y, url, dataset.dateCreated, `${y - 1}/${y}`);
						extractYears.push(extractYear);
					});
					return extractYears;
				}
				return new Array<ExtractYear>();
			}));
		}
		return of(new Array<ExtractYear>());
	}

	private getCensusYears (type: 'RevExp' | 'Debt'): Observable<Array<ExtractYear>> {
		return this.http.get<Array<ExtractYear>>(this.baseExtractsUrl + '/Census')
			.pipe(
				map(x => {
					x.map(y => y.url = `${this.baseExtractsUrl}/Census/${type}/${y.year}`);
					return x.sort(this.descendingYearSort);
				})
			);
	}

	private getDebtCoverageYears(): Observable<Array<ExtractYear>> {
		const baseUrl = this.baseExtractsUrl + '/DebtCoverage';
		return this.http.get<Array<ExtractYear>>(baseUrl)
			.pipe(
				map(x => x.map(y => {
					y.url = `${baseUrl}/${y.year}`;
					y.dateCreated = null;
					return y;
				}).sort(this.descendingYearSort))
			);
	}

	private getGlobalOperatingYears(): Observable<Array<ExtractYear>> {
		const baseUrl = this.baseExtractsUrl + '/GlobalOperatingResults';
		return this.http.get<Array<number>>(baseUrl)
			.pipe(
				map(x => x.map(y => new ExtractYear(y, `${baseUrl}/${y}`))
					.sort(this.descendingYearSort)),
			);
	}

	private getFilterableBaseUrl = (extractType: ExtractType) => this.baseExtractsUrl + '/' + extractType.filterableBaseUrl;

	public getTeamsUrl = (extractType: ExtractType) => this.getFilterableBaseUrl(extractType) + '/Teams';
	public getGovernmentsUrl = (extractType: ExtractType) => this.getFilterableBaseUrl(extractType) + '/Governments';
	public getGovTypeCodesUrl = (extractType: ExtractType) => this.getFilterableBaseUrl(extractType) + '/GovTypeCodes';
	public getAllUrl = (extractType: ExtractType) =>  this.getFilterableBaseUrl(extractType) + '/All';

	public getTeams(extractType: ExtractType): Observable<Array<SearchItem>> {
		return this.http.get<Array<Team>>(this.getTeamsUrl(extractType)).pipe(
			map(x => x.map(t =>
				new SearchItem(
					'Team',
					undefined,
					t.code,
					t.description,
					'download',
					this.getTeamsUrl(extractType) + '/' + t.code,
					[ { label: 'Team Code', value: t.code, alwaysDisplay: true } ],
				)
			))
		);
	}

	public getGovernments(extractType: ExtractType): Observable<Array<SearchItem>> {
		return this.http.get<Array<LocalGovernment>>(this.getGovernmentsUrl(extractType)).pipe(
			map(x => x.map(govt => {
				const g = new LocalGovernment(govt);
				return 	new SearchItem(
					'Government',
					g,
					g.mcag,
					g.fullNameForSearching ?? '',
					'download',
					this.getGovernmentsUrl(extractType) + '/' + g.mcag,
					[ { label: 'MCAG', value: g.mcag, alwaysDisplay: true } ],
				)
			}))
		);
	}

	public getGovernmentTypes(extractType: ExtractType): Observable<Array<SearchItem>> {
		return this.http.get<Array<GovernmentType>>(this.getGovTypeCodesUrl(extractType)).pipe(
			map(x => x.map(gt =>
				new SearchItem(
					'Government Type',
					gt,
					gt.code,
					gt.descriptionPlural ?? gt.description,
					'download',
					this.getGovTypeCodesUrl(extractType) + '/' + gt.code,
					[ { label: 'Government Type Code', value: gt.code, alwaysDisplay: true } ],
					gt.description ?? gt.descriptionPlural
				)
			))
		);
	}

	// All extracts are of SAO annual filing data, but some extracts don't depend on a dataset in order to
	// display the extract type's description and download buttons
	getDatasetSource(extractTypeId: ExtractTypeId) {
		const isSnapshotBased = this.extractTypes.find(et => et.id === extractTypeId)?.isSnapshotBased;
		if (isSnapshotBased) {
			return of(DatasetSource.AnnualFiling);
		}
		return of(DatasetSource.None);
	}
}
