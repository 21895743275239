<svg-icon *ngIf="outlookIndicator?.outlookConfig"
		  [key]="outlookIndicator?.outlookConfig?.icon ?? 'unknown'"
></svg-icon>
<span *ngIf="outlookIndicator?.count !== undefined; else indicator">
	{{outlookIndicator?.count === 0 ? '-' : outlookIndicator?.count}}
</span>
<ng-template #indicator>
	{{outlookIndicator?.outlook}}
</ng-template>


