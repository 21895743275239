import {IndicatorSummary} from '../../services/api/fit-api/models/indicators/indicator-summary';
import {OutlookIndicator} from '../../models/outlook-indicator';
import FinancialHealthService from '../../services/financial-health.service';
import {LocalGovernment} from '../../services/api/fit-api/models/local-government';

export class FinancialHealthListViewModel extends IndicatorSummary {
	overallOutlook: OutlookIndicator;
	overallOutlookSortOrder: number;
	overallOutlookName: string;
	localGovernment: LocalGovernment | undefined;

	constructor(
		indicatorSummary: IndicatorSummary,
		localGovernment?: LocalGovernment
	) {
		super(indicatorSummary);
		this.overallOutlook = FinancialHealthService.aggregateOutlook(this.indicatorOutlooks);
		this.overallOutlookSortOrder = this.overallOutlook.outlookConfig?.sortOrder ?? 0;
		this.overallOutlookName = this.overallOutlook.outlookConfig?.outlook ?? '';

		this.localGovernment = localGovernment;
	}
}
