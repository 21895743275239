import {ActivatedRouteSnapshot, ResolveFn} from '@angular/router';
import {map} from 'rxjs/operators';
import {resolveFinancialHealthIndicatorGroups} from './financial-health-indicator-groups.resolver.service';
import {FinancialHealthIndicatorResolvedRouteData} from '../../models/financial-health-indicator-resolved-route-data';

/**
 * Resolve indicator for indicator code
 * Flatten indicator codes resolved from route and find indicator using the indicator code route param
 *
 * @param route
 * @param state
 */
export const financialHealthIndicatorResolver: ResolveFn<object | undefined> = (route, state) => {
	return resolveFinancialHealthIndicator(route);
};

export const resolveFinancialHealthIndicator = (route: ActivatedRouteSnapshot) => {
	const indicatorCode = route.paramMap.get(FinancialHealthIndicatorResolvedRouteData.indicatorCode);
	return resolveFinancialHealthIndicatorGroups(route).pipe(indicatorGroups => {
		return indicatorGroups.pipe(map(indicatorGroups => indicatorGroups
			.map((indicatorGroup) => indicatorGroup.indicators)
			.flat()
			.find((indicator) => indicator.instanceCode === indicatorCode)));
	});
};
