import {Injectable} from '@angular/core';
import {DatasetDetail, DatasetType} from './api/fit-api/models/datasets/dataset-type';
import {DatasetService} from './api/fit-api/dataset.service';
import {combineLatest, forkJoin, map, Observable, of, ReplaySubject, switchMap} from 'rxjs';
import {SnapshotWithDetail} from './api/fit-api/models/datasets/snapshot';
import {LiveWithDetail} from './api/fit-api/models/datasets/live';
import {BasicAccount, DollarsCategory} from '../models/dollars-category';
import {FinancialSummaryService} from './financial-summary.service';
import {sortByProperty} from './functions/sort-by-property';
import {FilingStatusService} from './filing-status.service';
import {prepareRankings} from './functions/prepare-rankings';
import {FinancialService} from './api/fit-api/financial.service';
import {FinancialSummarySectionId} from './api/fit-api/models/financial-summary';
import {Palette} from '../models/palette';
import {FsSectionFilerCount} from './api/fit-api/models/fs-section-filer-count';
import {FinancialSummarySection} from './api/fit-api/models/snapshots/financial-summary-section';
import {AccountDescriptor} from './api/fit-api/models/snapshots/account-descriptor';
import {ActivatedRoute, Router} from '@angular/router';
import {lowerCaseFirst} from './functions/lower-case-first';
import {Ranking} from '../models/ranking';
import {FitApiService} from './api/fit-api/fit-api.service';
import {TrendAmount} from '../models/trend-amount';
import {LoggerService} from './logger.service';
import {GovernmentType} from './api/fit-api/models/government-type';
import {LocalGovernment} from './api/fit-api/models/local-government';
import {County} from './api/fit-api/models/snapshots/county';
import {yearsToRangeText} from './functions/years-to-range-text';
import {getPaletteValue} from './functions/get-palette-value';
import {GovernmentSpecificity} from './api/fit-api/models/government-specificity';
import {SchoolsWithDetail} from './api/fit-api/models/datasets/schools';
import {AggregationByGovType} from './api/fit-api/models/aggregation-by-gov-type';
import {intersection} from './functions/array/intersection';
import {shareReplay} from 'rxjs/operators';
import {Dataset} from './api/fit-api/models/datasets/dataset';
import {AggregationByGovt} from './api/fit-api/models/aggregation-by-govt';

@Injectable({
	providedIn: 'any'
})
export class DollarsCategoryService {

	public static supportedDatasetTypes = [
		DatasetType.Snapshot, // Default dataset type should be first in the array
	] as const;

	public static excludedFSSectionIds: Array<number> = [
		80, // Balance Sheet
	];

	// ordered BARS hierarchy list used for schedule 1 aggregations
	public static defaultBARSHierarchy: Array<string> = [
		'fsSectionId',
		'basicAccountId',
		'subAccountId',
		'elementId',
		'subElementId'
	];

	// data for by dollars profile (parent and child)
	color?: Palette;
	colorValue?: string; // For chart rendering

	logicalAccount = this.route.data.pipe(
		map(routeData => routeData['logicalAccount'] as FinancialSummarySection | AccountDescriptor)
	);

	dollarsFieldName = this.logicalAccount.pipe(map(logicalAccount => {
		if (this.isFSSection(logicalAccount)) {
			return 'fsSectionId';
		} else {
			// BARS Account
			return lowerCaseFirst(logicalAccount.acctSgmt + 'Id');
		}
	}));

	dollarsId = this.logicalAccount.pipe(map(logicalAccount => logicalAccount.id));

	/**
	 * DatasetsDetail for this route.  This profile page will use a merged multi-dataset approach similar to the
	 * Financial Health profile page.
	 */
	datasetsDetail = this.route.data.pipe(
		map(data => data['datasets'] as Array<Dataset>),
		// create an observable call for each dataset and recombine with forkJoin
		switchMap(datasets =>
			forkJoin(
				datasets.map(dataset => this.datasetService.getDatasetDetail(dataset.datasetType, dataset.id))
			)
		)
	);

	/**
	 * Fiscal display year for this route.
	 */
	fiscalYear = new ReplaySubject<number>(1);

	/**
	 * Fiscal display year for this route.
	 */
	defaultFiscalYear: Observable<number> = this.datasetsDetail.pipe(
		map(datasets => {
			const years = intersection(datasets.map(x => x.detail.includedYears));
			return Math.max(...years);
		}),
		shareReplay(1)
	);

	yearRange = this.fiscalYear.pipe(map(year =>
		yearsToRangeText([year, year ? year - FinancialService.NUM_TREND_YEARS + 1 : null])
	));

	/**
	 * Totals by year for field, no subcategory or gov detail
	 * Use case - trendline graph
	 */
	categoryTotalsForYearRange = combineLatest([this.datasetsDetail, this.fiscalYear, this.dollarsFieldName, this.dollarsId])
		.pipe(switchMap(([datasetsDetail, displayYear, dollarsFieldName, dollarsId]) => {
			if (displayYear) {
				return this.getTotalsByField(datasetsDetail, displayYear, displayYear - FinancialService.NUM_TREND_YEARS + 1, dollarsFieldName, dollarsId);
			} else {
				throw Error('Display year never emitted');
			}
		}));

	/**
	 * Totals for display year for field
	 * Use case - info header total (singular total)
	 */
	categoryTotal = combineLatest([this.categoryTotalsForYearRange, this.fiscalYear])
		.pipe(map(([categoryTotals, fiscalYear]) => {
			// return singular value from schedule 1 aggregations array (value that matches display year)
			// if there isn't a total amount, display zero
			return categoryTotals.find(categoryTotal => categoryTotal.year === fiscalYear)?.totalAmountExclIntlSrvc ?? 0;
		}))

	/**
	 * Totals for field for display year, no subcategory detail, mcag detail, govType detail, and county detail
	 * Use case - by government list (pre view model)
	 */
	categoryTotalsByGovt: Observable<Array<AggregationByGovt>> =
		combineLatest([this.datasetsDetail, this.fiscalYear, this.dollarsFieldName, this.dollarsId])
		.pipe(switchMap(([datasetsDetail, fiscalYear, dollarsFieldName, dollarsId]) => {
			const queryOverrides = this.generateQueryOverridesForLogicalAccount(dollarsFieldName, dollarsId, false);
			return forkJoin(datasetsDetail.map(dataset => {
				if ((dataset instanceof SnapshotWithDetail || dataset instanceof LiveWithDetail)) {
					return this.financialSummaryService.getSchedule1AggregationsByGovt(dataset, fiscalYear, fiscalYear, queryOverrides);
				} else if (dataset instanceof SchoolsWithDetail) {
					return this.financialSummaryService.getOSPIFinancialReportAggregationsByGovt(dataset, fiscalYear, fiscalYear, queryOverrides)
				}
				return of([])
			})).pipe(map(x => x.flat().filter(agg => agg.totalAmountExclIntlSrvc !== 0)));
		}));

	/**
	 * Totals for field for display year for govType detail (rolls up categoryTotalsByGov)
	 * Use case - by government type list (pre view model)
	 */
	categoryTotalsByGovType: Observable<Array<ListRowForDollarsProfile>> = this.categoryTotalsByGovt.pipe(map(categoryTotals => {
		// a) group governments by government type
		return categoryTotals.reduce((acc: ListRowForDollarsProfile[], curr: AggregationByGovt) => {
			const filer = acc.find((x: ListRowForDollarsProfile) => x.govTypeCode === curr.govTypeCode);
			if (filer) {
				filer.totalAmountExclIntlSrvc += curr.totalAmountExclIntlSrvc;
				filer.numberOfGovs ? filer.numberOfGovs += 1 : 0;
			} else {
				// create gov type schedule 1 model out gov model
				// set mcag and county code to zero and start count of govs
				curr.mcag = null;
				curr.countyCode = null;
				acc.push({
					// pass schedule1AggregationByGovt with extended field
					...curr,
					numberOfGovs: 1,
				} as ListRowForDollarsProfile);
			}
			return acc;
		}, []);
	}));

	/**
	 * Totals for field for display year, subcategory detail
	 * Use case - by government type list (pre view model)
	 */
	categoryTotalsBySubcategory = combineLatest([this.datasetsDetail, this.fiscalYear, this.dollarsFieldName, this.dollarsId])
		.pipe(switchMap(([datasetsDetail, fiscalYear, dollarsFieldName, dollarsId]) => {
			const queryOverrides = this.generateQueryOverridesForLogicalAccount(dollarsFieldName, dollarsId, true);
			return forkJoin(datasetsDetail.map(dataset => {
				if ((dataset instanceof SnapshotWithDetail || dataset instanceof LiveWithDetail)) {
					return this.financialSummaryService.getSchedule1AggregationsByGovType(dataset, fiscalYear, fiscalYear, queryOverrides);
				} else if (dataset instanceof SchoolsWithDetail) {
					return this.financialSummaryService.getOSPIFinancialReportAggregationsByGovType(dataset, fiscalYear, fiscalYear, queryOverrides);
				} else {
					throw new Error(`Can't resolve dataset ${dataset}`)
				}
			})).pipe(map(x => this.combineAggregationByGovType(x.flat())));

		}));

	/**
	 * Missing filer totals by year for field, no subcategory or gov detail
	 * Use case - trendline graph
	 */
	missingFilersForYearRange = combineLatest([this.datasetsDetail, this.fiscalYear, this.dollarsFieldName, this.dollarsId])
		.pipe(switchMap(([datasetsDetail, fiscalYear, dollarsFieldName, dollarsId]) => {
			const datasetDetail = datasetsDetail.find(dataset => dataset.datasetType === DatasetType.Live || dataset.datasetType === DatasetType.Snapshot);
			if (fiscalYear && (datasetDetail instanceof LiveWithDetail || datasetDetail instanceof SnapshotWithDetail)) {
				const queryOverrides = this.generateQueryOverridesForLogicalAccount(dollarsFieldName, dollarsId, false);
				const filterString = this.financialSummaryService.generateFilterStringForBarsAggregations(datasetDetail, fiscalYear, fiscalYear - FinancialService.NUM_TREND_YEARS + 1, queryOverrides, GovernmentSpecificity.Government);
				return this.financialService.getSchedule1AggregationMissingFilings(datasetDetail, filterString, fiscalYear, fiscalYear - FinancialService.NUM_TREND_YEARS + 1);
			}
			return []; // Only SAO Annual Filer datasets can have "missing" filings
		}));

	/**
	 * Missing filer totals for field for display year, no subcategory or gov detail
	 * (return missing filers last filing that is not display year)
	 * Use case - What’s Missing for FY yyyy? list
	 */
	missingFilersForDisplayYear = combineLatest([this.missingFilersForYearRange, this.fiscalYear])
		.pipe(map(([missingFilers, displayYear]) => {
				// a) group missing filers by mcag
				const groupedMissingFilers = Object.values(missingFilers.reduce((acc: { [x: string]: AggregationByGovt[] }, current: AggregationByGovt) => {
					if (current.mcag) {
						acc[current.mcag] = acc[current.mcag] ?? [];
						acc[current.mcag].push(current);
					}
					return acc;
				}, {}));

				// b) filter out missing filer records for display year and return most recent year w/o zero filing amount
				return groupedMissingFilers.map((gmf: Array<AggregationByGovt>) =>
					gmf.filter((mf: AggregationByGovt) => mf.year !== displayYear && mf.totalAmountExclIntlSrvc !== 0)
						// sort descending and return most recent missing filer record
						.sort(sortByProperty((mf => mf.year), false))[0])
					// and remove missing filer groups that are empty
					.filter(gmf => gmf);
			}
		));

	/**
	 * View model for by dollars page, by government list
	 */
	localGovsForList = this.generateByDollarsForGovernmentId(this.categoryTotalsByGovt);

	/**
	 * View model for by dollars page, by government type list
	 */
	govTypesForList = this.generateByDollarsForGovernmentId(this.categoryTotalsByGovType);

	/**
	 * View model for by dollars page, by subcategories list
	 */
	subcategoriesForList: Observable<ListRowForDollarsProfile[]> =
		combineLatest([this.generateByDollarsForGovernmentId(this.categoryTotalsBySubcategory), this.logicalAccount])
		.pipe(map(([category, logicalAccount]) => {
		// filter - for subcategory filers that have one subcategory that ids match, return empty array (don't show list)
		// for logging actual subcategory list before filter is set
		// this.logger.log(category));
		return category.length === 1 ? this.findSubcategoryFieldValue(category[0] as ListRowForDollarsProfile) === logicalAccount.id ? [] : category : category;
	}));

	/**
	 * View model for by dollars page, by subcategories list
	 */
	missingFilersForList = this.generateByDollarsForGovernmentId(this.missingFilersForDisplayYear);

	/**
	 * Number of filers for field for display year for by dollars page
	 * Use case - info header x, x governments out of y filers
	 */
	numberOfCategoryFilers = this.categoryTotalsByGovt.pipe(map(categoryFilers => categoryFilers.length));

	/**
	 * Summarized filing status for display year for by dollars page
	 * Use case - info header y, x governments out of y filers
	 */
	summarizedFilingStatusesForYear = (govTypeCode: Observable<string | undefined>) => combineLatest([this.datasetsDetail, this.fiscalYear, govTypeCode])
		.pipe(switchMap(([datasetsDetail, fiscalYear, govTypeCode]) => {
			return forkJoin(datasetsDetail.map(datasetDetail => {
				if (fiscalYear) {
					if (datasetDetail instanceof SnapshotWithDetail || datasetDetail instanceof LiveWithDetail) {
						return this.filingStatusService.getSummarizedFilingStatuses(datasetDetail, fiscalYear, govTypeCode).pipe(map(x => x.totalExpectedFilings));
					} else if (datasetDetail instanceof SchoolsWithDetail) {
						const queryOverrides = new Map([
							['fsSectionId', 'ne null'],
							['mcag', 'ne null'],
							['totalAmountExclIntlSrvc', 'ne 0']  // Exclude governments with zero amounts
						]);
						if (govTypeCode) {
							queryOverrides.set('govTypeCode', `eq '${govTypeCode}'`);
						}
						const filterString = this.financialSummaryService.generateFilterStringForBarsAggregations(datasetDetail, fiscalYear, fiscalYear, queryOverrides, GovernmentSpecificity.Government);
						return this.financialService.getOSPIAggregationTotalFilers(datasetDetail, filterString);
					} else {
						throw new Error(`Can't resolve dataset ${datasetDetail}`)
					}
				} else {
					throw Error('Display year never emitted');
				}
			})).pipe(map(x => {
				let totalCount: number | undefined = undefined;
				x.forEach(value => {
					if (value) {
						totalCount = (totalCount ?? 0) + value;
					}
				});
				return totalCount;
			}));

		}));

	// trend line view models

	/**
	 * View model for category totals for trendline for by dollars page;
	 * sort by year ascending so that the data points are drawn in order
	 */
	categoryTotalsForTrendLine = this.categoryTotalsForYearRange.pipe(map(catTotals =>
		catTotals.sort(sortByProperty(x => x.year)).map(x => new TrendAmount(x.year, x.totalAmountExclIntlSrvc))
	));

	/**
	 * View model for missing filer category totals for trendline (toggle) for by dollars page;
	 * the categoryTotalsForTrendLine is a sorted array of totals per year (ascending by year);
	 * the results returned from this method maintains the order of this input.
	 */
	categoryTotalsMinusMissingFilersTotalsForTrendLine = combineLatest([this.categoryTotalsForTrendLine, this.missingFilersForYearRange])
		.pipe(map(([categoryTotalsForTrendLine, missingFilersForYearRange]) => {
			// a) group missing filers by year
			const groupedMissingFilers = missingFilersForYearRange.reduce((acc: TrendAmount[], curr: AggregationByGovt) => {
				const filer = acc.find((x: TrendAmount) => x.year === curr.year);
				if (filer) {
					filer.amount += curr.totalAmountExclIntlSrvc;
				} else {
					acc.push(new TrendAmount(curr.year, curr.totalAmountExclIntlSrvc));
				}
				return acc;
			}, []);

			// b) return subtracted missing filer totals from category totals as trend amount view model
			return categoryTotalsForTrendLine.map(ct => {
				const match = groupedMissingFilers.find(gmf => gmf.year === ct.year);
				return new TrendAmount(ct.year, ct.amount - (match?.amount ?? 0));
			})
		}))

	constructor(private datasetService: DatasetService,
				private financialSummaryService: FinancialSummaryService,
				private financialService: FinancialService,
				private filingStatusService: FilingStatusService,
				private route: ActivatedRoute,
				private fitApiService: FitApiService,
				private router: Router,
				private logger: LoggerService) {

		// set by dollars profile color
		this.logicalAccount.subscribe(category => {
			if (category && 'fsSectionId' in category && category.fsSectionId) {
				this.color = this.generatePalette(category.fsSectionId);
			} else if (category) {
				this.color = this.generatePalette(category.id);
			} else {
				// something went wrong if it gets to here
				this.color = 'grey';
			}
			this.colorValue = getPaletteValue(this.color);
		});
	}

	// Dollars Selection
	/**
	 * Return view model array (Dollars Categories) for 'By Dollars' Selection Page
	 * This method does the following:
	 * 1) Create array of Dollars Category objects (FS Sections) with nested array of Subcategory account - Basis Account/BARS 2nd Digit" objects (view model)
	 * 2) Call data source aggregation endpoints to populate totals to corresponding FS Section or subcategory
	 * 2) Add total number of filers and number who filed for that fsSection to the corresponding fsSection object
	 * @param datasets
	 * @param displayYear
	 */
	getCategoriesForDollarsSelection(
		datasets: Array<DatasetDetail>,
		displayYear: number
	): Observable<Array<DollarsCategory>>
	{
		const dollarsCategories: Array<DollarsCategory> = [];
		datasets.forEach(dataset => {
			// 1) Create view model structure from this dataset's reference data (merge with any existing)
			dataset?.detail?.financialSummarySections
				// exclude balance sheet
				.filter(fsSection => !DollarsCategoryService.excludedFSSectionIds.includes(fsSection.id))
				.forEach(fsSection => {
					const category = dollarsCategories.find(category => category.id === fsSection.id);
					if (!category) {
						dollarsCategories.push(new DollarsCategory(
							fsSection.name,
							fsSection.id,
							fsSection.sortOrder,
							fsSection.logicalAccount,
							this.generatePalette(fsSection.id)
						));
					}
				});

			// Add all Basic Account level categories to the appropriate FS Sections
			dataset?.detail?.accountDescriptors
				.filter(account => account.acctSgmt === 'BasicAccount' && account.deletedFromHierarchy === false)
				.forEach(account => {
					// match account id with dollars category id
					const category = dollarsCategories.find(category => category.id === account.fsSectionId);

					if (category) {
						const basicAccount = category.basicAccounts.find(basicAccount => basicAccount.id === account.id);
						if (!basicAccount) {
							category.basicAccounts?.push(new BasicAccount(account.name, account.id, account.categoryDisplay, account.logicalAccount))
						}
					}
				});
		});

		// Four calls:
		// a) schedule 1 totals for FsSection for the display year
		// b) schedule 1 totals for BasicAccount for the display year
		// c) schedule 1 number of filers for the display year
		// d) total number of filers for the year (filing status endpoint)
		const totalsForFsSection = this.getTotalsByField(datasets, displayYear, displayYear, 'fsSectionId', null);
		const totalsForBasicAccount = this.getTotalsByField(datasets, displayYear, displayYear, 'basicAccountId', null);
		const filersPerFsSections = this.getFilerCountPerFsSection(datasets, displayYear);
		const expectedFilers = this.getExpectedFilerCount(datasets,displayYear);

		return forkJoin([totalsForFsSection, totalsForBasicAccount, filersPerFsSections, expectedFilers])
			.pipe(map(([totalsForFsSection, totalsForBasicAccount, filersForFsSection, expectedFilers]) => {
				// 2) Add schedule 1 (fsSections and basicAccount) totals to dollar category fields
				totalsForFsSection.forEach(totalForFsSection => {
					// a) Match fsSectionIds and attach total amount to category
					const category = dollarsCategories.find(category =>
						category.id === totalForFsSection.fsSectionId);

					if (category) {
						category.amount = totalForFsSection.totalAmountExclIntlSrvc;
					}
				})

				totalsForBasicAccount.forEach(totalForBasicAccount => {
					// b) Match fsSectionIds AND THEN basicAccountIds and attach total amount to subcategory
					const subCategory = dollarsCategories.find(category =>
						category.id === totalForBasicAccount.fsSectionId)?.basicAccounts.find(subCategory =>
						subCategory.id === totalForBasicAccount.basicAccountId);

					if (subCategory) {
						subCategory.amount = totalForBasicAccount.totalAmountExclIntlSrvc;
					}
				});

				// 3) Add filer data (xxx governments out of xxxx in Washington) to dollar category fields
				dollarsCategories.forEach(dollarCategory => {
					// a) add total amount of expected filers statewide to each dollar category
					dollarCategory.expectedFilersStatewide = expectedFilers;
					// b) match schedule 1 filers for fsSection by fsSectionId and add total to dollar category
					dollarCategory.filersForFsSection = filersForFsSection.find(schedule1Filer => schedule1Filer.fsSectionId === dollarCategory.id)?.numberOfFilers;

					// prepare rankings (set percentage totals and sorts by ranking; filter out any )
					dollarCategory.basicAccounts =
						prepareRankings(dollarCategory.basicAccounts.filter(account => account.amount));
				})

				// return view model
				return dollarsCategories;
			}));


	}

	/**
	 * Return grouped fsSections by total amount of mcags that filed for each section
	 * @param datasets
	 * @param displayYear
	 */
	getFilerCountPerFsSection(
		datasets: Array<DatasetDetail>,
		displayYear: number): Observable<Array<FsSectionFilerCount>>
	{
		const queryOverrides = new Map([
			['fsSectionId', 'ne null'],
			['mcag', 'ne null'],
			['totalAmountExclIntlSrvc', 'ne 0']  // Exclude governments with zero amounts
		]);

		return forkJoin(datasets.map(dataset => {
			const filterString = this.financialSummaryService.generateFilterStringForBarsAggregations(dataset, displayYear, displayYear, queryOverrides, GovernmentSpecificity.Government);
			if ((dataset instanceof SnapshotWithDetail || dataset instanceof LiveWithDetail)) {
				return this.financialService.getSchedule1AggregationTotalFilersPerFsSection(dataset, filterString);
			} else if (dataset instanceof SchoolsWithDetail) {
				return this.financialService.getOSPIAggregationTotalFilersPerFsSection(dataset, filterString);
			} else {
				throw new Error(`Can't resolve dataset ${dataset}`)
			}
		})).pipe(map(x => {
			const combined: Array<FsSectionFilerCount> = [];
			x.flat().forEach(row => {
				const foundRow = combined.find(combinedRow => combinedRow.fsSectionId === row.fsSectionId);
				if (foundRow) {
					foundRow.numberOfFilers += row.numberOfFilers;
				} else {
					combined.push(row);
				}
			});
			return combined;
		}));
	}

	getExpectedFilerCount(
		datasets: Array<DatasetDetail>,
		displayYear: number): Observable<number | undefined>
	{
		return forkJoin(datasets.map(dataset => {
			if ((dataset instanceof SnapshotWithDetail || dataset instanceof LiveWithDetail)) {
				return this.filingStatusService.getSummarizedFilingStatuses(dataset, displayYear).pipe(map(x => x.totalExpectedFilings));
			} else if (dataset instanceof SchoolsWithDetail) {
				const queryOverrides = new Map([
					['fsSectionId', 'ne null'],
					['mcag', 'ne null'],
					['totalAmountExclIntlSrvc', 'ne 0']  // Exclude governments with zero amounts
				]);
				const filterString = this.financialSummaryService.generateFilterStringForBarsAggregations(dataset, displayYear, displayYear, queryOverrides, GovernmentSpecificity.Government);
				return this.financialService.getOSPIAggregationTotalFilers(dataset, filterString);
			} else {
				throw new Error(`Can't resolve dataset ${dataset}`)
			}
		})).pipe(map(x => {
			let totalCount: number | undefined = undefined;
			x.forEach(value => {
				if (value) {
					totalCount = (totalCount ?? 0) + value;
				}
			});
			return totalCount;
		}));
	}

	/**
	 *
	 * @param datasets
	 * @param displayYear
	 *
	 * @deprecated refactor and combine with getExpectedFilerCount() & summarizedFilingStatusesForYear()
	 *
	 * todo - Refactor with with getExpectedFilerCount() & summarizedFilingStatusesForYear()
	 */
	getFilerCount(
		datasets: Array<DatasetDetail>,
		displayYear: number): Observable<number | undefined>
	{
		return forkJoin(datasets.map(dataset => {
			if ((dataset instanceof SnapshotWithDetail || dataset instanceof LiveWithDetail)) {
				return this.filingStatusService.getSummarizedFilingStatuses(dataset, displayYear).pipe(map(x => x.filers));
			} else if (dataset instanceof SchoolsWithDetail) {
				const queryOverrides = new Map([
					['fsSectionId', 'ne null'],
					['mcag', 'ne null'],
					['totalAmountExclIntlSrvc', 'ne 0']  // Exclude governments with zero amounts
				]);
				const filterString = this.financialSummaryService.generateFilterStringForBarsAggregations(dataset, displayYear, displayYear, queryOverrides, GovernmentSpecificity.Government);
				return this.financialService.getOSPIAggregationTotalFilers(dataset, filterString);
			} else {
				throw new Error(`Can't resolve dataset ${dataset}`)
			}
		})).pipe(map(x => {
			let totalCount: number | undefined = undefined;
			x.forEach(value => {
				if (value) {
					totalCount = (totalCount ?? 0) + value;
				}
			});
			return totalCount;
		}));
	}

	/**
	 * Generate color palette for each fsSection
	 * @param fsSectionId
	 */
	generatePalette(fsSectionId: FinancialSummarySectionId): Palette {
		if (fsSectionId === 20 || fsSectionId === 25) {
			return 'green';
		} else if (fsSectionId === 30 || fsSectionId === 35) {
			return 'purple';
		} else if (fsSectionId === 10 || fsSectionId === 40) {
			return 'blue';
		} else {
			// default case (something went wrong if return is grey)
			return 'grey';
		}
	}

	// end section

	// dollars info header
	getTotalsByField(
		datasets: Array<DatasetDetail>,
		endYear: number,
		startYear: number,
		dollarsFieldName: string,
		dollarsFieldId: number | null): Observable<Array<AggregationByGovType>>
	{
		const queryOverrides = this.generateQueryOverridesForLogicalAccount(dollarsFieldName, dollarsFieldId, false);
		return forkJoin(datasets.map(dataset => {
			if ((dataset instanceof SnapshotWithDetail || dataset instanceof LiveWithDetail)) {
				return this.financialSummaryService.getSchedule1AggregationsByGovType(dataset, endYear, startYear, queryOverrides);
			} else if (dataset instanceof SchoolsWithDetail) {
				return this.financialSummaryService.getOSPIFinancialReportAggregationsByGovType(dataset, endYear, startYear, queryOverrides);
			} else {
				throw new Error(`Can't resolve dataset ${dataset}`)
			}
		})).pipe(map(x => this.combineAggregationByGovType(x.flat())));

	}

	combineAggregationByGovType(data: Array<AggregationByGovType>) {
		const combined: Array<AggregationByGovType> = [];
		data.forEach(dataRow => {
			const foundRow = combined.find(combinedRow => combinedRow.year === dataRow.year && combinedRow.govTypeCode === dataRow.govTypeCode
				&& combinedRow.countyCode === dataRow.countyCode && combinedRow.fsSectionId === dataRow.fsSectionId
				&& combinedRow.fundCategoryId === dataRow.fundCategoryId && combinedRow.fundTypeId === dataRow.fundTypeId
				&& combinedRow.basicAccountId === dataRow.basicAccountId && combinedRow.subAccountId === dataRow.subAccountId
				&& combinedRow.elementId === dataRow.elementId && combinedRow.subElementId === dataRow.subElementId);
			if (foundRow) {
				foundRow.totalAmount += dataRow.totalAmount;
				foundRow.totalAmountExclIntlSrvc += dataRow.totalAmountExclIntlSrvc;
			} else {
				combined.push(Object.assign({},dataRow));
			}
		});
		return combined;
	}

	/**
	 * Generate query overrides for by dollar profile schedule 1 aggregations
	 * @param fieldName
	 * @param fieldId - if null, pass string 'ne null' (get all totals for this field)
	 * @param includeSubcategoryDetails
	 */
	generateQueryOverridesForLogicalAccount(
		fieldName: string,
		fieldId: number | null,
		includeSubcategoryDetails: boolean): Map<string, string> {
		const queryOverrides = new Map();

		// 1) any category above given field set to not equal null
		// a) index number in order hierarchy
		const index = DollarsCategoryService.defaultBARSHierarchy.findIndex(name => name === fieldName);
		// b) slice array at that point (starting at 0 index)
		const slicedArray = DollarsCategoryService.defaultBARSHierarchy.slice(0, index);
		// c) iterate over sliced array and set query overrides to not equal null for any fields include in it
		slicedArray.forEach(fieldName => queryOverrides.set(fieldName, 'ne null'));

		// 2) set actual explicit field that we're retrieving data for
		queryOverrides.set(fieldName, fieldId === null ? 'ne null' : `eq ${fieldId}`);

		if (includeSubcategoryDetails) {
			// if include subcategory detail is set to true, return level of detail below fieldName in Bars Hierarchy
			const childFieldName = DollarsCategoryService.defaultBARSHierarchy[index + 1];

			queryOverrides.set(childFieldName, 'ne null');
		}
		return queryOverrides;
	}

	/**
	 * Return fiscal year for display based on dataset
	 * @param dataset
	 */
	getFiscalYearForDisplay(dataset: DatasetDetail | null): Observable<number | null> {
		return this.financialSummaryService.getFiscalYearForDisplay(dataset);
	}

	private isFSSection = (logicalAccount: FinancialSummarySection | AccountDescriptor)
		: logicalAccount is FinancialSummarySection => {
		return (logicalAccount as FinancialSummarySection).sortOrder !== undefined;
	}

	isBarsAccount = (logicalAccount: FinancialSummarySection | AccountDescriptor)
		: logicalAccount is AccountDescriptor => {
		return (logicalAccount as AccountDescriptor).categoryDisplay !== undefined;
	}

	// end section

	// By dollars Profile

	/**
	 * 	View model for all dollars profile page lists
	 * 	Identifies type by looking up properties on schedule1AggregationByGov or schedule1AggregationByGovType (or any type that extends it) in this order:
	 * 	government
	 * 	government type
	 * 	dollar category (in bars hierarchy) (comes from schedule1AggregationByGovType endpoint)
	 * 	assigns name, navRoute, amount, and ranking
	 * 	Note: In the case of the govType list, pass schedule1AggregationByGovt already extended as ListRowDollarsProfile with number of govs for each gov type
	 * 	@param aggregations
	 */
	generateByDollarsForGovernmentId(aggregations: Observable<AggregationByGovt[] | AggregationByGovType[]>): Observable<Array<ListRowForDollarsProfile>> {
		return combineLatest([
			aggregations,
			this.fitApiService.governmentsForList(),
			this.fitApiService.governmentTypesForList,
			this.fitApiService.countiesForList,
			this.datasetsDetail
		]).pipe(map(([aggregations, localGovs, govTypes, counties, datasetsDetail]) => {
			const rows = aggregations.map(aggregation => {
				const localGov: LocalGovernment | undefined = localGovs.find(gov => gov.mcag === (aggregation as AggregationByGovt).mcag);
				const govType: GovernmentType | undefined = govTypes.find(govType => govType.code === aggregation.govTypeCode);
				const county: County | undefined = counties.find(county => county.countyCode === aggregation.countyCode);

				let subcategory: any | undefined = undefined;
				datasetsDetail.forEach(datasetDetail => {
					const reference = datasetDetail?.detail.getReference('accountDescriptors', this.findSubcategoryFieldValue(aggregation));
					if (reference) {
						subcategory = reference;
					}
				});

				return {
					...aggregation,
					name: localGov?.sortableFullNameForSearching ?? govType?.descriptionPlural ?? subcategory?.name,
					navRoute: localGov ? ['explore', 'government', (aggregation as AggregationByGovt).mcag] : govType ? ['explore', 'government-type', aggregation.govTypeCode] : ['explore', 'dollars', 'bars', subcategory?.logicalAccount],
					amount: aggregation.totalAmountExclIntlSrvc,
					govTypeName: localGov ? govType?.description : null,
					navRouteForGovType: localGov ? ['explore', 'government-type', aggregation.govTypeCode] : null,
					county: localGov ? county?.countyName : null
				} as ListRowForDollarsProfile
			})

			return prepareRankings(rows);
		}));
	}

	/**
	 * Find the subcategory field value that is the lowest level of detail for schedule1AggregationByGovt
	 * 	@param aggregationByGovType
	 */
	findSubcategoryFieldValue(aggregationByGovType: AggregationByGovType): number {
		const reversedHierarchy = [...DollarsCategoryService.defaultBARSHierarchy].reverse();
		const fieldName = reversedHierarchy.find(account => aggregationByGovType[account as keyof AggregationByGovType] !== null);
		return aggregationByGovType[fieldName as keyof AggregationByGovType] as number;
	}

	/**
	 * Navigation function for by dollars profile
	 * Takes user to top of page when on the same route
	 * @param category
	 */
	navigateToProfile(category: ListRowForDollarsProfile | null) {
		if (category) {
			this.router.navigate(category.navRoute).then(() => {
					// scroll to top if navigating to same page
					document.getElementById('scroll-to-top')?.scrollIntoView(true);
				}
			)
		}
	}
}

// Type for view model
export interface ListRowForDollarsProfile extends Ranking, AggregationByGovt, AggregationByGovType {
	name: string;
	navRoute: Array<string>;

	// for gov list
	navRouteForGovType?: Array<string>;
	govTypeName?: string
	county?: string;

	// for gov type list
	numberOfGovs?: number;
}
