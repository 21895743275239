import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

/* eslint-disable no-console */
const noop = (): void => undefined;

@Injectable({
	providedIn: 'root'
})
export class LoggerService {
	readonly isDebug: boolean;

	constructor() {
		this.isDebug = !environment.production;
		// Allow debug override for debugging production
		try {
			const localStorageIsDebug = localStorage.getItem('debug');
			if (localStorageIsDebug === null) {
				return;
			}
			// localStorage is always serialized (primitives are string literals)
			this.isDebug = localStorageIsDebug === 'true';
		} catch (e) {
			// no localStorage available, nothing to do
		}
	}

	// TODO figure out why this bind nonsense is here? Seems complicated.
	get log() {
		return this.isDebug
			? console.log.bind(console)
			: noop;
	}

	get info() {
		return this.isDebug
			? console.info.bind(console)
			: noop;
	}

	get warn() {
		return console.warn.bind(console);
	}

	get error() {
		return console.error.bind(console);
	}

	get debug() {
		return this.isDebug
			? console.debug.bind(console)
			: noop;
	}

	get time() {
		return this.isDebug
			? console.time.bind(console)
			: noop;
	}

	get timeEnd() {
		return this.isDebug
			? console.timeEnd.bind(console)
			: noop;
	}
}
