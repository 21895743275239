/**
 * Returns consecutive count of a given property in an ordered object array from its first item
 * If property never changes returns length of array, if array is empty returns zero
 *
 * @param orderedArray
 * @param property
 */
export const countUntilChange = <T>(orderedArray: Array<T>, property: keyof T ): number => {
	if (orderedArray.length === 0) {
		return 0;
	}

	// note: if the property never changes, the consecutive year count is all the years of data
	const count = orderedArray.findIndex((item: T) => item[property] !== orderedArray[0][property]);
	return count === -1 ? orderedArray.length : count;
}
