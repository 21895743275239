import {ActivatedRouteSnapshot, ResolveFn, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {resolveGovernmentIdDataset} from './government-id-dataset.resolver';
import {FitApiService} from '../api/fit-api/fit-api.service';
import {inject} from '@angular/core';
import {MCAG} from '../api/fit-api/models/mcag';
import {mergeMap, switchMap} from 'rxjs';
import {FinancialSummaryService} from '../financial-summary.service';
import {DatasetService} from '../api/fit-api/dataset.service';
import {FinancialService} from '../api/fit-api/financial.service';
import {GovernmentId} from '../api/fit-api/models/government-id';
import {FinancialHealthIndicatorResolvedRouteData} from '../../models/financial-health-indicator-resolved-route-data';
import {OptionalRouteParam} from '../../models/optional-route-params';

/**
 * Resolve indicator groups for mcag and year range
 * First check if there is a start and end year from the optional route params, if so use that and the mcag from the route
 * to resolve indicator groups. If no year range given, use default year range.
 *
 * @param route
 * @param state
 */
export const financialHealthIndicatorGroupsResolver: ResolveFn<object | undefined> = (route, state) => {
	return resolveFinancialHealthIndicatorGroups(route);
};

export const resolveFinancialHealthIndicatorGroups = (route: ActivatedRouteSnapshot) => {
	const fitApiService = inject(FitApiService);
	const financialSummaryService = inject(FinancialSummaryService);
	const router = inject(Router);

	// We need to get the resolved dataset to get the dataset detail
	const datasetService = inject(DatasetService);
	const dataset = resolveGovernmentIdDataset(route)
	const datasetWithDetail = dataset.pipe(switchMap(dataset => {
		if (dataset) {
			return datasetService.getDatasetDetail(dataset.datasetType, dataset.id);
		} else {
			throw Error('No dataset returned from Financial Health Indicator route')
		}
	}));

	return datasetWithDetail.pipe(
		mergeMap(datasetDetail => {
			const mcag = route.paramMap.get(FinancialHealthIndicatorResolvedRouteData.mcag) as MCAG;

			return financialSummaryService.getFiscalYearForDisplay(datasetDetail, new GovernmentId(mcag)).pipe(map(fy => {
				return {datasetDetail: datasetDetail, fy: fy, mcag: mcag};
			}));
		}),
		mergeMap(({datasetDetail, fy, mcag}) => {
				// use latest fiscal year range off dataset and default year range
				// otherwise start OR end year if present
				if (fy) {
					let startYear = fy - (FinancialService.NUM_TREND_YEARS - 1);
					let endYear = fy;

					if (route.paramMap.get(OptionalRouteParam.startYear)) {
						startYear = Number(route.paramMap.get(OptionalRouteParam.startYear));
					}

					if (route.paramMap.get(OptionalRouteParam.endYear)) {
						endYear = Number(route.paramMap.get(OptionalRouteParam.endYear));
					}

					// if either start or end year is not a number re-route to page not found
					if (isNaN(startYear) || isNaN(endYear)) {
						router.navigate(['/not-found']).then();
					}

					return fitApiService.getIndicatorGroups(datasetDetail, mcag, startYear, endYear);
				} else {
					throw Error('No fiscal year returned from dataset');
				}
			}
		)
	);
};

