/**
 * Create a function that can be used to test that a given string begins with any of the strings in the provided array.
 *  E.g.
 *  ```
 *  	const isAbsoluteUrl = startsWithAny(['http', '//']);
 *  	const test = isAbsoluteUrl('/'); // false
 *  ```
 * @param arr
 */
export const startsWithAny = (arr: string[] = []) => (value = ''): boolean => {
	return arr.some(test =>
		value.toLowerCase().startsWith(test.toLowerCase())
	);
}
