import {Ranking} from '../../models/ranking';
/**
 * Calculate percentageOfTotal and ranking for each member of generic array, return array
 * with percentageOfTotal and ranking attached
 * @param financialCategories
 */
export const prepareRankings = <T extends Ranking>(financialCategories: Array<T>): Array<T> => {
	// if amount is undefined or null, make amount zero
	const total = financialCategories.reduce(((acc: number, cur: T) => acc += cur.amount ?? 0), 0);
	// 1. calculate percentage, 2. sort by percentage, 3. add rankings based on sort order
	return financialCategories.map((financialCategory: T) => {
		return {...financialCategory, percentageOfTotal: (financialCategory.amount ?? 0) / total}
	})
		.sort((a: T, b: T) => b.percentageOfTotal! - a.percentageOfTotal!)
		.map((val: T, i: number) => {
			return {...val, ranking: i + 1}
		});
}
