import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
	selector: 'app-progress-bar',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {

	@Input() isVisible = true;

	@Input() value?: number;

}
