import {OutlookIndicator} from './outlook-indicator';
import {Indicator} from './indicator';
import {GovernmentId} from '../services/api/fit-api/models/government-id';
import {GovernmentSpecificity} from '../services/api/fit-api/models/government-specificity';
import FinancialHealthService from '../services/financial-health.service';

// note: named IndicatorGrouping instead of IndicatorGroup because IndicatorGroup is a model we get off the FIT API
export class IndicatorGrouping {
	//instantiationLevel: InstantiationLevel;
	//report: IndicatorReportType;
	//filingBasis: 'Cash' | 'GAAP' | 'None';

	constructor(
		public group: 'All Governmental Funds' | 'General Fund' | 'Enterprise Funds' | string,
		public governmentId: GovernmentId,
		public indicators: Array<Indicator>,
		public outlookIndicator?: OutlookIndicator)
	{}

	get indicatorOutlooks(): Array<OutlookIndicator> | undefined {
		if (this.governmentId.specificity === GovernmentSpecificity.GovernmentType) {
			return FinancialHealthService.aggregateGovernmentOutlooks(this.indicators);
		}
		return this.indicators?.map(indicator => indicator?.outlookIndicatorForDisplayYear);
	}
}


