import {ActivatedRouteSnapshot, ResolveFn, Router} from '@angular/router';
import {AccountDescriptor} from '../api/fit-api/models/snapshots/account-descriptor';
import {FinancialSummarySection} from '../api/fit-api/models/snapshots/financial-summary-section';
import {DatasetService} from '../api/fit-api/dataset.service';
import {inject} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {EMPTY, forkJoin, Observable, switchMap} from 'rxjs';
import {sortByProperty} from '../functions/sort-by-property';
import {resolveByDollarsDatasets} from './by-dollars-datasets.resolver';

export const dollarsLogicalAccountResolver: ResolveFn<AccountDescriptor | FinancialSummarySection> = (route) => {
	const router = inject(Router);

	return resolveLogicalAccount(route).pipe(
		catchError(() => {
			// todo need to think about this more to figure out how we want to handle not found scenarios
			router.navigateByUrl('/not-found');
			return EMPTY;
		})
	);
};

export const resolveLogicalAccount = (route: ActivatedRouteSnapshot)
	: Observable<AccountDescriptor | FinancialSummarySection> =>
{
	// We need to get the resolved datasets to get the dataset detail
	const datasetService = inject(DatasetService);

	const datasets = resolveByDollarsDatasets();

	const datasetsWithDetail = datasets.pipe(
		switchMap(datasets =>
			forkJoin(
				datasets.map(dataset => datasetService.getDatasetDetail(dataset.datasetType, dataset.id))
			)
		)
	);

	// Get logicalAccount from route
	const logicalAccount = route.paramMap.get('logicalAccount');
	if (logicalAccount) {
		return datasetsWithDetail.pipe(map(datasetsWithDetail => {
			let foundLogicalAccount: AccountDescriptor | FinancialSummarySection | undefined = undefined;
			if (datasetsWithDetail.length) {
				datasetsWithDetail.forEach(dswd => {
					const fsSection = dswd.detail.financialSummarySections
						.find(fs => fs.logicalAccount === logicalAccount);

					const account = dswd.detail.accountDescriptors
						.sort(sortByProperty(bars => bars.id)) // handle duplicates by taking account higher in the hierarchy
						.find(bars => bars.logicalAccount === logicalAccount);

					if ( fsSection ) {
						foundLogicalAccount = fsSection;
					} else if (account ) {
						foundLogicalAccount = account;
					}
				});

				if (foundLogicalAccount) {
					return foundLogicalAccount;
				} else {
					throw new Error(`A valid logicalAccount must be provided to use this resolver. Offered: ${logicalAccount}`);
				}
			} else {
				throw new Error(`A dataset must be provided that supports BARS accounts to use this resolver.`);
			}

		}));
	} else {
		throw new Error(`A logicalAccount must be provided to use this resolver.`);
	}

}
