import { ResolveFn } from '@angular/router';
import {Dataset} from '../api/fit-api/models/datasets/dataset';
import {inject} from '@angular/core';
import {DatasetService} from '../api/fit-api/dataset.service';
import {DatasetType} from '../api/fit-api/models/datasets/dataset-type';
import FinancialHealthService from '../financial-health.service';

export const adminSnapshotDatasetResolver: ResolveFn<Dataset> = (route, state) => {
	const datasetService = inject(DatasetService);
	// 1. If datasetType (+ datasetId) provided, resolve that Dataset
	const datasetType = route.paramMap.get('snapshotDatasetType') as DatasetType;
	if (datasetType) {
		const datasetId = route.paramMap.get('snapshotDatasetId') ? Number(route.paramMap.get('snapshotDatasetId')) : undefined;
		return datasetService.getDataset(datasetType, datasetId);
	}

	// 2. Otherwise, get the default Dataset for this route...
	const defaultDatasetType = DatasetType.Snapshot;

	// 2a. User setting to "use live where available" logic would go here to override the default Dataset Type for the route
	//  DatasetType.Live

	return datasetService.getDataset(defaultDatasetType);
};
