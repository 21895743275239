export enum OptionalRouteParam {
	startYear = 'startYear',
	endYear = 'endYear',
	fundNode = 'fundNode',
	counties = 'counties',
	excludeInternalServiceFunds = 'excludeInternalServiceFunds',
	displayUnit = 'displayUnit',
	showAccountNumbers = 'showAccountNumbers',
	byExpenditureObjects = 'byExpenditureObjects',
}
