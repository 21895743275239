import {forkJoin, Observable} from 'rxjs';
import {ResolveFn} from '@angular/router';
import {resolveGovernmentName} from './government-name.resolver';
import {map} from 'rxjs/operators';
import {resolveFinancialHealthIndicator} from './financial-health-indicator.resolver.service';

export const financialHealthIndicatorTitleResolver: ResolveFn<string> = (route, state) => {
	const mcag = route.paramMap.get('mcag');
	let resolveGovernmentTitle: Observable<string> | undefined = undefined;

	if (mcag) {
		resolveGovernmentTitle = resolveGovernmentName(route);
	}

	if (resolveGovernmentTitle) {
		return forkJoin([resolveGovernmentTitle, resolveFinancialHealthIndicator(route)]).pipe(
			map(([govTitle, financialHealthIndicator]) => `${govTitle}: ${financialHealthIndicator?.subTitle}, ${financialHealthIndicator?.title}`) // for browser title, history
		);
	} else {
		throw Error('Financial Health Indicator title not able to resolve for government');
	}
};
