<label *ngIf="label" [for]="elementId">{{ label }}</label>
<span class="input-container">
	<input
		#searchInput
		class="search-input"
		type="search"
		[id]="elementId"
		[placeholder]="placeholder"
		[value]="value"
	>
	<svg-icon key="search"></svg-icon>
</span>
