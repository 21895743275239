import {Injectable} from '@angular/core';
import {HotkeysService as NgNeatHotkeysService} from '@ngneat/hotkeys';
import {Subject} from 'rxjs';
import {LoggerService} from './logger.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class HotkeysService {

	// NgNeat Hotkeys uses the Angular EventManagerService
	// https://angular.io/api/platform-browser/EventManager
	// KeyboardEvent plugin:
	// https://github.com/angular/angular/blob/main/packages/platform-browser/src/dom/events/key_events.ts

	// The search key was pressed "/"
	search = new Subject<KeyboardEvent>();

	constructor(
		// Proxy
		private ngNeatHotkeys: NgNeatHotkeysService,
		private logger: LoggerService
	) {
		this.registerHotkeys();
		this.logger.debug('Registered hotkeys:', this.ngNeatHotkeys.getHotkeys());
	}

	private registerHotkeys(): void {
		// ngNeatHotkeys kills the entire hotkey once unsubscribed, so do not subscribe consumers directly to it
		this.ngNeatHotkeys.addShortcut({
			group: 'Global shortcuts',
			keys: '/',
			description: 'Open the OmniSearch to find content anywhere in the application.'
		})
			.pipe(untilDestroyed(this))
			.subscribe(event => this.search.next(event));
	}
}
