import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'currencySuffix',
	standalone: true
})
// loosely based on https://stackoverflow.com/questions/48583020/display-number-in-million-or-thousand-format-in-angular-4
export class CurrencySuffixPipe implements PipeTransform {
	transform(input?: number, args?: number): string {
		if (input) {
			const sign = input < 0 ? '-' : '';
			const absoluteValue = Math.round(Math.abs(input));

			if (absoluteValue < 1000) {
				return `${sign}$${absoluteValue.toString()}`;
			}

			const suffixes = ['K', 'M', 'B'];
			const exp = Math.floor(Math.log(absoluteValue) / Math.log(1000));

			return `${sign}$${(absoluteValue / Math.pow(1000, exp)).toFixed(args)}${suffixes[exp - 1]}`;
		} else {
			return '$0'
		}
	}
}
