import {Injectable} from '@angular/core';
import {DatasetService} from '../../services/api/fit-api/dataset.service';
import {EMPTY, forkJoin, Observable, of, switchMap} from 'rxjs';
import {SearchItem} from '../../models/search-item';
import FinancialHealthService from '../../services/financial-health.service';
import {map} from 'rxjs/operators';
import {OutlookIndicator} from '../../models/outlook-indicator';
import {FitApiService} from '../../services/api/fit-api/fit-api.service';
import {LocalGovernment} from '../../services/api/fit-api/models/local-government';
import {UpperCasePipe} from '@angular/common';
import {GovernmentType} from '../../services/api/fit-api/models/government-type';
import {DatasetType} from '../../services/api/fit-api/models/datasets/dataset-type';
import {SnapshotWithDetail} from '../../services/api/fit-api/models/datasets/snapshot';
import {DollarsCategoryService} from '../../services/dollars-category.service';
import {FinancialHealthListViewModel} from '../../pages/financial-health-profile/financial-health-list-view-model';
import {AccountDescriptor} from '../../services/api/fit-api/models/snapshots/account-descriptor';
import {FinancialSummarySection} from '../../services/api/fit-api/models/snapshots/financial-summary-section';
import {FinancialSummaryService} from '../../services/financial-summary.service';
import {lowerCaseFirst} from '../../services/functions/lower-case-first';
import {hasKey} from '../../services/functions/has-key';
import {CurrencySuffixPipe} from '../../services/pipes/currency-suffix.pipe';

@Injectable({
  providedIn: 'root'
})
export class OmniSearchService {

	searchItems?: Observable<Array<SearchItem>>;

	// Get latest snapshot based dataset
	datasetDetail = this.datasetService.getDatasetDetail(DatasetType.Snapshot);

	displayYear: Observable<number|null> = this.datasetDetail.pipe(
		switchMap((datasetDetail) =>
			this.dollarsCategoryService.getFiscalYearForDisplay(datasetDetail)
		)
	);

	// Get list of fsSection dollar categories to use for search items
	fsSections = this.datasetDetail.pipe(map(datasetDetail => {
		if (datasetDetail instanceof SnapshotWithDetail) {
			return datasetDetail.detail.financialSummarySections
				.filter(fs => fs.id !== 80).map(fs =>
					new SearchItem(
						'Dollars Category',
						fs,
						fs.logicalAccount,
						fs.name,
						'routerLink',
						[`/explore/dollars/bars`, fs.logicalAccount],
						// Populate fields & additional search text in forkJoin
					)
				);
		}
		return [] as Array<SearchItem>;
	}));

	// Get list of BARS dollar categories to use for search items
	accounts = this.datasetDetail.pipe(map(datasetDetail => {
		if (datasetDetail instanceof SnapshotWithDetail) {
			return datasetDetail.detail.accountDescriptors
				.filter(bars => !bars.deletedFromHierarchy && bars.primeAccountId !== bars.id && bars.fsSectionId !== 80).map(bars =>
					new SearchItem(
						'Dollars Category',
						bars,
						bars.logicalAccount,
						bars.name,
						'routerLink',
						[`/explore/dollars/bars`, bars.logicalAccount],
						// Populate fields & additional search text in forkJoin
					)
				);
		}
		return [] as Array<SearchItem>;
	}));

	// Get Schedule1 Aggregations to display total dollars for Dollar Category search items
	sch1Aggegrations = forkJoin([this.datasetDetail, this.displayYear]).pipe(switchMap(([dataset,displayYear]) => {
		if (dataset instanceof SnapshotWithDetail && displayYear) {
			// Prepare Schedule 1 Aggregation query filters - we want all FSSection & BARS Account level detail
			const queryOverrides = new Map(FinancialSummaryService.barsHierarchy.map(level => [level, `ne null`]));
			// Fetch Schedule 1 data
			/// return observable singleton view model (Finances at a Glance Card)
			return this.financialSummaryService.getSchedule1AggregationsByGovType(dataset, displayYear, displayYear, queryOverrides);
		}
		return of([]);
	}));


	// Get outlook summaries to display outlooks for Government & Government Type search types
	outlooksByGovernment = forkJoin([this.datasetDetail, this.displayYear]).pipe(switchMap(([dataset,displayYear]) => {
		if (dataset instanceof SnapshotWithDetail && displayYear) {
			return this.fitApiService.getIndicatorSummaries(dataset, displayYear)  // We only need summary records for the display year
				.pipe(map(data => data.map(x => new FinancialHealthListViewModel(x))));
		}
		return of([]);
	}));

	// Build outlook search items
	outlooks = of(FinancialHealthService.OUTLOOKS.map(o =>
		new SearchItem(
			'Financial Health',
			o,
			o.outlook,
			new OutlookIndicator(o.outlook),
			'routerLink',
			[`/explore/financial-health`, { filterOutlook: o.outlook }],
			// Populate fields & additional search text in forkJoin
		)
	));

	// Build government search items
	governments = this.fitApiService.governmentsForSearch.pipe(map(govts => govts.map(govt =>
		new SearchItem(
			'Government',
			govt,
			govt.mcag,
			govt.fullNameForSearching ?? '',
			'routerLink',
			[`/explore/government`, govt.mcag]
			// Populate fields & additional search text in forkJoin
		))
	));

	// Build government type search items
	governmentTypes = this.fitApiService.governmentTypesForList.pipe(map(types => types.map(type =>
		new SearchItem(
			'Government Type',
			type,
			type.code,
			type.descriptionPlural,
			'routerLink',
			[`/explore/government-type`, type.code],
			// Populate fields & additional search text in forkJoin
		))
	));

	constructor(
		private fitApiService: FitApiService,
		private upperCasePipe: UpperCasePipe,
		private currencySuffixPipe: CurrencySuffixPipe,
		private datasetService: DatasetService,
		private dollarsCategoryService: DollarsCategoryService,
		private financialSummaryService: FinancialSummaryService
	) {
		// Concatenate all search items together and annotate with additional metrics or properties (FHI, etc.)
		this.searchItems = forkJoin([
			this.displayYear,
			this.fitApiService.countiesForList,
			this.outlooks,
			this.governments,
			this.governmentTypes,
			this.fsSections,
			this.accounts,
			this.outlooksByGovernment,
			this.sch1Aggegrations
		]).pipe(map(([displayYear, counties, outlooks, govts, types, fsSections, accounts, outlooksByGovt, sch1Agg]) => {
			// Populate additional data fields for local governments
			govts.forEach(item => {
				if (item.detailFields.length === 0) {
					const govt = item.object as LocalGovernment;
					// Add county field
					const county = counties.find(c => govt.countyCodes?.includes(c.countyCode));
					if (govt && county && county.countyCode !== 50 && govt.govTypeCode !== '06') {  // Don't show "county" field for Statewide "county code" & County gov types
						item.detailFields.push( { label: 'County', value: upperCasePipe.transform(county?.countyName ?? ''), alwaysDisplay: false } );
					}
					// Add govt type field
					const type = types.find(t => t.id === govt.govTypeCode);
					if (govt && type) {
						item.detailFields.push( { label: 'Type', value: type.object as GovernmentType, type: 'Government Type', alwaysDisplay: true } );
					}
					// Get FHI Outlook
					const outlookSummary = outlooksByGovt.find(o => o.mcag === govt.mcag);
					if (outlookSummary) {
						const outlookIndicator = FinancialHealthService.aggregateOutlook(outlookSummary.indicatorOutlooks);
						item.detailFields.push( { label: 'Outlook', value:  outlookIndicator, type: 'Outlook Indicator', alwaysDisplay: false } );
						item.setAdditionalStringForSearch(outlookIndicator.outlook ?? '');
					}
				}
			})

			// Populate additional data fields & search strings for government types
			types.forEach(item => {
				if (item.detailFields.length === 0) {
					const type = item.object as GovernmentType;
					if (type) {
						item.detailFields.push({ label: 'Governments', value: type.activeCount, alwaysDisplay: true });
						item.setAdditionalStringForSearch(type.description);

						// Get FHI Outlooks for this gov Type
						const outlookSummaries = outlooksByGovt.filter(govt => govt.govTypeCode === type.code).map(govt =>
							FinancialHealthService.aggregateOutlook(govt.indicatorOutlooks)
						);
						item.detailFields.push({ label: '', value: outlookSummaries, type: 'Outlook Indicators', alwaysDisplay: false });
					}
				}
			});

			// Populate additional data fields & search text for financial health outlooks
			outlooks.forEach(item => {
				if (item.detailFields.length === 0) {
					const outlookIndicator = item.object as OutlookIndicator;

					// Get # of governments that match this outlook search item (Good, Cautionary, Concerning, etc.)
					const outlookSummaries = outlooksByGovt.map(govt =>
						FinancialHealthService.aggregateOutlook(govt.indicatorOutlooks)
					).filter(govt => govt.outlook === outlookIndicator.outlook);
					item.detailFields.push({ label: 'Governments', value: outlookSummaries.length, alwaysDisplay: true });

					item.setAdditionalStringForSearch('Financial Health Indicator FHI Outlook');
				}
			});

			// Populate FSSection dollars category with additional data fields & search text
			fsSections.forEach(item => {
				if (item.detailFields.length === 0) {
					const fsSection = item.object as FinancialSummarySection;
					if (displayYear) {
						const total = sch1Agg.filter(s1 => s1.fsSectionId === fsSection.id)
							.reduce((sum, s1) => { return sum + s1.totalAmountExclIntlSrvc }, 0);
						item.detailFields.push( { label: `FY ${displayYear}`, value: this.currencySuffixPipe.transform(total, 1), alwaysDisplay: true } );
					}
				}
			});

			// Populate BARS segments' dollars category with additional data fields & search text
			accounts.forEach(item => {
				if (item.detailFields.length === 0) {
					const account = item.object as AccountDescriptor;

					// Lookup FS Section name for BARS Account
					const fsSection = fsSections.map(i => i.object as FinancialSummarySection).find(fs => fs.id === account.fsSectionId);
					if (fsSection) {
						item.detailFields.push( { label: 'Category', value: fsSection.name, alwaysDisplay: true });
					}

					// Add total dollars for the current display year
					if (displayYear && account.acctSgmt) {
						const fieldName = lowerCaseFirst(account.acctSgmt + 'Id');
						const total = sch1Agg.filter(s1 => hasKey(s1, fieldName) && s1[fieldName] === account.id)
							.reduce((sum, s1) => { return sum + s1.totalAmountExclIntlSrvc }, 0);
						item.detailFields.push( { label: `FY ${displayYear}`, value: this.currencySuffixPipe.transform(total, 1), alwaysDisplay: true } );
					}
				}
			});

			return outlooks.concat(govts).concat(types).concat(fsSections).concat(accounts);
		}));
	}


}
