import {HttpInterceptorFn} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {startsWithAny} from '../functions/starts-with-any';

/**
 * Detect relative requests and prepend the API path.
 * @param req
 * @param next
 * @constructor
 */
export const ApiInterceptor: HttpInterceptorFn = (req, next) => {

	const isAbsoluteUrl = startsWithAny(['http://', 'https://']);
	// Complete URLs pass through untouched (external APIs, etc)
	//  e.g. 'https://portal.sao.wa.gov/ExternalCommonservice/api'
	if (isAbsoluteUrl(req.url)) {
		return next(req);
	}

	let url: string;
	if (req.url.startsWith('/')) {
		// app-relative
		// e.g. '/extracts' => 'https://portal.sao.wa.gov/FIT/extracts'
		url = `${environment.base}${req.url}`;
	} else {
		// path-relative
		// e.g. 'snapshots(17)' => 'https://portal.sao.wa.gov/FIT/api/snapshots(17)'
		url = `${environment.base}/api/${req.url}`;
	}
	return next(
		req.clone({ url })
	);
};
