import {OutlookIndicator} from './outlook-indicator';
import {MCAG} from '../services/api/fit-api/models/mcag';
import {GovernmentType} from '../services/api/fit-api/models/government-type';
import {Team} from '../services/api/fit-api/models/team';
import {Outlook} from '../services/api/fit-api/models/indicators/outlook-info';
import {LinkType} from './types/link-type';
import {GovTypeCode} from '../services/api/fit-api/models/gov-type-code';
import {LocalGovernment} from '../services/api/fit-api/models/local-government';
import {AccountDescriptor} from '../services/api/fit-api/models/snapshots/account-descriptor';
import {FinancialSummarySection} from '../services/api/fit-api/models/snapshots/financial-summary-section';
import {OutlookConfig} from '../services/financial-health.service';

// todo - maybe add a SearchItemType map to default Nav Routes or download links

/**
 * Supported search item types - allows for dynamic template display, etc.
 * government - used in omni-search & some extracts
 * government-type - used in omni-search & some extracts
 * team - used in some extracts
 * financial-health-profile - used in the omni-search
 * ...
 */
export type SearchItemType = 'Government' | 'Government Type' | 'Team' | 'Financial Health' | 'Dollars Category' | null;

/**
 * Unique id for the type of Search Item.  Items like financial health will have a few pages that are
 * set up to go to a page with a filter set, so might have Ids like 'Good', 'Cautionary', 'Indeterminate', or 'all'
 *
 * More TBD
 */
export type SearchItemId = MCAG | GovTypeCode | Outlook | 'all' | string | number;

export type SearchItemObject = OutlookConfig | LocalGovernment | GovernmentType | AccountDescriptor | FinancialSummarySection | undefined;

/**
 * Search item title or headline to use when showing the search item in the filtered auto-suggest result list.
 * The text returned will be included in any search "contains" logic.
 */
export type SearchItemTitle = string | OutlookIndicator;

export type SearchItemFieldValue = string | number | OutlookIndicator | Array<OutlookIndicator> | MCAG | GovernmentType;

/**
 * This model describes the search item that is used in the omni-search, extract search/dropdowns, etc.
 */
export class SearchItem {
	public searchString = '';
	constructor(
		public type: SearchItemType,
		public object: SearchItemObject,
		public id: SearchItemId,
		public title: SearchItemTitle,
		public linkType: LinkType,  // Used if the selection is used to navigate
		public link: string | Array<any>,
		public detailFields: Array<{
			label: string,
			value: SearchItemFieldValue,
			alwaysDisplay: boolean,
			type?: 'Government Type' | 'Outlook Indicator' | 'Outlook Indicators'
		}> = [],
		additionalStringForSearch = '', // In addition to the default strings id & title
		public pinned = false  //  If true, this search item will always show up in the results, regardless of filter (i.e., "All Governments")
) {
		this.setAdditionalStringForSearch(additionalStringForSearch);
	}

	setAdditionalStringForSearch = (additionalStringForSearch: string) => {
		// Always include id, title, & type in the search string
		this.searchString = `${this.id} ${this.title} ${additionalStringForSearch}`;
	}
}
