import {CanActivateFn, createUrlTreeFromSnapshot} from '@angular/router';
import {DatasetType} from '../api/fit-api/models/datasets/dataset-type';
import {inject} from '@angular/core';
import {DatasetService} from '../api/fit-api/dataset.service';
import {catchError, map} from 'rxjs/operators';
import {forkJoin, Observable, of, switchMap} from 'rxjs';
import {GovTypeCode} from '../api/fit-api/models/gov-type-code';
import {MCAG} from '../api/fit-api/models/mcag';
import {ExtractTypeId} from '../../pages/data-extracts/extract-type';
import {FitApiService} from '../api/fit-api/fit-api.service';
import {DataExtractsService} from '../data-extracts.service';
import {DatasetSource} from '../api/fit-api/models/datasets/dataset-source';

export const dataExtractCanActivateDatasetGuard: CanActivateFn = (route, state) => {
	// 1. Check if datasetType provided. If no, return true
	const datasetType = route.paramMap.get('datasetType') as DatasetType;
	if (!datasetType) {
		return true;
	}
	// Otherwise get the Dataset Observable requested and continue to step 2
	const datasetId = route.paramMap.get('datasetId') ? Number(route.paramMap.get('datasetId')) : undefined;
	const datasetService = inject(DatasetService);
	const routeDataset = datasetService.getDataset(datasetType, datasetId)
		// return null for any errors that occur in order to rewrite url below
		.pipe(catchError(() => of(null)));

	// 2. Check if extractTypeId provided
	const extractTypeId = route.paramMap.get('extractTypeId') as ExtractTypeId;
	if (!extractTypeId) {
		throw new Error(`Must provide an ExtractTypeId to access a Dataset.`);
	}

	// 3. Next, find inferred dataset based on extractTypeId input
	const dataExtracts = inject(DataExtractsService);
	// for some reason you can't pipe map after the conditional? rxjs loses the type
	// Get the DatasetSource for the Government context (MCAG or GovTypeCode)

	let inferredDatasetSource: Observable<DatasetSource>;
	if (extractTypeId) {
		inferredDatasetSource = dataExtracts.getDatasetSource(extractTypeId);
	} else {
		inferredDatasetSource = of(DatasetSource.None);
	}

	// Inferred default Dataset for the inferredDatasetSource
	const inferredDataset = inferredDatasetSource
		.pipe(switchMap(datasetSource => datasetService.getDataset(datasetSource)));

	return forkJoin([routeDataset, inferredDataset]).pipe(map(([routeBased, inferred]) => {
		// If the datasets do not agree, redirect to the originator without optional parameters for default resolution.
		// Also note the optional chain operator on routeDatasource since Datasets you do not have access to do not get
		//  returned from the API. (i.e., Live, Schools.) This obviates the need to check a UserService.
		if (routeBased?.source !== inferred?.source) {
			// todo this should specifically remove datasetType and datasetId instead of just killing all optional params
			return createUrlTreeFromSnapshot(route, ['/', ...route.url.map(x => x.path)]);
		}

		// fallback to true
		return true;
	}));
};
