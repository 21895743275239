import {DatasetSource} from './datasets/dataset-source';
import {GovTypeCode} from './gov-type-code';
import {MCAG} from './mcag';
import {GovernmentId} from './government-id';

export class LocalGovernment {

	public mcag!: MCAG;
	public status!: string;
	public countyCodes!: Array<number>;
	public govTypeCode!: GovTypeCode;
	public govTypeDesc!: string;
	public govTypeDescPlural!: string;
	public canHaveIndicators!: boolean;
	public financialsDatasetSource!: DatasetSource;
	public responsibleTeam!: string | null;
	public teamName!: string | null;
	public rcw!: string | null;
	public fiscalYearEnd!: string | null;
	public acctBasisTypeId!: number | null;
	public acctBasisTypeDesc!: string | null;
	public entityDescription!: string | null;
	public website!: string | null;
	public isLocalGovernment!: boolean;
	public buildingAndSuite!: string | null;
	public poBox!: string | null;
	public streetAddress!: string | null;
	public city!: string | null;
	public state!: string | null;
	public zip!: string | null;
	public longitude!: number | null;
	public latitude!: number | null;

	public terminationDate!: Date | null;
	public establishedDate!: Date | null;
	public dateInactive!: Date | null;

	public governingBodyTypesId!: number | null;
	public governingBodyTypesDesc!: string | null;
	public governingBodySize!: number | null;
	public governingBodyOriginsId!: number | null;
	public governingBodyOriginsDesc!: string | null;
	public treasurerMCAG!: string | null;
	public componentUnitOfMCAG!: string | null;


	public governmentId: GovernmentId;


	// Government Id
	/**
	 * Legal Name - Legal Name of Government
	 */
	public legalName!: string;

	/**
	 * Common government name - Uses DBA (or LegalName if no DBA)
	 *  Examples - Sound Transit, City of Seattle, etc.
	 */
	public commonName!: string;

	/**
	 * Common lookup government name - Uses DBA (or LookupName if no DBA)
	 * Useful when you need to list governments by name alphabetically.
	 *  Examples - Sound Transit, "Seattle, City of", etc.
	 */
	public sortableCommonName!: string;

	/**
	 * Combined government name - Uses DBA & LegalName (or just LegalName if no DBA)
	 *  Useful if you are searching the list of governments and need to search across both names.
	 *  Examples - Sound Transit (Central Puget Sound Regional Transit Authority), City of Seattle, etc.
	 */
	public fullNameForSearching!: string;

	/**
	 * Combined government name - Uses DBA & LegalName (or just LookupName if no DBA)
	 *  Useful when you want to list the governments alphabetically & search by name across both names.
	 *  Examples - Sound Transit (Central Puget Sound Regional Transit Authority), City of Seattle, etc.
	 */
	public sortableFullNameForSearching!: string;

	constructor(data?: Partial<LocalGovernment>) {
		Object.assign(this, data);

		this.governmentId = new GovernmentId(this.mcag);
	}

}
