import {ActivatedRouteSnapshot, ResolveFn, Router} from '@angular/router';
import {GovernmentType} from '../api/fit-api/models/government-type';
import {inject} from '@angular/core';
import {GovTypeCode} from '../api/fit-api/models/gov-type-code';
import {FitApiService} from '../api/fit-api/fit-api.service';
import {catchError} from 'rxjs/operators';
import {EMPTY} from 'rxjs';

export const governmentTypeResolver: ResolveFn<GovernmentType> = (route, state) => {
	const router = inject(Router);

	return resolveGovernmentType(route).pipe(
		catchError(err => {
			// console.debug(`caught error`, err); // this actually throws an error lol
			// todo need to think about this more to figure out how we want to handle not found scenarios
			router.navigateByUrl('/not-found');
			return EMPTY;
		})
	);
};

export const resolveGovernmentType = (route: ActivatedRouteSnapshot) => {
	const govTypeCode =
		route.paramMap.get('govTypeCode') as GovTypeCode;

	if (govTypeCode == null || govTypeCode.length !== 2) {
		throw new Error(`A valid govTypeCode must be provided to use this resolver. Offered: ${govTypeCode}`);
	}

	return inject(FitApiService).getGovernmentType(govTypeCode);
};
