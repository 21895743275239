import {FundCategoryId} from './fund-category';
import {ReferenceLookup} from '../snapshots/reference-lookup';

export enum FundTypeId {
	general = 0,
	specialRevenue = 1,
	debtService = 2,
	capitalProjects = 3,
	enterprise = 4,
	internalService = 5,
	permanent = 7
}

export interface FundType extends ReferenceLookup<number> {
	categoryId: FundCategoryId;
	id: FundTypeId;
	name: string;
	sortOrder: number;
}
