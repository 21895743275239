/***
 * Used to sort arrays of objects by a string or numeric property
 * @param getProperty
 * @param ascending defaults to true
 */
export const sortByProperty = <T>(getProperty: (object: T) => string | number, ascending = true) =>
	(objA: T, objB: T) => {
		const a = getProperty(objA);
		const b = getProperty(objB);

		let comparison: number;
		// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
		if (typeof a === 'string' || typeof b === 'string') {
			comparison = a.toString().localeCompare(b.toString());
		} else {
			comparison = a > b ? 1 : a < b ? -1 : 0;
		}
		return ascending ? comparison : -1 * comparison;
	};
