<!--
Working from the pattern described here:
	https://24ways.org/2019/making-a-better-custom-select-element/
Also, good info here about how to handle click and blur events together (2nd most popular answer):
	https://stackoverflow.com/questions/39439115/how-to-execute-click-function-before-the-blur-function
-->
<ng-container *ngIf="!isDownloading">
	<div class="combobox-outer"
		 [ngClass]="{'mobile-search-enabled': compactMode && mobileSearchEnabled, 'mobile-search-disabled': compactMode && !mobileSearchEnabled}"
	>
		<div #comboboxInput class="combobox-inner">
			<app-search
				class="combobox-input"
				[ngClass]="{'downloading': isDownloading, 'visible': !compactMode || mobileSearchEnabled}"
				[label]="label"
				[placeholder]="placeholder"
				(focusin)="onFocusIn($event)"
				(focusout)="onFocusOut($event)"
				[(value)]="searchText"
			></app-search>
			<button *ngIf="compactMode" class="mobile-search-button" (click)="toggleSearchInput()">
				<svg-icon [key]="mobileSearchEnabled ? 'close' : 'search'"></svg-icon>
			</button>
		</div>
	</div>

	<div #searchResults class="search-results"
		 [ngClass]="{'visible': showResults}"
		 (focusout)="onFocusOut($event)"
	>
		<overlay-scrollbars [options]="overlayScrollDarkVertical">
			<!--Leave tabindex at -1, important for key focus pattern (keyboard navigation)-->
			<app-omni-search-combo-box-item
											*ngFor="let item of displayItems; let last = last; let first = first"
											[first]="first"
											[last]="last"
											[item]="item"
											class="search-item"
											(click)="selectItem(item)"
											(keydown.enter)="selectItem(item)"
											tabindex="-1"
			>
			</app-omni-search-combo-box-item>
		</overlay-scrollbars>
		<div *ngIf="nextPageCount > 0">
			<div class="divider">
				<hr>
			</div>
			<div #nextPage
				class="next-page"
				(click)="addNextPage()"
				(keydown.enter)="addNextPage()"
				 (focus)="onNextPageFocus()"
				(focusout)="onFocusOut($event)"
				 tabindex="0"
				>
				<svg-icon key="reload"></svg-icon>
				<p class="small-text">Load {{nextPageCount}} more results</p>
			</div>
		</div>

	</div>
</ng-container>
<ng-container *ngIf="isDownloading">
	<app-loading-indicator
		[ngClass]="{'show': isDownloading}"
		class="download-indicator"
		[loadingText]="downloadStatusMessage | titlecase"
	></app-loading-indicator>
</ng-container>







