import {Injectable} from '@angular/core';
import {Outlook, OutlookInfo} from './api/fit-api/models/indicators/outlook-info';
import {countBy} from './functions/count-by';
import {OutlookIndicator} from '../models/outlook-indicator';
import {SvgIcons} from '@ngneat/svg-icon';
import {DatasetDetail, DatasetType} from './api/fit-api/models/datasets/dataset-type';
import {GovernmentId} from './api/fit-api/models/government-id';
import {Observable} from 'rxjs';
import {IndicatorReport} from './api/fit-api/models/indicators/indicator-report';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {FitApiService} from './api/fit-api/fit-api.service';
import {IndicatorGrouping} from '../models/indicator-grouping';
import {TrendAmount} from '../models/trend-amount';
import {Indicator} from '../models/indicator';
import {Palette} from '../models/palette';
import {GovernmentSpecificity} from './api/fit-api/models/government-specificity';

@Injectable({
	providedIn: 'root'
})
export default class FinancialHealthService {

	public static supportedDatasetTypes = [
		DatasetType.Snapshot, // Default dataset type should be first in the array
	] as const;

	public static OUTLOOKS: Array<OutlookConfig> = [
		{outlook: 'Good', sortOrder: 1, icon: 'done', color: 'green'},
		{outlook: 'Cautionary', sortOrder: 2, icon: 'warning', color: 'orange'},
		{outlook: 'Concerning', sortOrder: 3, icon: 'alert', color: 'red'},
		{outlook: 'Indeterminate', sortOrder: 4, icon: 'no-data', color: 'grey'}
	];

	readonly defaultOutlookIndicator = new OutlookIndicator('Indeterminate', 'Not enough information available to make a determination');

	// todo - remove these temporary sample values used on style guide and prototyping for each explore option
	outlookGood = new OutlookIndicator('Good');
	outlookCautionary = new OutlookIndicator('Cautionary');
	outlookConcerning = new OutlookIndicator('Concerning');
	outlookIndeterminate = new OutlookIndicator('Indeterminate');
	// Example of a group of FHI outlooks
	outlooks: Array<OutlookIndicator> = [
		this.outlookCautionary,
		this.outlookGood,
		this.outlookGood,
		this.outlookConcerning,
		this.outlookIndeterminate,
		this.outlookGood,
		this.outlookCautionary,
		this.outlookCautionary,
		this.outlookCautionary,
		this.outlookConcerning,
	];

	constructor(
		private http: HttpClient,
		private apiService: FitApiService
	) {
	}

	public getOutlookCounts = (outlooks?: Array<OutlookIndicator | undefined>) => {
		if (outlooks) {
			const counts = countBy(outlooks, o => o?.outlook);
			return Object.entries(counts).map(([outlook, count]) => new OutlookIndicator(outlook as Outlook, null, count as number))
				.sort((a, b) => {
					const aConfig = FinancialHealthService.OUTLOOKS.find(o => o.outlook === a.outlook);
					const bConfig = FinancialHealthService.OUTLOOKS.find(o => o.outlook === b.outlook);
					return (aConfig?.sortOrder ?? 100) - (bConfig?.sortOrder ?? 100);
				});
		} else {
			return undefined;
		}
	}

	/**
	 * Return view model (Indicator Reports) for gov and gov type profile financial health section
	 * @param datasetDetail
	 * @param governmentId
	 * @param displayYear
	 * @param numOfTrendYears
	 */
	getIndicatorGroupsForGovernmentId(
		datasetDetail: DatasetDetail,
		governmentId: GovernmentId,
		displayYear: number,
		numOfTrendYears: number
	): Observable<Array<IndicatorGrouping>> {
		const filter = `year gt ${displayYear - numOfTrendYears} and Year le ${displayYear} and ${governmentId.parameterName} eq '${governmentId}'`;
		return this.apiService.getIndicatorReports(datasetDetail, filter).pipe(map(indicatorReports => {
			const indicatorGroups = indicatorReports.reduce((groups: Array<IndicatorGrouping>, indicatorRow: IndicatorReport) => {
				if (governmentId.specificity === GovernmentSpecificity.Government && indicatorRow.measure === null) {
					return groups;
				}

				// For government type profile, we want to group all enterprise fund rows together
				const currentGroupName = governmentId.specificity === GovernmentSpecificity.GovernmentType &&
					indicatorRow.report === 'EnterpriseFundIndicators' ? 'Enterprise Funds' : indicatorRow.group;

				// reduce reports into groups
				let indicatorGroup = groups.find((indicatorGroup: IndicatorGrouping) => indicatorGroup.group === currentGroupName);
				// if group doesn't exist create it
				if (!indicatorGroup) {
					indicatorGroup = new IndicatorGrouping(currentGroupName, governmentId, [])

					groups.push(indicatorGroup)
				}

				// We are creating one indicator per government if this is for the government type profile, so include MCAG in lookup
				let indicator = indicatorGroup.indicators?.find((indicator: Indicator) =>
					indicator.mcag === indicatorRow.mcag && indicator.instanceCode === indicatorRow.instanceCode
				);
				// if the indicator doesn't exist create it
				if (!indicator) {

					// set indicator info meaning to each indicator (lookup by code)
					const indicatorName = datasetDetail.detail.indicatorNames.find(indicatorName =>
						indicatorName.code === indicatorRow.code);

					const infoMeaning = indicatorName?.indicatorInfo.meaning

					indicator = new Indicator(indicatorRow.mcag, indicatorRow.code, indicatorRow.instanceCode, indicatorRow.title, indicatorRow.measureBenchmark, indicatorRow.measureUnitInfo, [], displayYear, infoMeaning, this.defaultOutlookIndicator);
					indicatorGroup.indicators?.push(indicator);
				}

				indicator.trendAmounts.push(new TrendAmount(indicatorRow.year, indicatorRow.measure));

				// if the current indicator report year equals the display year then set additional indicator properties
				if (indicatorRow.year === displayYear) {
					indicator.outlookIndicatorForDisplayYear = new OutlookIndicator(indicatorRow.outlookInfo.outlook, indicatorRow.outlookInfo.annotation);
					indicator.measureForDisplayYear = indicatorRow.measure;
				}

				return groups;
			}, [])

			// Determine group outlook (used for government profile view)
			indicatorGroups.forEach((indicatorGroup: IndicatorGrouping) => {
				const inputOutlooks = indicatorGroup.indicators?.map((indicator: Indicator) => {
					return indicator.outlookIndicatorForDisplayYear;
				})
				indicatorGroup.outlookIndicator = FinancialHealthService.aggregateOutlook(inputOutlooks);
			})

			// Filter out any groups that don't have measures for displayYear
			return indicatorGroups.filter((group: IndicatorGrouping) => group.indicators.find(i => i.measureForDisplayYear != null));
		}));
	}

	// Take an array of indicators (with a mix of governments) and generate an outlook for each government
	static aggregateGovernmentOutlooks = (indicators: Array<Indicator>) => {
		return indicators?.reduce((govts, indicator) => {
			let govt = govts.find(g => g.mcag === indicator.mcag);
			if (!govt) {
				govt = { mcag: indicator.mcag, indicatorOutlooks: [] };
				govts.push(govt);
			}
			govt.indicatorOutlooks.push(indicator.outlookIndicatorForDisplayYear);
			return govts;
		}, [] as Array<{ mcag: string, indicatorOutlooks: Array<OutlookIndicator> }>)
			.map(g => FinancialHealthService.aggregateOutlook(g.indicatorOutlooks));
	}

	/**
	 * Generic method to return outlook indicator based on array of outlooks
	 * @param outlooks
	 */
	static aggregateOutlook = (outlooks?: Array<OutlookInfo | undefined>): OutlookIndicator => {
		if (outlooks?.some((outlookInfo?: OutlookInfo) => outlookInfo?.outlook === 'Concerning')) {
			return new OutlookIndicator('Concerning', 'One or more indicators have an outlook that is concerning.');
		} else if (outlooks?.some((outlookInfo?: OutlookInfo) => outlookInfo?.outlook === 'Cautionary')) {
			return new OutlookIndicator('Cautionary', 'One or more indicators have an outlook that is cautionary.');
		} else if (outlooks?.some((outlookInfo?: OutlookInfo) => outlookInfo?.outlook === 'Good')) {
			return new OutlookIndicator('Good', 'All indicators have a good outlook or are indeterminate.');
		} else {
			return new OutlookIndicator('Indeterminate', 'Not enough information available to make a determination.');
		}
	}

}

export interface OutlookConfig {
	outlook: Outlook;
	sortOrder: number;
	icon: SvgIcons;
	color: Palette;
}
