import {Injectable} from '@angular/core'
import {
	HttpClient,
	HttpEvent,
	HttpEventType,
	HttpHeaderResponse,
	HttpProgressEvent,
	HttpResponse
} from '@angular/common/http'
import {distinctUntilChanged, Observable, scan} from 'rxjs'
import {saveAs} from 'file-saver-es';
import {parse} from 'content-disposition';

// Inspired by https://stackblitz.com/edit/angular-file-download-progress
//
// TODO replace with native tracking and remove file-saver-es, content-disposition, and remove content-disposition from
// angular.json allowedCommonJsDependencies
// https://angular.io/guide/http-track-show-request-progress
@Injectable({providedIn: 'root'})
export class FileService {
	constructor(
		private http: HttpClient
	) {
	}

	download(url: string, filename?: string): Observable<Download> {
		return this.http.get(url, {
			reportProgress: true,
			observe: 'events',
			responseType: 'blob'
		}).pipe(
			downloadProgressWithSaveAsSideEffect((blob, headerFilename) =>
				saveAs(blob, headerFilename || filename)
			)
		);
	}
}

export interface Download {
	content: Blob | null;
	progress: number;
	state: 'pending' | 'inProgress' | 'done' | 'error';
	status?: number;
	statusText?: string;
}

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
	return event.type === HttpEventType.Response;
}
function isHttpHeaderResponse(event: HttpEvent<unknown>): event is HttpHeaderResponse {
	return event.type === HttpEventType.ResponseHeader;
}


function isHttpProgressEvent(
	event: HttpEvent<unknown>
): event is HttpProgressEvent {
	return (
		event.type === HttpEventType.DownloadProgress ||
		event.type === HttpEventType.UploadProgress
	);
}

// Handle HTTPEvents for file downloads
// Can use the pending download emitted values to display download progress
// Notes from Micah: This maps an Observable stream of Download, interrupting the original stream, and then triggers
// a file-save side-effect out-of-band.
//todo - use this pattern https://angular.io/guide/http-track-show-request-progress
export function downloadProgressWithSaveAsSideEffect(
	saver?: typeof saveAs
): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
	return (source: Observable<HttpEvent<Blob>>) =>
		source.pipe(
			scan(
				(download: Download, event): Download => {
					if (isHttpProgressEvent(event)) {
						return {
							progress: event.total
								? Math.round(event.loaded / event.total)
								: download.progress,
							state: 'inProgress',
							content: null
						};
					}
					if (isHttpResponse(event)) {
						if (saver && event.body) {
							saver(event.body, getHeaderFilename(event));
						}
						return {
							progress: 100,
							state: 'done',
							content: event.body
						};
					}
					if (isHttpHeaderResponse(event) && !event.ok) {
						return {
							progress: 0,
							state: 'error',
							content: null,
							status: event.status,
							statusText: event.statusText
						};
					}
					return download;
				},
				{state: 'pending', progress: 0, content: null}
			),
			distinctUntilChanged((a, b) =>
				a.state === b.state
				&& a.progress === b.progress
				&& a.content === b.content
			)
		);
	}

/**
 * Parses the Content-Disposition response header for a filename associated with the download
 * @param event HttpResponse event that contains response header information
 */
export function getHeaderFilename(event: HttpResponse<Blob>): string | undefined {
	const contentDispositionText = event.headers.get('Content-Disposition');
	if (!contentDispositionText) {
		return undefined;
	}
	const contentDisposition = parse(contentDispositionText);
	return contentDisposition?.parameters['filename'];
}
