import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {CommonModule, Location} from '@angular/common';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {SvgIconComponent} from '@ngneat/svg-icon';
import { environment } from '../../../environments/environment';
import {UserService} from '../../services/user.service';
import {User} from '../../models/user';
import {LayoutClassDirective} from '../../services/directives/layout-class.directive';
import {OverlayscrollbarsModule} from 'overlayscrollbars-ngx';
import {overlayScrollLightVertical} from '../overlay-scroll-options';
import {AppLayoutService} from '../../services/app-layout.service';
import {DialogComponent} from '../../components/modal-dialog/dialog.component';

@Component({
	selector: 'app-navigation',
	standalone: true,
	imports: [CommonModule, RouterLink, RouterLinkActive, SvgIconComponent, OverlayscrollbarsModule, DialogComponent],
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
	hostDirectives: [LayoutClassDirective]
})
export class NavigationComponent {
	@ViewChild('logoutDialog') logoutDialog?: DialogComponent;
	isDevelopment = environment.development;

	@Output()
	closeMenu = new EventEmitter<void>();

	user?: User;

	currentLocation?: string;
	login = () => {
		this.userService.login(this.currentLocation);
		this.close();
	}

	logout = () => this.userService.logout();

	constructor(
		public appLayoutService: AppLayoutService,
		private location: Location,
		private userService: UserService
	) {
		this.userService.user.subscribe(x => this.user = x);
		this.location.onUrlChange(url => this.currentLocation = url);
	}

	close = (): void => {
		this.closeMenu.next();
	}
	protected readonly overlayScrollLightVertical = overlayScrollLightVertical;
}
