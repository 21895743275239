/**
 * Returns a string like '2018-2021' given an array of numbers (years)
 * @param years
 */

export const yearsToRangeText = (years: Array<number | null>) => {
	let result = '';

	let filteredYears: Array<number> = years.reduce((acc,y) => {
		if (y !== null) {
			acc.push(y);
		}
		return acc;
	}, [] as Array<number>)
		.sort((a,b) => a - b);

	if (!Array.isArray(filteredYears) || !filteredYears[0]) {
		return result;
	}

	if (filteredYears.length > 2) {
		filteredYears = [Math.min(...filteredYears), Math.max(...filteredYears)];
	}

	if (filteredYears[1] === null || filteredYears[0] === filteredYears[1]) {
		result += filteredYears[0];
	} else {
		result += filteredYears[0] + '-' + filteredYears[1];
	}

	return result;
}
