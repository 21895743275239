export type ExtractTypeId = 'full' | 'omwbe' | 'census-debt-balances' | 'census-revenues-expenditures' |
	'debt-service-coverage'	| 'operating-results' | 'financial-health-indicators' |	'pension-liability';
export class ExtractType {

	routeLink: string;
	constructor(
		public id: ExtractTypeId,
		public title: string,
		public subtitle: string,
		public description?: string,
		public isSnapshotBased = false,
		// to use on the button name
		public linkSuffix = 'Data',
		public isYearsBased = true,
		public restrictedToRole?: 'globalAccess',
		public isTeamOrGovernmentFilterable = false,
		public filterableBaseUrl?: string
	) {
		this.routeLink = '/data-extracts/' + this.id;
	}

}
