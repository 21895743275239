import {Injectable} from '@angular/core';
import {Observable, of, switchMap,} from 'rxjs';
import {map} from 'rxjs/operators';
import {LiveWithDetail} from './models/datasets/live';
import {SnapshotWithDetail} from './models/datasets/snapshot';
import {GovernmentId} from './models/government-id';
import {ODataCollectionResult} from '../odata-collection-result';
import {Schedule9} from './models/schedule9';
import {HttpClient} from '@angular/common/http';
import {SchoolsWithDetail} from './models/datasets/schools';
import {OSPIFinancialReportRow} from './models/schools/o-s-p-i-financial-report-row';
import {OSPILongTermLiabilityRow} from './models/schools/long-term-liability-row';
import {FsSectionFilerCount} from './models/fs-section-filer-count';
import {GovernmentSpecificity} from './models/government-specificity';
import {Share} from '../share';
import {FundType} from './models/funds/fund-type';
import {Fund} from './models/funds/fund';
import {DatasetService} from './dataset.service';
import {LoggerService} from '../../logger.service';
import {ALLFUNDS, AllFundsDataRow} from './models/all-funds';
import {Schedule1AggregationByGovt} from './models/schedule1-aggregation-by-govt';
import {Schedule1AggregationByGovType} from './models/schedule1-aggregation-by-gov-type';
import {FinancialReportAggregationByGovType} from './models/schools/financial-report-aggregation-by-gov-type';
import {FinancialReportAggregationByGovt} from './models/schools/financial-report-aggregation-by-govt';


// todo is this FiscalYearService? may be some methods that should be extracted from FilingStatusService
@Injectable({
	providedIn: 'root'
})

/**
 * This service contains methods that interface with all the FitApi financial endpoints.
 * There is no business logic per se in this service, just the mechanics of interfacing with the API layer.
 *
 */
export class FinancialService {
	static NUM_TREND_YEARS = 4; // todo - this is business logic (4 years by default) and probably should be moved elsewhere

	constructor(
		private datasetService: DatasetService,
		private http: HttpClient,
		private logger: LoggerService
	) {
	}

	/** SAO Annual Filing */

	// Revenues & Expenditures

	/**
	 * Return Schedule 1 Aggregations By Government
	 * @param dataset
	 * @param filter
	 */
	@Share()
	getSchedule1AggregationsByGovt(
		dataset: LiveWithDetail | SnapshotWithDetail,
		filter?: string
	): Observable<Array<Schedule1AggregationByGovt>> {
		// log query string
		// this.logger.log('filter string: ', filter)
		return this.http.get<ODataCollectionResult<Schedule1AggregationByGovt>>(
			`${dataset.route}/schedule1AggregationsByGovt?$filter=${filter}`
		).pipe(map(x => x.value));
	}

	/**
	 * Return Schedule 1 Aggregations By Government Type
	 * @param dataset
	 * @param filter
	 */
	@Share()
	getSchedule1AggregationsByGovType(
		dataset: LiveWithDetail | SnapshotWithDetail,
		filter?: string
	): Observable<Array<Schedule1AggregationByGovType>> {
		// log query string
		// this.logger.log('filter string: ', filter)
		return this.http.get<ODataCollectionResult<Schedule1AggregationByGovType>>(
			`${dataset.route}/schedule1AggregationsByGovType?$filter=${filter}`
		).pipe(map(x => x.value));
	}

	// Debt & Liabilities

	/**
	 * Return Schedule 9 totals
	 * @param dataset
	 * @param filter
	 */
	@Share()
	getSchedule9Totals(
		dataset: LiveWithDetail | SnapshotWithDetail,
		filter?: string
	): Observable<Array<Schedule9>> {
		return this.http.get<ODataCollectionResult<Schedule9>>(`${dataset.route}/Schedule9s?$filter=${filter}`)
			.pipe(map(x => x.value));
	}

	/** OSPI */

	// Revenues and Expenditures
	/**
	 * Return OSPI Aggregations By Government
	 * @param dataset
	 * @param filter
	 */
	@Share()
	getOSPIAggregationsByGovt(
		dataset: SchoolsWithDetail,
		filter?: string
	): Observable<Array<FinancialReportAggregationByGovt>> {
		// log query string
		// this.logger.log('filter string: ', filter)
		return this.http.get<ODataCollectionResult<FinancialReportAggregationByGovt>>(
			`${dataset.route}/FinancialReportAggregationsByGovt?$filter=${filter}`
		).pipe(map(x => x.value));
	}

	/**
	 * Return OSPI Aggregations By Government Type
	 * @param dataset
	 * @param filter
	 */
	@Share()
	getOSPIAggregationsByGovType(
		dataset: SchoolsWithDetail,
		filter?: string
	): Observable<Array<FinancialReportAggregationByGovType>> {
		// log query string
		// this.logger.log('filter string: ', filter)
		return this.http.get<ODataCollectionResult<FinancialReportAggregationByGovType>>(
			`${dataset.route}/FinancialReportAggregationsByGovType?$filter=${filter}`
		).pipe(map(x => x.value));
	}

	/**
	 * Return Financial Reports
	 * @param dataset
	 * @param governmentId
	 * @param filter
	 *
	 * @deprecated Use getOSPIAggregationsByGovt or getOSPIAggregationsByGovType
	 */
	@Share()
	getOSPIFinancialReports(
		dataset: SchoolsWithDetail,
		governmentId: GovernmentId,
		filter?: string
	): Observable<Array<AllFundsDataRow<OSPIFinancialReportRow>>> {
		// either call financial row data by gov or gov type
		// note: does not return an Odata response
		const obs = governmentId.specificity === GovernmentSpecificity.GovernmentType ?
			this.http.get<Array<OSPIFinancialReportRow>>(`${dataset.route}/GetFinancialReports(govTypeCode='${governmentId}')?$filter=${filter}`)
			: this.http.get<Array<OSPIFinancialReportRow>>(`${dataset.route}/GetFinancialReports(mcag='${governmentId}')?$filter=${filter}`)

		// todo - ask Micah if there is a better way to do this?
		return obs.pipe(map(x => x.map(r => {
			return { ...r, ...ALLFUNDS } as AllFundsDataRow<OSPIFinancialReportRow>
		})));
	}

	// Debt & Liabilities
	/**
	 * Return Long Term Liabilities
	 * @param dataset
	 * @param governmentId
	 * @param filter
	 */
	@Share()
	getOSPILongTermLiabilities(
		dataset: SchoolsWithDetail,
		governmentId: GovernmentId,
		filter: string
	): Observable<Array<OSPILongTermLiabilityRow>> {
		// either call financial row data by gov or gov type
		// note: does not return an Odata response
		return governmentId.specificity === GovernmentSpecificity.GovernmentType ?
			this.http.get<Array<OSPILongTermLiabilityRow>>(`Schools/GetLongTermLiabilities(govTypeCode='${governmentId}')?$filter=${filter}`)
			: this.http.get<Array<OSPILongTermLiabilityRow>>(`Schools/GetLongTermLiabilities(mcag='${governmentId}')?$filter=${filter}`);
	}

	// By Dollars selection page
	/**
	 * Return Schedule 1 Aggregations FsSections grouped by number of mcags that filed for each section
	 * @param dataset
	 * @param filter
	 */
	@Share()
	getSchedule1AggregationTotalFilersPerFsSection(dataset: LiveWithDetail | SnapshotWithDetail, filter: string): Observable<Array<FsSectionFilerCount>> {
		return this.http.get<ODataCollectionResult<FsSectionFilerCount>>(`${dataset.route}/schedule1AggregationsByGovt?$apply=filter(${filter})/groupby((fsSectionId),aggregate(mcag with countdistinct as numberOfFilers))`)
			.pipe(map(x => x.value));
	}

	/**
	 * Return OSPI Aggregations FsSections grouped by number of mcags that filed for each section
	 * @param dataset
	 * @param filter
	 */
	@Share()
	getOSPIAggregationTotalFilersPerFsSection(dataset: SchoolsWithDetail, filter: string): Observable<Array<FsSectionFilerCount>> {
		return this.http.get<ODataCollectionResult<FsSectionFilerCount>>(`${dataset.route}/FinancialReportAggregationsByGovt?$apply=filter(${filter})/groupby((fsSectionId),aggregate(mcag with countdistinct as numberOfFilers))`)
			.pipe(map(x => x.value));
	}

	/**
	 * Return OSPI Aggregations  number of mcags that filed
	 * @param dataset
	 * @param filter
	 */
	@Share()
	getOSPIAggregationTotalFilers(dataset: SchoolsWithDetail, filter: string): Observable<number> {
		return this.http.get<ODataCollectionResult<{ numberOfFilers: number }>>(`${dataset.route}/FinancialReportAggregationsByGovt?$apply=filter(${filter})/aggregate(mcag with countdistinct as numberOfFilers)`)
			.pipe(map(x => x.value[0]?.numberOfFilers));
	}

	/**
	 * Return Schedule 1 Aggregation governments with missing filings over year range
	 * @param dataset
	 * @param filter
	 * @param endYear
	 * @param startYear
	 */
	@Share()
	getSchedule1AggregationMissingFilings(dataset: LiveWithDetail | SnapshotWithDetail, filter: string, endYear: number, startYear: number): Observable<Array<Schedule1AggregationByGovt>> {
		return this.http.get<ODataCollectionResult<Schedule1AggregationByGovt>>(`${dataset.route}/Schedule1AggregationsByGovt/WithFilingGaps(filingGapStartYear=${startYear},filingGapEndYear=${endYear})?$filter=${filter}`)
			.pipe(map(x => x.value));
	}


	// Funds
	/**
	 * Return all filed funds based on mcag
	 * @param governmentId
	 * @param endYear
	 * @param startYear
	 */
	@Share()
	getFiledFunds(
		governmentId?: GovernmentId,
		startYear?: number,
		endYear?: number
	): Observable<Array<Fund> | undefined> {
		if (governmentId?.specificity === GovernmentSpecificity.Government) {
			// call getDefaultDatasetForGovernmentId() in order to get correct route
			return this.datasetService.getDefaultDatasetForGovernmentId(governmentId).pipe(
					switchMap(dataset => {
						if (dataset) {
							return this.http.get<ODataCollectionResult<Fund>>(`${dataset.route}/Funds/GetFiledFunds(mcag='${governmentId}',startYear=${startYear},endYear=${endYear})`)
								.pipe(map(result => result?.value.map(fund => new Fund(fund))));
						} else {
							this.logger.warn(`dataset for government id: ${governmentId} could not be resolved. Check user authorization.`);
							// return undefined to signify this call wasn't made
							return of(undefined);
						}
					})
				)
		} else {
			// return undefined to signify this call wasn't made
			return of(undefined);
		}
	}

	/**
	 * Return all filed fund types based on gov type id
	 * @param governmentId
	 * @param endYear
	 * @param startYear
	 */
	@Share()
	getFiledFundTypes(
		governmentId?: GovernmentId,
		startYear?: number,
		endYear?: number
	): Observable<Array<FundType> | undefined> {
		// call getDefaultDatasetForGovernmentId() in order to get correct route
		if (governmentId?.specificity === GovernmentSpecificity.GovernmentType) {
			return this.datasetService.getDefaultDatasetForGovernmentId(governmentId).pipe(
				switchMap(dataset => {
					if (dataset) {
						return this.http.get<ODataCollectionResult<FundType>>(`${dataset.route}/Funds/GetFiledFundTypes(govTypeCode='${governmentId}',startYear=${startYear},endYear=${endYear})`)
							.pipe(map(x => x.value));
					} else {
						this.logger.warn(`dataset for government id: ${governmentId} could not be resolved. Check user authorization.`);
						// return undefined to signify this call wasn't made
						return of(undefined);
					}
				})
			)
		} else {
			// return undefined to signify this call wasn't made
			return of(undefined);
		}
	}

}
