<overlay-scrollbars
	class="app-navigation"
	[options]="overlayScrollLightVertical"
	(osScroll)="appLayoutService.onOSScroll($event)"
>
	<div class="navigation">
		<div class="header">
			<img class="logo-with-text" src="assets/fit-logo-mono-text.svg" alt="Financial Intelligence Tool logo">
			<button class="close-menu" (click)="close()" aria-label="Close the menu">
				<svg-icon key="close"></svg-icon>
			</button>
		</div>

		<nav>
			<ul class="pages">
				<li>
					<a routerLink="/explore" routerLinkActive="active-page" [routerLinkActiveOptions]="{exact: true}" (click)="close()">
						<svg-icon key="explore"></svg-icon>
						Explore
					</a>
				</li>
				<li>
					<a routerLink="/explore/government" routerLinkActive="active-page" (click)="close()">
						<svg-icon key="location"></svg-icon>
						Individual Governments
					</a>
				</li>
				<li>
					<a routerLink="/explore/government-type" routerLinkActive="active-page" (click)="close()">
						<svg-icon key="gov-type"></svg-icon>
						Government Types
					</a>
				</li>
				<li>
					<a routerLink="/explore/dollars" routerLinkActive="active-page" (click)="close()">
						<svg-icon key="finances"></svg-icon>
						Navigate By Dollars
					</a>
				</li>
				<li>
					<a routerLink="/explore/financial-health" routerLinkActive="active-page" (click)="close()">
						<svg-icon key="health"></svg-icon>
						Financial Health
					</a>
				</li>
<!--				<li>-->
<!--					<a routerLink="/explore/filing-statistics" routerLinkActive="active-page" (click)="close()">-->
<!--						<svg-icon key="filing"></svg-icon>-->
<!--						Filing Statistics-->
<!--					</a>-->
<!--				</li>-->
			</ul>

			<hr>

			<!-- actions for mobile in lieu of header actions -->
			<ul class="actions">
<!--				<li>-->
<!--					<a [routerLink]="[]" (click)="close()">-->
<!--						<svg-icon key="settings"></svg-icon>-->
<!--						Settings-->
<!--					</a>-->
<!--				</li>-->
				<li *ngIf="!user?.claim?.contactID">
					<a [routerLink]="[]" (click)="login()">
						<svg-icon key="login"></svg-icon>
						Login
					</a>
				</li>
				<li *ngIf="user?.claim?.contactID">
					<a [routerLink]="[]" (click)="logoutDialog.showModal()">
						<svg-icon key="logout"></svg-icon>
						Logout
					</a>
				</li>
			</ul>

			<hr class="actions">

			<ul class="misc">
				<li *ngIf="user?.hasAdminRole">
					<a routerLink="/fit-admin" routerLinkActive="active-page" (click)="close()">
						<svg-icon key="gear-solid"></svg-icon>
						Admin
					</a>
				</li>
				<li>
					<a routerLink="/data-extracts" routerLinkActive="active-page" (click)="close()">
						<svg-icon key="data-extract"></svg-icon>
						Data Extracts
					</a>
				</li>
<!--				<li>-->
<!--					<a routerLink="/resources" routerLinkActive="active-page" (click)="close()">-->
<!--						<svg-icon key="resources"></svg-icon>-->
<!--						Resources-->
<!--					</a>-->
<!--				</li>-->
				<li>
					<a routerLink="/about" routerLinkActive="active-page" (click)="close()">
						<svg-icon key="info-solid"></svg-icon>
						About
					</a>
				</li>
				<li>
					<a routerLink="/help" routerLinkActive="active-page" (click)="close()">
						<svg-icon key="help"></svg-icon>
						Help
					</a>
				</li>
				<li *ngIf="isDevelopment">
					<a routerLink="/style-guide" routerLinkActive="active-page" (click)="close()">
						<svg-icon key="resources-info"></svg-icon>
						Style Guide
					</a>
				</li>
			</ul>
		</nav>

		<div class="seal">
			<a href="https://sao.wa.gov/">
				<img class="horizontal" src="assets/seal-horizontal.svg" alt="Seal of the Office of the Washington State Auditor">
				<img class="vertical" src="assets/seal-vertical.svg" alt="Seal of the Office of the Washington State Auditor">
			</a>
		</div>
	</div>
</overlay-scrollbars>

<app-dialog #logoutDialog [showCloseButton]="true" title="Logout Confirmation" class="generic" aria-describedby="description">
	<p id="description">Please confirm you wish to logout as an authenticated user. After logout, you will be taken to the Explore page.</p>
	<ng-container class="actions">
		<button class="dialog-button large outline" (click)="logoutDialog.close()">
			Cancel
		</button>
		<button class="dialog-button large solid" autofocus=autofocus (click)="logout()">
			<svg-icon key="check"></svg-icon>
			Apply
		</button>
	</ng-container>
</app-dialog>



