import {Pipe, PipeTransform} from '@angular/core';
import {OutlookInfo} from '../api/fit-api/models/indicators/outlook-info';
import {OutlookIndicator} from '../../models/outlook-indicator';

/**
 * Generates outlook indicator from outlook info
 * @param outlookInfo
 */
@Pipe({
    name: 'outlookIndicator',
    standalone: true
})
export class OutlookIndicatorPipe implements PipeTransform {
    transform(outlookInfo?: OutlookInfo): OutlookIndicator | undefined {
        if (outlookInfo) {
            return new OutlookIndicator(outlookInfo.outlook);
        } else {
            return undefined;
        }
    }
}
