import {Outlook, OutlookInfo} from '../services/api/fit-api/models/indicators/outlook-info';
import FinancialHealthService, {OutlookConfig} from '../services/financial-health.service';

/**
 *  This model is used to drive the outlook indicator display
 *
 *  Either the Outlook text or the count can be displayed.
 */
export class OutlookIndicator extends OutlookInfo {
	public outlookConfig?: OutlookConfig;

	constructor(
		outlook: Outlook,
		annotation?: string | null,
		public count?: number
	) {
		super(outlook, annotation);
		this.outlookConfig = FinancialHealthService.OUTLOOKS.find(o => o.outlook === outlook);
	}

	// Needed as the string value of the outlook will be used in searches, etc.  Also, the text generated can be used
	// in building narrative text for tooltips and other text-based explanations
	override toString = () => this.outlook?.toString() ?? '';

}
