import {AnnualFilingDataset, AnnualFilingDatasetDetail} from './annual-filing-dataset-detail';

class Live extends AnnualFilingDataset {
	override id!: never;
	barsYearUsed!: number;
	allowedMcags!: Array<string>;
	isRestrictedByMcag!: boolean;

	constructor(data: Live) {
		super(data);
	}
}

class LiveDetail extends AnnualFilingDatasetDetail {
	constructor(data: LiveDetail) {
		super(data);
	}

}

/**
 * Represents ~/api/Live?$expand=detail
 */
export class LiveWithDetail extends Live {
	detail!: LiveDetail;

	constructor(data: LiveWithDetail) {
		super(data);
		this.detail = new LiveDetail(data.detail);
	}
}
