import {
	Pipe,
	PipeTransform
} from '@angular/core';

// todo comment
@Pipe({
	name: 'filter',
	standalone: true,
	pure: false
})
export class FilterPipe implements PipeTransform {

	transform(value: Array<any>, filterExpression: any, optionalParam?: any): any {
		return value.filter(x => filterExpression(x, optionalParam));
	}
}
