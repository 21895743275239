import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Claim, User} from '../models/user';
import {shareReplay} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';


@Injectable({
	providedIn: 'root'
})
export class UserService {

	private _user = new Subject<User>();
	user = this._user.pipe(
		shareReplay(1)
	);

	constructor(
		private http: HttpClient,
		private router: Router
	) {
		this.updateUserClaim();
	}

	updateUserClaim() {
		this.getUserClaim().subscribe(x => {
			this._user.next(new User(x));
		});
	}

	private getUserClaim(): Observable<Claim> {
		return this.http.get<Claim>(`${environment.base}/saml2/GetUserProfile`);
	}

	/**
	 * Login user with an optional return URL.
	 * In dev environments, this will attempt to use the loginUser defined in environment (see SSO documentation for requirements)
	 */
	login(returnUrl: string | null = null) {
		const params = [];
		if (returnUrl) {
			params.push(`returnUrl=${returnUrl}`);
		}
		if (environment.development && environment.loginUser) {
			params.push(`email=${environment.loginUser}`);
		}

		let url = `${environment.base}/saml2/login`;
		params.forEach((param, index) => {
			const prefix = index === 0 ? '?' : '&';
			url          = `${url}${prefix}${param}`;
		});
		window.location.href = url;
	}

	/** Log the current SSO user out and swap in global user. */
	logout() {
		this.http.get(`${environment.base}/service/logout`).subscribe(() => {
			this.router.navigate(
				['/explore']
			).then(() => window.location.reload()); // reload page to reset any angular views
		});
	}
}
