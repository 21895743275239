import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {resolveFinancialHealthIndicatorGroups} from '../resolvers/financial-health-indicator-groups.resolver.service';
import {map} from 'rxjs/operators';
import {FinancialHealthIndicatorResolvedRouteData} from '../../models/financial-health-indicator-resolved-route-data';

/**
 * Determine if Indicator Code is valid
 *  Check indicator code route parameter against indicators. If the indicator is in an indicator group for this mcag,
 *  year range, pass. If not, fail and navigate to first indicator in the indicator groups list. If no indicators, fail and
 *  navigate to 'not found' page.
 *
 * @param route
 * @param state
 */
export const financialHealthIndicatorGuard: CanActivateFn = (route, state) => {
	const router = inject(Router);
	const indicatorCode= route.paramMap.get(FinancialHealthIndicatorResolvedRouteData.indicatorCode);
	const mcag= route.paramMap.get(FinancialHealthIndicatorResolvedRouteData.mcag);
	return resolveFinancialHealthIndicatorGroups(route).pipe(indicatorGroups => {
		return indicatorGroups.pipe(map(indicatorGroups => {
			const indicators = indicatorGroups.map((indicatorGroup) => indicatorGroup.indicators).flat();
			const foundIndicator = indicators.find((indicator) => indicator.instanceCode === indicatorCode);

			if (foundIndicator) {
				return true;
			} else if (indicators.length > 0) {
				router.navigate(['.', 'explore', 'government', mcag, 'health', indicators[0].instanceCode]).then();
				return false;
			} else {
				router.navigate(['/not-found']).then();
				return false;
			}
		}));
	});
}
