import {ActivatedRouteSnapshot, ResolveFn} from '@angular/router';
import {RouteParam} from '../../models/report';
import {resolveGovernmentIdDataset} from './government-id-dataset.resolver';
import {map} from 'rxjs/operators';
import {inject} from '@angular/core';
import {FinancialSummaryService} from '../financial-summary.service';

export const financialReportResolver: ResolveFn<object | undefined> = (route, state) => {
	return resolveFinancialReport(route);
};

export const resolveFinancialReport = (route: ActivatedRouteSnapshot) => {
	const financialSummaryService = inject(FinancialSummaryService);

	return resolveGovernmentIdDataset(route).pipe(
		map(dataset => {
			const reportRouteParameter = route.paramMap.get('report') as RouteParam;

			// find match in report definitions based on dataset and report route param, else return undefined
			return financialSummaryService.REPORTS.find(routeDef =>
			routeDef.routeParam === reportRouteParameter && routeDef.financialDatasetSource === dataset?.source)
		})
	);
};
