import {Component, EventEmitter, Output} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {SvgIconComponent} from '@ngneat/svg-icon';
import {LayoutClassDirective} from '../../services/directives/layout-class.directive';

@Component({
	selector: 'app-corner',
	standalone: true,
	imports: [CommonModule, SvgIconComponent, NgOptimizedImage],
	templateUrl: './corner.component.html',
	styleUrls: ['./corner.component.scss'],
	hostDirectives: [LayoutClassDirective]
})
export class CornerComponent {

	@Output() launchMenu = new EventEmitter<void>();

	open(): void {
		this.launchMenu.next();
	}
}
