import {SnapshotWithDetail} from './snapshot';
import {LiveWithDetail} from './live';
import {SchoolsWithDetail} from './schools';
import {DatasetSource} from './dataset-source';
import {KeysContainingPropertyOfType} from '../../../configuation-objects';

export enum DatasetType {
	Snapshot = 'Snapshot',
	Live = 'Live',
	Schools = 'Schools'
}

/**
 * Configuration for Datasets providing both lookups and type assertions.
 *  Datasets should be ordered so that the default Dataset for a given DatasetSource comes first.
 *  E.g. Snapshot is defined before Live.
 */
export const DatasetConfig = {
	[DatasetType.Snapshot]: {
		datasetType: DatasetType.Snapshot,
		datasetSource: DatasetSource.AnnualFiling,
		versioned: true,
		model: SnapshotWithDetail,
		route: 'Snapshots',
		title: 'SAO Annual Filing Snapshots',
	},
	[DatasetType.Live]: {
		datasetType: DatasetType.Live,
		datasetSource: DatasetSource.AnnualFiling,
		versioned: false,
		model: LiveWithDetail,
		route: 'Live',
		title: 'SAO Annual Filing Live Data'
	},
	[DatasetType.Schools]: {
		datasetType: DatasetType.Schools,
		datasetSource: DatasetSource.OSPI,
		versioned: false,
		model: SchoolsWithDetail,
		route: 'Schools',
		title: 'Schools'
	}
} as const;

/**
 * Union of all possible DatasetDetailTypes.
 * todo this is wrong with the typeof, isn't it? InstanceType + typeof is crazy. How did I get that??
 */
export type DatasetDetail = InstanceType<typeof DatasetConfig[DatasetType]['model']>;
// export type RealDatasetsWithDetail = typeof DatasetConfig[DatasetType]['model'];

/**
 * Retrieve the model used for the given DatasetType from the DatasetConfig. InstanceType removes typeof inferred by
 *  type system and enables new().
 */
// export type DatasetWithDetail<T extends DatasetType> = InstanceType<typeof DatasetConfig[T]['model']>;

/**
 * todo this could be made generic. E.g. DatasetsOfSource<DatasetSource.AnnualFiling>
 * Could be useful for typing parameters provided to Reports since they are DatasetSource dependent.
 */
export type AnnualFilingDatasets = KeysContainingPropertyOfType<typeof DatasetConfig, 'datasetSource', DatasetSource.AnnualFiling>;

/**
 * Above but detail model.
 */
export type AnnualFilingDatasetsDetail = InstanceType<typeof DatasetConfig[AnnualFilingDatasets]['model']>;

/**
 * Datasets where DatasetConfig[DatasetType].versioned = true.
 */
export type VersionedDataset = KeysContainingPropertyOfType<typeof DatasetConfig, 'versioned', true>;

export type SingletonDataset = KeysContainingPropertyOfType<typeof DatasetConfig, 'versioned', false>;

/**
 * Versioned Datasets can have an id parameter (number). Singletons cannot (never).
 */
export type DatasetId<T extends DatasetType> = T extends VersionedDataset ? number : never;

/**
 * Tests
 */
// class TestDataset<T extends DatasetType> {
// 	constructor(
// 		public datasetType: T,
// 		public id: DatasetIdType<T>
// 	) {
// 	}
// }
// const t1 = new TestDataset(DatasetType.Snapshot, 12);
// const t2 = new TestDataset(DatasetType.Snapshot);
// const t3 = new TestDataset(DatasetType.Live, 12);
// const t4 = new TestDataset(DatasetType.Live);
// type datasetVersionedIdType = DatasetIdType<DatasetType.Snapshot>;
// type datasetUnversionedIdType = DatasetIdType<DatasetType.Live>;
// const datasetIdentifierTest = {
// 	versionedPass: new DatasetIdentifier(DatasetType.Snapshot, 3),
// 	versionedFail: new DatasetIdentifier(DatasetType.Snapshot),
// 	unversionedPass: new DatasetIdentifier(DatasetType.Live),
// 	unversionedFail: new DatasetIdentifier(DatasetType.Live, 3),
// };

