import {PivotAnnotation, PivotAnnotationType} from './pivot-annotation';
import {PivotRow} from './pivot-row';
import {RelativeDirection} from './direction';
import {GridCoordinates} from './grid-coordinates';

export enum PivotCellType {
	descriptionArea = 'description-area', // top leftmost header cell
	columnHeader = 'column-header',
	rowHeader = 'row-header',
	data = 'data'
}

export enum PivotCellSubType {
	subtotal = 'subtotal',
	informational = 'informational'
}

// https://www.w3schools.com/tags/att_th_scope.asp
export enum PivotHeaderCellScope {
	col = 'col',
	row = 'row',
	colGroup = 'colgroup',
	rowGroup = 'rowgroup',
}

// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-expanded#associated_roles
export enum PivotCellRole {
	columnHeader = 'columnheader',
	rowHeader = 'rowheader',
	gridCell = 'gridcell'
}

export enum Skip {
	page = 'page',
	grid = 'grid'
}

/**
 * PivotCell - a header or data cell in the pivot table
 *
 * `T` represents the type of one row of the original data source record.
 *
 * This object provides:
 * * Cell details w/colspan & rowspan values
 * * Expanded state of the cell if it is one of the three header types
 * * Details sufficient for handling expand/collapse events (what header cell was expanded/collapsed)
 * * Header annotations (e.g. normalization, missing filing reason, account codes, etc.)
 * * Text labels & display values for each cell
 */
export class PivotCell<T extends object> {
	public excelNumberFormat = '$#,##0'; // Used to render values in Excel as needed

	annotations: PivotAnnotation[] = [];  // Annotations to include in the header cell

	public pivotRow: PivotRow<T> | undefined // Parent row of this cell todo - remove the undefined once we abandon the pivot table sample data

	// Depth of header cell (e.g., 1, 2. 3, etc.)
	public readonly depth?: number;

	// property name from the table's column or row hierarchy
	//public readonly field?: keyof T;

	// Scope https://www.w3schools.com/tags/att_th_scope.asp
	public readonly scope?: PivotHeaderCellScope;

	// Role value for HTML rendering - https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-expanded#associated_roles
	public readonly role?: PivotCellRole;

	public readonly isHeader: boolean = false;

	// Column indexes this cell spans within the PivotTable
	public gridColumnIndexes: number[] = [];
	// Row indexes this cell spans within the PivotTable
	public gridRowIndexes: number[] = [];

	// Cell Sub Type - allows for enhanced styling in the data grid component
	public subType?: PivotCellSubType;

	// For data cells, these store the hierarchy values that the cell represents
	public columnHierarchyValues: (number | string) [] = []; // Column hierarchy represented by an array of values; useful for future navigation to "By Dollars"
	public rowHierarchyValues: (number | string) [] = [];    // Row hierarchy represented by an array of values; useful for future navigation to "By Dollars"

	constructor(
		readonly type: PivotCellType,
		public aggregationValue: number | null, // For data cells, aggregated amount/value for the data intersection of the column & row hierarchies
		public value: number | string | null,  // For data cells, calculated/normalized value; for header cell, value of that position in the col or row hierarchy
		public displayValue: number | string, // Label to display in the header cell
		readonly hierarchyValues: (number | string) [] = [],  // the values of the hierarchy that this header cell represents; e.g., [2019, 'All Funds', 1]
		public columnSpan: number = 1, // Column span for this header cell
		public rowSpan: number = 1, // Row span for this header cell
		public expanded?: boolean // Expanded state of the header cell; if undefined, header cell is not expandable
	) {
		if (this.hierarchyValues.length) {
			this.depth = this.hierarchyValues.length;
			//this.field = this.hierarchy[this.hierarchy.length - 1]; // The property field name associated with this header cell is the last in the hierarchy
		}

		// Set the scope value for cells that are headers
		if (this.type === PivotCellType.descriptionArea || this.type === PivotCellType.columnHeader) {
			this.scope = columnSpan === 1 ? PivotHeaderCellScope.col : PivotHeaderCellScope.colGroup;
		} else if (this.type === PivotCellType.rowHeader) {
			this.scope = rowSpan === 1 ? PivotHeaderCellScope.row : PivotHeaderCellScope.rowGroup;
		}

		if (type === PivotCellType.descriptionArea || type === PivotCellType.columnHeader) {
			this.role = PivotCellRole.columnHeader;
			this.isHeader = true;
		} else if (type === PivotCellType.rowHeader) {
			this.role = PivotCellRole.rowHeader;
			this.isHeader = true;
		} else if (type === PivotCellType.data) {
			this.role = PivotCellRole.gridCell;
		}
	}

	public firstAnnotationOfType(annotationType: PivotAnnotationType) {
		return this.annotations.find(a => a.type === annotationType);
	}

	/**
	 * Returns the coordinates of the <strong>edge</strong> of the next cell according to `direction` and `offset`.
	 * @param {RelativeDirection} direction The direction of travel
	 * @param {number} offset How many cells to traverse in the direction specified.
	 * 		Infinity resolves to the last cell in the specified direction,
	 * 		i.e. end of grid
	 * @return {GridCoordinates | null}
	 */
	getCellCoordinates(
		direction: RelativeDirection,
		offset: number
	): GridCoordinates | null {
		if (this.gridRowIndexes === undefined ||
			this.gridColumnIndexes === undefined ||
			this.pivotRow?.pivotTable?.totalRows === undefined ||
			this.pivotRow?.pivotTable?.totalColumns === undefined
		) {
			throw ReferenceError('Cannot calculate coordinates due to missing column and/or row references.');
		}

		const firstColumnIndex = 0;
		const lastColumnIndex = this.pivotRow.pivotTable.totalColumns - 1;
		const firstRowIndex = 0;
		const lastRowIndex = this.pivotRow.pivotTable.totalRows - 1;

		// column edge is leftmost in all directions but right
		const columnEdge = direction === RelativeDirection.right
			? Math.max(...this.gridColumnIndexes)
			: Math.min(...this.gridColumnIndexes);
		// row edge is topmost in all directions but down
		const rowEdge = direction === RelativeDirection.down
			? Math.max(...this.gridRowIndexes)
			: Math.min(...this.gridRowIndexes);

		// Set these to a number if moving in that direction.
		let nextColumn: number | null = null;
		let nextRow: number | null = null;

		switch (direction) {
			case RelativeDirection.left: {
				// apply negative offset but clamp to firstColumnIndex
				nextColumn = Math.max(firstColumnIndex, columnEdge - offset);
				break;
			}
			case RelativeDirection.down: {
				// apply positive offset but clamp to lastRowIndex
				nextRow = Math.min(lastRowIndex, rowEdge + offset);
				break;
			}
			case RelativeDirection.up: {
				// apply negative offset but clamp to firstRowIndex
				nextRow = Math.max(firstRowIndex, rowEdge - offset);
				break;
			}
			case RelativeDirection.right: {
				// apply positive offset but clamp to lastColumnIndex
				nextColumn = Math.min(lastColumnIndex, columnEdge + offset);
				break;
			}
		}

		const nextCellCoords = new GridCoordinates(
			// preserve the existing edge if not navigating in that direction
			nextColumn ?? columnEdge,
			nextRow ?? rowEdge
		);
		const thisCellCoords = new GridCoordinates(this.gridColumnIndexes, this.gridRowIndexes);
		// If there is no next cell, the column & row edges are preserved, i.e.
		// coordinates will be equal if no next cell
		return nextCellCoords.isEqual(thisCellCoords)
			? null // null signifies no next cell to navigate to
			: nextCellCoords;
	}

	/**
	 * Determines whether this cell intersects GridCoordinates.
	 * @param {GridCoordinates} coords
	 * @return {boolean}
	 */
	intersects(coords: GridCoordinates): boolean {
		return this.gridColumnIndexes.includes(coords.column)
			&& this.gridRowIndexes.includes(coords.row);
	}
}
