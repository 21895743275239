import {MonoTypeOperatorFunction, Observable} from 'rxjs';
import {share, shareReplay} from 'rxjs/operators';
import { environment } from '../../../environments/environment';

// console debug information in development environment
const showDebug = false;

/**
 * Specifies the behavior of the Share Decorator.
 */
export enum ShareMode {
	/**
	 * Joins Subscribers to the active Observable, or creates a new Observable if no current Subscribers.
	 *  (i.e., [rxjs share operator](https://rxjs.dev/api/index/function/share)).
	 */
	InProgress = 'InProgress',
	/**
	 * Replays the *value* from the Observable execution without re-evaluation.
	 *  (i.e., [rxjs shareReplay operator](https://rxjs.dev/api/index/function/shareReplay))
	 */
	Replay = 'Replay'
}

// Property decorators are not provided with a descriptor, so initializer and return type are not accessible
//  @see https://www.typescriptlang.org/docs/handbook/decorators.html#property-decorators
// export function ShareProp<T>(shareMode: ShareMode = ShareMode.InProgress) {
// 	return function (
// 		// Containing class
// 		target: Object,
// 		// the name of the method
// 		memberName: string
// 		// No descriptor.value for properties
// 	) {
// 		console.debug('ShareProp', target, memberName);
// 	}
// }

/**
 * Shares Observables by memoizing arguments and storing the Observable in a Map with the appropriate rxjs operator
 *  applied. Because sharing is caring. 🥰
 * @param [shareMode=ShareMode.InProgress] - Whether result (Replay) or instance (InProgress) is shared
 */
export function Share<T>(shareMode: ShareMode = ShareMode.InProgress) {
	return function (
		// Containing class
		target: Object,
		// the name of the method; may only be used on strings (not symbols) because we need to guarantee a key for memoization
		memberName: string,
		// TypedPropertyDescriptor<T> types descriptor.value as T
		// Can only be applied to a function that returns an Observable
		descriptor: TypedPropertyDescriptor<(...args: any[]) => Observable<T>>
	) {
		let operator: () => MonoTypeOperatorFunction<T>;
		if (shareMode === ShareMode.InProgress) {
			operator = share;
		} else if (shareMode === ShareMode.Replay) {
			operator = shareReplay;
		} else {
			throw new Error(`Share Decorator was provided an invalid shareType: ${shareMode}`);
		}

		// Store the memoizied function for use on subsequent calls
		const memoizedObservables = new Map<typeof memberName, Observable<T>>();
		// Make sure the function exists
		if (!descriptor.value) {
			throw new Error(`ShareInProgress decorator found no function to decorate.`);
		}
		const originalFunction = descriptor.value;
		descriptor.value = function (...args: any[]): Observable<T> {
			// Memoize based on the args that get passed in.
			const key = args.join('::');
			let observable = memoizedObservables.get(key);

			if (!observable) {
				observable = originalFunction
					// Call original function with parameters, applying scope from its original location
					.apply(this, args)
					.pipe(
						// Share any in-progress requests
						operator()
					);
				// Store for subsequent calls matching signature
				memoizedObservables.set(key, observable);
			}

			if (environment.development && showDebug) {
				const identifier = `@Share#${target.constructor.name}.${memberName}: ${memoizedObservables.size} variant(s) stored in ${shareMode} mode.`;
				console.groupCollapsed(identifier);
				console.debug('decorator metadata', { target, memberName, descriptor });
				console.debug('internals', {
					'functionThis': this, // should be the original scope of the function (i.e., the service it came from)
					'functionArgs': args,
					'memoizedObservables': memoizedObservables.keys()
				});
				console.groupEnd();
			}

			return observable;
		};
	};
}
