import {
	Component,
	ElementRef, EventEmitter,
	Input, OnDestroy, Output,
	ViewChild
} from '@angular/core';
import {SvgIconComponent} from '@ngneat/svg-icon';
import {overlayScrollDarkVertical} from '../../layout/overlay-scroll-options';
import {OverlayscrollbarsModule} from 'overlayscrollbars-ngx';
import {AppLayoutService} from '../../services/app-layout.service';
import {NgClass} from '@angular/common';
import {ResizeObserverModule} from '@ng-web-apis/resize-observer';
import {
	autoUpdate,
	computePosition,
	ComputePositionConfig,
	flip,
	limitShift,
	offset,
	Placement,
	shift
} from '@floating-ui/dom';
import {Key} from 'ts-key-enum';
import {PxToRemPipe} from '../../services/pipes/px-to-rem.pipe';

/**
 * Creates a dialog that conforms to/implements the native
 * [HTML Dialog](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog).
 *
 * # API
 *   - It is the parent's responsibility to open and close the dialog.
 * # Content Projection
 *   - The default slot is projected into the main content (middle) of the dialog.
 *   - .actions is projected into the action bar (sticky footer) at the bottom of the dialog and is used to provide
 *     the user with actions to perform in relation to the dialog.
 * # Autofocus
 *   - Browsers will automatically focus the first focusable item within the dialog as a feature of HTML Dialog.
 *   - Override this behavior by placing the [autofocus] attribute on projected content. (e.g. keeping track of the
 *     last-focused item when the dialog opens or focusing a specific button on open).
 * # Floating UI Target
 * 	 - If you pass this component a floatingUITarget element, in non-modal mode, it will use implement the floating UI library
 * 	   and use that element as its anchor element
 * # Click Outside to Close
 * 	 - Default for modal dialog. If you want to turn it off, simply pass [cancelOnOutsideClick] false.
 * 	 - If you want to enable for non-modal dialog, this must be done at parent level. See title-dropdown component for pattern.
 */
@Component({
	selector: 'app-dialog',
	standalone: true,
	templateUrl: './dialog.component.html',
	imports: [
		SvgIconComponent,
		OverlayscrollbarsModule,
		NgClass,
		PxToRemPipe,
		ResizeObserverModule
	],
	styleUrl: './dialog.component.scss'
})
export class DialogComponent implements OnDestroy {
	// When true, creates a button in the title bar that allows the user to close the dialog
	@Input() showCloseButton = false;
	@Input() showTitle = true;
	@Input() title = 'Dialog';
	@Input() minWidth = 304; // min width of dialog (defaults to 304px)
	@Input() cancelOnOutsideClick = true;
	@Input() placement: Placement = 'top-end'; // placement of dialog (defaults to 'top-end')
	@Input() offset = true; // optional offset
	@Input() floatingUITarget?: Element; // target element used for floating UI

	// Signals parent when the dialog closes (for any close event, e.g. click outside logic, escape key logic, and clicking on the x icon)
	@Output() closed = new EventEmitter<void>();

	@ViewChild('dialog') dialog?: ElementRef<HTMLDialogElement>;
	@ViewChild('content') content?: ElementRef<HTMLElement>;

	// Keeps track of the autoUpdate function in order to cancel it when hiding the popover
	cancelAutoUpdate?: () => void;

	hasVerticalScrollbar = false;

	constructor(
		public appLayoutService: AppLayoutService,
		public pxToRemPipe: PxToRemPipe
	) {
	}

	showModal = () => this.dialog?.nativeElement.showModal();

	show = () => {
		this.dialog?.nativeElement.show(); // html dialog show function

		// if you pass target to component, activate floating UI
		if (this.floatingUITarget && this.dialog?.nativeElement) {
			// Update the positioning if the user scrolls/resizes the page and record reference (cancelAutoUpdate) to
			//  function that cancels the autoUpdate
			this.cancelAutoUpdate = autoUpdate(
				this.floatingUITarget,
				this.dialog?.nativeElement,
				this.updatePosition
			)
		}
	}

	// close dialog
	// always emit when the dialog closes (from x icon, from click outside of dialog logic, etc.)
	close = () => {
		this.closed.emit(); // emit close (for parent logic, e.g. trigger reset)
		this.cancelAutoUpdate?.(); // only for non-modal mode (for floating UI)
		this.dialog?.nativeElement.close();
	}

	// click outside of dialog logic (for modal dialog)
	click(event: MouseEvent) {
		// if you click #dialog but not one of its children or inner wrapper (and if cancelOnOutsideClick is true)
		if ((event.target === this.dialog?.nativeElement) && this.cancelOnOutsideClick) {
			this.close();
		}
	}

	// escape key logic
	// note: escape key closes out-of-the-box, call close() to emit and cancel auto update (non-modal)
	keydown(event: KeyboardEvent) {
		if (event.key === Key.Escape) {
			this.close();
		}
	}

	// if content section height is bigger than overlay scroll height, then content is overflowing - add gray lines
	onResize(event: ResizeObserverEntry[]) {
		const firstTarget = event?.[0];

		if (!firstTarget) {
			return;
		}

		if (this.content) {
			this.hasVerticalScrollbar = Math.round(firstTarget?.contentRect.height) < this.content?.nativeElement.clientHeight;
		}
	}

	// Position Popover relative to target using middleware specified in `this.floatingUiOptions`
	private updatePosition = (): void => {
		if (!this.floatingUITarget) return;

		// Options for Floating UI. See: https://floating-ui.com/docs/
		const floatingUiOptions: Partial<ComputePositionConfig> = {
			placement: this.placement,
			middleware: [
				this.offset ? offset(({rects}) => ({
					// set to end of target element
					alignmentAxis: -rects.floating.width
				})) : null,
				flip(), // flip and shift mimic the default popperjs behavior
				shift({limiter: limitShift()}),
			]
		}

		computePosition(
			this.floatingUITarget,
			this.dialog?.nativeElement as HTMLElement,
			floatingUiOptions
		).then(({x, y}) => {
			Object.assign((this.dialog?.nativeElement as HTMLElement).style, {
				left: `${x}px`,
				top: `${y}px`
			})
		})
	}


	ngOnDestroy = () => {
		// Manually kill autoUpdate since it is not an Observable
		this.cancelAutoUpdate?.();
	}

	protected readonly overlayScrollDarkVertical = overlayScrollDarkVertical;
}
