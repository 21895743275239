import {ActivatedRouteSnapshot, ResolveFn} from '@angular/router';
import {resolveGovernmentType} from './government-type.resolver';
import {map} from 'rxjs/operators';

export const governmentTypeNameResolver: ResolveFn<string> = (route, state) => {
	return resolveGovernmentTypeName(route);
};

export const resolveGovernmentTypeName = (route: ActivatedRouteSnapshot) => {
	return resolveGovernmentType(route).pipe(
		map(govType => govType.descriptionPlural ?? '')
	);
};
