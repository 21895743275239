import { ResolveFn } from '@angular/router';
import {ExtractType, ExtractTypeId} from '../../pages/data-extracts/extract-type';
import {inject} from '@angular/core';
import {DataExtractsService} from '../data-extracts.service';


export const extractTypeResolver: ResolveFn<ExtractType | undefined> = (route, state) => {
	// todo - same code as in extractTitleResolver ; move someplace common
	const extractTypeId = route.paramMap.get('extractTypeId') as ExtractTypeId;
	if (extractTypeId == null) {
		throw new Error(`A valid extract type must be provided to use this resolver. Offered: ${extractTypeId}`);
	}

	return inject(DataExtractsService).getExtractType(extractTypeId);
};
