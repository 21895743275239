import {PivotCell} from './pivot-cell';
import {PivotTable} from './pivot-table';

export enum PivotRowType {
  header = 'header',
  data = 'data',
  total = 'total'
}

/**
 * Represents each row of the generated PivotTable view model.
 *
 * Each PivotRow has an array of PivotCells. Not every PivotRow has an equal number of cells
 * as the cells themselves can span multiple columns and rows similar to how HTML tables are implemented.
 *
 * Each row can have an array of child rows - this structure helps during rendering of the HTML `<table>`
 */
export class PivotRow<T extends object> {

	public pivotTable?: PivotTable<T> // For navigation/reference

	// Row index of the overall grid
	public gridRowIndex?: number;

	private _expanded?: boolean
	public get expanded() { return this._expanded }
	public set expanded(expanded: boolean | undefined) {
		this._expanded = expanded;
		this.updateChildRows();
	}

	private _allChildRows?: Array<PivotRow<T>>;
	public set allChildRows(rows: Array<PivotRow<T>> | undefined) {
		this._allChildRows = rows;
		this.updateChildRows();
	}

	// Visible/expanded child rows
	public childRows?: Array<PivotRow<T>>;

	constructor(
		readonly type: PivotRowType,
		readonly cells: PivotCell<T> [],
		readonly level?: number, // For nested rows, the depth of the nesting (used for aria-depth rendering (starts at 1, not 0)
		expanded?: boolean, // Expanded state of the row; if undefined, row is not expandable
		childRows?: Array<PivotRow<T>>,  // Represents hierarchy of rows as children to enable easier rendering to HTML
	) {
		this.allChildRows = childRows;
		this.expanded = expanded;
	}

	private updateChildRows() {
		if (this.expanded) {
			this.childRows = this._allChildRows;
		} else {
			this.childRows = undefined;
		}
	}

}
