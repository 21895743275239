import {ActivatedRouteSnapshot, ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {DatasetService} from '../api/fit-api/dataset.service';
import {DatasetType} from '../api/fit-api/models/datasets/dataset-type';
import {of} from 'rxjs';
import {Dataset} from '../api/fit-api/models/datasets/dataset';
import {GovernmentId} from '../api/fit-api/models/government-id';

/**
 * Resolves the proper Financials Dataset to use for this route. This assumes that any datasetType (+datasetId) provided
 *  has been checked by canActivateDatasetGuard(s) for validity.
 * @param route
 * @param state
 */
export const governmentIdDatasetResolver: ResolveFn<Dataset | null> = (route, state) => {
	return resolveGovernmentIdDataset(route);
};

export const resolveGovernmentIdDataset = (route: ActivatedRouteSnapshot) => {
	const datasetService = inject(DatasetService);
	// 1. If datasetType (+ datasetId) provided, resolve that Dataset
	const datasetType = route.paramMap.get('datasetType') as DatasetType;
	if (datasetType) {
		const datasetId = route.paramMap.get('datasetId')
			? Number(route.paramMap.get('datasetId'))
			: undefined;
		return datasetService.getDataset(datasetType, datasetId);
	}

	// Otherwise, return the default dataset for the GovernmentId, or null if no GovernmentId can be resolved
	const governmentId = GovernmentId.fromRoute(route);
	return governmentId ? datasetService.getDefaultDatasetForGovernmentId(governmentId) : of(null);
};
