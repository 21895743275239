import {Injectable} from '@angular/core';
import {RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {Title} from '@angular/platform-browser';

/**
 * Provides a dynamic title strategy.
 * todo where does this go?
 */
@Injectable({providedIn: 'root'})
export class FinancialIntelligenceToolPageTitleStrategy extends TitleStrategy {
	constructor(private readonly title: Title) {
		super();
	}

	override updateTitle(routerState: RouterStateSnapshot) {
		const title = this.buildTitle(routerState);
		if (title !== undefined) {
			this.title.setTitle(`FIT | ${title}`);
		} else {
			this.title.setTitle('Financial Intelligence Tool');
		}
	}
}
