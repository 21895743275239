import {AnnualFilingDataset, AnnualFilingDatasetDetail} from './annual-filing-dataset-detail';

export class Snapshot extends AnnualFilingDataset {
	barsYearUsed!: number;
	code!: string;
	createdById?: number;
	createdByEmail!: string;
	createdByName!: string;
	type!: string;
	periodicity!: string;
	totalRevenues!: number;
	filersWithData!: number;

	protected constructor(data: AnnualFilingDataset) {
		super(data);
	}
}

class SnapshotDetail extends AnnualFilingDatasetDetail {
	containsOperatingAllocations!: boolean;

	constructor(data: SnapshotDetail) {
		super(data);
	}
}

/**
 * Represents ~/api/Snapshots(n)?$expand=detail
 */
export class SnapshotWithDetail extends Snapshot {
	detail!: SnapshotDetail;

	constructor(data: SnapshotWithDetail) {
		super(data);
		this.detail = new SnapshotDetail(data.detail);
	}
}


