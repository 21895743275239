<div class="item-contents">
    <div class="header">
        <ng-container [ngSwitch]="item?.type">
            <app-outlook-indicator
                    *ngSwitchCase="'Financial Health'" class="outlook" size="large"
                    [outlookIndicator]="$any(item?.title)"
            ></app-outlook-indicator>
            <div *ngSwitchCase="'Government Type'" class="tag medium government-type-value">
                {{$any(item?.title)}}
            </div>
            <p *ngSwitchDefault class="title">{{item?.title}}</p>
        </ng-container>
        <div class="details">
            <div *ngFor="let field of item?.detailFields" class="field" [ngClass]="{'secondary': !field.alwaysDisplay}">
                <div *ngIf="field.label" class="label">{{field.label}}:</div>
                <ng-container [ngSwitch]="field.type">
                    <div *ngSwitchCase="'Government Type'" class="tag small government-type-value">
                        {{$any(field.value).description}}
                    </div>
                    <app-outlook-indicator
                            *ngSwitchCase="'Outlook Indicator'" class="outlook" size="small"
                            [outlookIndicator]="$any(field.value)"
                    ></app-outlook-indicator>
                    <app-outlook-icon-counts
                            *ngSwitchCase="'Outlook Indicators'" size="small"
                            [outlookIndicators]="$any(field.value)"
                    ></app-outlook-icon-counts>
                    <div *ngSwitchDefault class="value">{{field.value}}</div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="type">
        <svg-icon *ngIf="item?.pinned" key="thumbtack-solid"></svg-icon>
        {{item?.type | titlecase}}
    </div>
</div>
<div *ngIf="!last" class="divider">
    <hr>
</div>
