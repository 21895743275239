import {MeasureUnitInfo} from '../services/api/fit-api/models/indicators/measure-unit-info';

export class TrendAmount {
		year: number;
		amount: number;
		unitInfo?: MeasureUnitInfo;

	constructor(year: number, amount: number, unitInfo?: MeasureUnitInfo) {
		this.year = year;
		this.amount = amount;
		this.unitInfo = unitInfo;
	}
}
