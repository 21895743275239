import {InstantiationLevel} from './instantiation-level';
import {IndicatorReportType} from './indicator-report';
import {OutlookInfo} from './outlook-info';
import {Indicator} from './indicator';

export class IndicatorGroup {
	constructor(
		public group: 'All Governmental Funds' | 'General Fund' | string,
		public mcag: string,
		public instantiationLevel: InstantiationLevel,
		public report: IndicatorReportType,
		public filingBasis: 'Cash' | 'GAAP' | 'None',
		public outlookInfo: OutlookInfo,
		public indicators: Array<Indicator>
	) {
	}

}
