import {ResolveFn} from '@angular/router';
import {Dataset} from '../api/fit-api/models/datasets/dataset';
import {inject} from '@angular/core';
import {DatasetService} from '../api/fit-api/dataset.service';
import {FitApiService} from '../api/fit-api/fit-api.service';
import {map} from 'rxjs/operators';
import {DatasetSource} from '../api/fit-api/models/datasets/dataset-source';
import {forkJoin, switchMap} from 'rxjs';

export const byDollarsDatasetsResolver: ResolveFn<Array<Dataset>> = () => {
	return resolveByDollarsDatasets();
};

export const resolveByDollarsDatasets = () => {
	const api = inject(FitApiService);
	const datasetService = inject(DatasetService);

	// The unique list of DatasetSources available from `api/GovernmentTypes` endpoint
	const allFITGovernmentTypesDatasetSources = api.governmentTypesForList.pipe(
		map(govTypes => {
			const datasetSources = govTypes
				.filter(x => x.financialsDatasetSource !== DatasetSource.None)  // Only those government types that have financial data
				.map(x => x.financialsDatasetSource);
			// get unique values by way of Set
			return Array.from(new Set(datasetSources));
		})
	);

	return allFITGovernmentTypesDatasetSources.pipe(
		// make a call for each DatasetSource
		switchMap(datasetSources =>
			forkJoin(
				datasetSources.map(
					dataset => datasetService.getDataset(dataset)
				)
			)
		),
		// remove nulls and erase null with a type guard
		map(datasets => datasets.filter(
			(dataset): dataset is Dataset => dataset !== null
		))
	)
};
