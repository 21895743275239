// Serves as the "id" of the cell by using the leftmost value when spanning columns
export class GridCoordinates {
	public column: number;
	public row: number;

	constructor(
		column: number | Array<number>,
		row: number | Array<number>,
	) {
		// Select the "leftmost" value from an array
		this.column = Array.isArray(column) ? column[0] : column;
		// Select "topmost" value from array
		this.row = Array.isArray(row) ? row[0] : row;
	}

	isEqual = (other: GridCoordinates | null): boolean =>
		other !== null && this.row === other.row && this.column === other.column;

	// Outputs "x,y" where x is the column and y is the row
	toString = () => [this.column, this.row].join(',');
}
