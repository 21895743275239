import {DatasetConfig, DatasetType} from './dataset-type';
import {DatasetSource} from './dataset-source';
import {KeysOfType} from '../../../configuation-objects';
import {TypeOrArrayOf} from '../../../../../models/types/type-or-array-of';
import {DatasetResponse} from './dataset-response';

/**
 * Represents shared Dataset properties.
 */
export class Dataset {
	datasetType!: DatasetType;
	id?: number;
	source!: DatasetSource;
	name!: string;
	extendedName!: Array<string>;
	description!: string;
	comment!: string;
	dateCreated!: Date;

	// todo should these properties be put into a metadata class?
	route: string;
	detailModel: typeof DatasetConfig[DatasetType]['model'];

	constructor(data: Dataset | DatasetResponse) {
		// todo this would be more precise to take Dataset | DatasetResponse and assign properties directly
		Object.assign(this, data);

		// UI model additions
		const datasetTypeConfig = DatasetConfig[this.datasetType];
		this.route = datasetTypeConfig.route;
		if (datasetTypeConfig.versioned) {
			this.route += `(${this.id})`;
		} else {
			// remove `0` from singleton OData responses
			delete this.id;
		}
		this.detailModel = datasetTypeConfig.model;
	}

	toString = (): string => this.datasetType + (this.id ? `_${this.id}` : '');
}

export abstract class DatasetDetail {
	id!: number;

	// testArr: Array<any>;

	protected constructor(data: Partial<DatasetDetail>) {
		Object.assign(this, data);
		// this.lookupWithTypeSafety('testArr'); // array
		// this.lookupWithTypeSafety('id'); // not an array
	}

	// May be able to do this if I can wrap my head around this concept
	//  https://github.com/microsoft/TypeScript/issues/30728
	// lookupWithTypeSafety(
	// 	collection: KeysOfType<this, Array<any>>
	// ) {
	// 	const ref = this[collection] as any as Array<any>;
	// 	return this[collection].find(x => x);
	// }

	/**
	 * Get reference data from a collection present on the Detail.
	 * @param collection An array present on this class instance in which to search.
	 * @param value The value to match against records in `collection`. Can match on multiple values when `key` is
	 *  provided as an array representing a composite key. Date is added here for compatibility with DevExtreme
	 *  customizeText, but is not currently supported.
	 * @param key The name of the property in `collection` to match `value` against, or an array of the keys (composite)
	 *  to match the array in `value` against the records in `collection`.
	 */
	getReference(
		collection: KeysOfType<this, any[]>, // keyof this, or KeysOfType<this, Array<any>> if Microsoft had this implemented yet
		value: TypeOrArrayOf<string | number | Date>, // is there a way to bind these with a constraint that makes them both either primitives or arrays?
		key: TypeOrArrayOf<string> = 'id'
	): any {
		if (value instanceof Date) {
			throw new Error(`Dates not supported.`);
		}

		// todo this is typed as any because TS is stupid https://github.com/microsoft/TypeScript/issues/48992
		const collectionArr = this[collection];
		// this also satisfies the type system at runtime, but not design time
		if (!Array.isArray(collectionArr)) {
			throw new Error(`Collection '${String(collection)}' not found.`);
		}

		// normalize by converting primitives to single-value array
		const compositeKey = Array.isArray(key) ? key : [key];
		const compositeValue = Array.isArray(value) ? value : [value];

		if (compositeKey.length !== compositeValue.length) {
			throw new Error(`'value' and 'key' must contain the same number of values.`);
		}

		// search for a collection record that matches the composite key values
		const record = collectionArr.find(search =>
			// value and key arrays must be provided in the same order
			compositeKey.every((keyName, index) =>
				// match if every key/value in their respective arrays matches the record we are processing
				search[keyName] === compositeValue[index]
			)
		);

		return record;
	}
}

