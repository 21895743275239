import {Dataset, DatasetDetail} from './dataset';
import {AccountDescriptor} from '../snapshots/account-descriptor';
import {SchoolFund} from '../funds/school-fund';
import {FinancialSummarySection} from '../snapshots/financial-summary-section';
import {ReportItem} from '../schools/report-item';

class Schools extends Dataset {
	override id!: never;
	constructor(data: Schools) {
		super(data);
	}
}

/**
 * todo type
 */
class SchoolsDetail extends DatasetDetail {
	includedYears!: Array<number>;
	indicatorNames!: Array<any>;
	reports!: Array<any>;
	funds!: Array<SchoolFund>;
	programs!: Array<any>;
	activities!: Array<any>;
	objects!: Array<any>;
	reportItems!: Array<ReportItem>;
	columns!: Array<any>;
	accountDescriptors!: Array<AccountDescriptor>;
	financialSummarySections!: Array<FinancialSummarySection>;

	constructor(data: Partial<SchoolsDetail>) {
		super(data);
	}
}

/**
 * Represents ~/api/Schools?$expand=detail
 */
export class SchoolsWithDetail extends Schools {
	detail: SchoolsDetail;

	constructor(data: SchoolsWithDetail) {
		super(data);
		// @ts-ignore
		this.detail = new SchoolsDetail(data.detail);
	}
}
