export enum RankingSortOrder {
	schoolsEnrollment,
	schoolsRevenues,
	schoolsExpenditures,

	population,
	saoAnnualFilingRevenues,
	saoAnnualFilingExpenditures,
	saoAnnualFilingTaxes,
	saoAnnualFilingPublicSafety
}
