import {EventEmitter, Injectable} from '@angular/core';
import {OverlayScrollbars} from 'overlayscrollbars';
import {Subject} from 'rxjs';
import {shareReplay} from 'rxjs/operators';

/**
 * Provides a scrolling event bus for the application's layout components
 *
 * Scrolling of the content component creates a box shadow under the header component
 * and sometimes the corner component (if showing a hidden sidebar navigation menu at smaller viewport widths).
 *
 * Scrolling of the navigation component creates a box shadow under the corner component.
 */
@Injectable({
  providedIn: 'root'
})
export class AppLayoutService {

	private _contentScrolled = new Subject<boolean>();
	contentScrolled = this._contentScrolled.pipe(
		shareReplay(1)
	);

	private _navigationScrolled = new Subject<boolean>;
	navigationScrolled = this._navigationScrolled.pipe(
		shareReplay(1)
	);

	onOSScroll = ([instance, event]:[OverlayScrollbars, Event]): void => {
		const { viewport} = instance.elements();
		if (viewport.parentElement?.className === 'app-content') {
			this._contentScrolled.next(viewport.scrollTop > 0);
		}
		if (viewport.parentElement?.className === 'app-navigation') {
			this._navigationScrolled.next(viewport.scrollTop > 0);
		}
	}
}
