import {Palette} from './palette';

// Values for dollars category & FHI colors; used for chart rendering
export const paletteValues: Array<{color: Palette, value: string}> = [
	{ color: 'green',  value: '#3D7F0B'}, // var(--color-tint-success)
	{ color: 'purple', value: '#7C4AA7'}, // var(--color-tint-purple)
	{ color: 'blue',   value: '#0167C5'}, // var(--color-accent-primary-default
	{ color: 'grey',   value: '#626D78'}, // var(--color-base-gray-200)
	{ color: 'red',    value: '#D51010'}, // var(--color-tint-destructive)
	{ color: 'orange', value: '#DB7200'}, // var(--color-tint-caution);
];
