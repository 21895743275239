import {Pipe, PipeTransform} from '@angular/core';
/*
 * Take percent as string (return of common module, percent pipe), if string is '0%' replace with '<1%'
*/
@Pipe({
	name: 'zeroPercent',
	standalone: true
})
export class ZeroPercentPipe implements PipeTransform {
	transform(stringValue: string | null): string | null {
		if (stringValue === '0%') {
			return '<1%'
		} else {
			return stringValue;
		}
	}
}
