import { ResolveFn } from '@angular/router';
import {map} from 'rxjs/operators';
import {resolveGovernmentTypeName} from './government-type-name.resolver';
import {resolveFinancialReport} from './financial-report.resolver';
import {forkJoin, Observable} from 'rxjs';
import {resolveGovernmentName} from './government-name.resolver';

export const financialReportTitleResolver: ResolveFn<string> = (route, state) => {
	const mcag = route.paramMap.get('mcag');
	const govTypeCode = route.paramMap.get('govTypeCode');
	let resolveGovernmentTitle: Observable<string> | undefined = undefined;

	if (mcag) {
		resolveGovernmentTitle = resolveGovernmentName(route);
	}

	if (govTypeCode) {
		resolveGovernmentTitle = resolveGovernmentTypeName(route);
	}

	if (resolveGovernmentTitle) {
		return forkJoin([resolveGovernmentTitle, resolveFinancialReport(route)]).pipe(
			map(([govTitle, financialReport]) => `${govTitle}: ${financialReport?.name}`) // todo change financial report name to modal logic
		);
	} else {
		throw Error('Financial Report Title not able to resolve Government Id');
	}
};

